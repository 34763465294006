@import '/src/common/styles/colors.scss';

.aira-tasting-page {
    align-items: center;
    justify-content: flex-start;

    .logo {
        height: 20px;
    }

    h1 {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 22px;
    }

    .aira-img {
        width: 80%;
        max-width: 250px;
    }

    p {
        font-size: 12px;
        max-width: 450px;
        text-align: center;
        margin: 22px auto 12px;

        b {
            color: getColor(primary);
        }
    }

    span {
        font-size: 12px;
    }

    .ant-btn {
        width: 100%;
        max-width: 200px;
        margin: 22px 0 0;
    }
}

@media only screen and (min-width: 768px) {
    .aira-tasting-page {
        .logo {
            height: 32px;
        }

        h1 {
            font-size: 22px;
            margin: 0 0 20px;
        }

        .aira-img {
            max-width: 280px;
        }

        p {
            font-size: 13px;
            max-width: 450px;
            margin: 28px auto 20px;
        }

        span {
            font-size: 13px;
        }

        .ant-btn {
            width: 100%;
            max-width: 250px;
            margin: 28px 0 0;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .aira-tasting-page {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;

        .logo {
            height: 30px;
        }

        h1 {
            font-size: 26px;
            margin: 0 0 24px;
        }

        .aira-img {
            max-width: 233px;
        }

        p {
            font-size: 16px;
            max-width: 700px;
            margin: 24px auto 24px;
        }

        span {
            font-size: 16px;
        }

        .ant-btn {
            width: 100%;
            max-width: 250px;
            margin: 24px 0 0;
        }
    }
}

@media only screen and (max-width: 1440px) {
}
