@import '/src/common/styles/colors.scss';

.notification-modal {
  top: 0;
  left: 210px;
  width: 100%;
  z-index: 998;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  transition: left 0.2s ease-in-out 0.1s;
  background-color: rgba(0, 0, 0, 0.4);

  &.collapsed {
    left: 56px;
  }

  &.closed {
    .notification-card-content {
      animation: moveNotifModalLeft 0.3s;

      @keyframes moveNotifModalLeft {
        from {
          left: 0;
          opacity: 100;
        }

        to {
          opacity: 0;
          left: -50%;
        }
      }
    }
  }

  .notification-card-content {
    left: 0;
    top: 50%;
    width: 50%;
    height: 100vh;
    max-width: 775px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    background-color: #f2f2f2;
    border-radius: 4px 0 0 4px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    animation: moveNotifModalRight 0.3s;

    .notifications-page {
      padding: 40px 32px;
    }

    @keyframes moveNotifModalRight {
      from {
        opacity: 0;
        left: -50%;
      }

      to {
        left: 0;
        opacity: 100;
      }
    }
  }
}

.menu-arrow {
  top: 68px;
  left: 208px;
  width: 22px;
  height: 22px;
  z-index: 999;
  cursor: pointer;
  position: fixed;
  border-radius: 22px;
  transform: translate(-50%);
  border: 1px solid #ffe6ed;
  background-color: #fff9fb;
  transition: all 0.3s ease-in-out;

  .icon {
    top: 50%;
    left: 50%;
    font-size: 8px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, -50%);
  }

  &.collapsed {
    left: 56px;

    &:hover {
      .icon {
        left: 100%;
        transform: translate(-150%, -50%);
      }
    }
  }

  &:hover {
    width: 36px;
    padding: 0 8px;
    border-color: getColor(primary);
    background-color: getColor(primary);

    .icon {
      left: 0;
      color: #fff;
      transform: translate(50%, -50%);
    }
  }
}

.ant-layout-sider {
  z-index: 997;
  position: fixed;
  width: 208px !important;
  flex: 0 0 208px !important;
  max-width: 208px !important;
  min-width: 208px !important;
  overflow: hidden !important;

  .ant-layout-sider-children {
    padding-top: 28px;
    background-color: #fff8fb;
    transition: all 0.3s ease-in-out;
    border-right: 1px solid #ffe6ed;

    .logo-airfluencer {
      height: 70px;

      .logo {
        top: 28px;
        left: 15px;
        width: 36px;
        position: absolute;
        transition: 0.3s all ease-in-out;
      }

      .logo-label {
        .text-img {
          top: 12px;
          left: 53px;
          width: 118px;
          height: auto;
          position: relative;
          transition: 0.2s all ease-in-out 0.3s;
        }
      }
    }

    .menus {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 100px);
      justify-content: space-between;

      .bottom-menu {
        // progress item
        .ant-menu-item:first-child {
          cursor: default;
          height: auto !important;
          padding: 0 10px 8px 10px !important;

          &:hover {
            background-color: transparent !important;
          }
        }
      }

      .ant-menu {
        width: 100%;
        overflow-y: hidden;
        background-color: #fff8fb;

        .ant-menu-submenu {
          .ant-menu-submenu-title {
            padding: 0 10px !important;
          }
        }

        .ant-menu-submenu,
        .ant-menu-item {
          margin: 0;
          padding: 0 10px;

          .icon {
            font-size: 18px;
            color: getColor(secondary);
          }

          .ant-menu-title-content {
            font-size: 13px;
            color: getColor(secondary);
            margin-left: 6px !important;
          }

          &:hover {
            background-color: getColor(primary, light);
          }
        }

        .ant-menu-item {
          height: 40px !important;
          padding-left: 10px !important;

          &.ant-menu-item-selected {
            background-color: getColor(primary, light);

            .icon {
              color: getColor(primary);
            }

            .ant-menu-title-content {
              font-weight: 600;
              color: getColor(primary);
            }
          }

          .limited-search {
            width: 100%;
            animation: fadeCard 0.3s;
            transition: all 0.2s ease-in-out;

            &.opened {
              color: #000;
              display: flex;
              font-size: 11px;
              border-radius: 8px;
              flex-direction: column;
              padding: 0 12px 24px 6px;
              background-color: #fff;
              border: 1px solid #ffe6ed;

              &.quota-exceeded {
                padding: 0 12px 8px 6px;

                .plan-text {
                  margin: 0 0 4px 0;
                }

                .search-concluded-text {
                  height: 10px;
                  margin: -10px 0 8px 0;
                }

                .ant-progress-text {
                  margin-right: 8px !important;
                }

                .part-speak-to-specialist {
                  margin-top: 16px;
                }
              }

              .plan-text {
                height: 20px;
                font-weight: 600;
                margin: 0 0 8px 0;
              }

              .search-concluded-text {
                height: 10px;
                margin: -10px 0 0 0;
              }

              .part-speak-to-specialist {
                display: flex;
                margin-top: 18px;
                flex-direction: column;
                justify-content: flex-start;
                border-top: 1px solid #ffe6ed;

                p {
                  height: 14px;
                  margin: -6px 0 18px 0;
                }

                .ant-button {
                  width: 100%;
                }
              }
            }

            @keyframes fadeCard {
              from {
                opacity: 0;
                transform: scale(0);
              }

              to {
                opacity: 100;
                transform: scale(1);
              }
            }

            .ant-progress {
              width: 100% !important;
              height: auto !important;
              transition: all 0.2s ease-in-out;

              &.ant-progress-line {
                display: flex;
                position: relative;
                flex-direction: column;

                .ant-progress-outer {
                  padding: 0 !important;
                }

                .ant-progress-text {
                  right: 0;
                  bottom: -16px;
                  margin: 0;
                  position: absolute;
                }
              }

              &.ant-progress-circle {
                width: 44px !important;
                height: 44px !important;
              }

              .ant-progress-inner {
                max-height: 44px;
              }
            }

            .ant-progress-text {
              font-size: 11px;
              font-weight: 600;
              color: #000 !important;
            }
          }

          .notification-counter {
            position: relative;

            .counter {
              top: 7px;
              width: 13px;
              right: -3px;
              height: 13px;
              display: flex;
              font-size: 8px;
              position: absolute;
              line-height: normal;
              border-radius: 13px;
              align-items: center;
              justify-content: center;
              background-color: getColor(primary);
            }
          }
        }

        &:first-child .ant-menu-item:last-child {
          padding: 0 11px;

          img {
            width: 24px;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }

  &.ant-layout-sider-collapsed {
    width: 56px !important;
    flex: 0 0 56px !important;
    max-width: 56px !important;
    min-width: 56px !important;

    .ant-layout-sider-children {
      .logo-airfluencer {
        .logo {
          top: 28px;
          left: 10px;
          width: 35px;
        }

        .logo-label {
          .text-img {
            width: 0;
            height: 0;
          }
        }
      }
    }

    .menus {
      .bottom-menu {
        // progress item
        .ant-menu-item:first-child {
          display: flex;
          cursor: default;
          align-items: flex-end;
          height: auto !important;
          padding: 0 6px 8px !important;
        }
      }

      .ant-menu {
        &.top-menu {
          // menu item aira
          .ant-menu-item:last-child {
            padding: 0 11px !important;

            img {
              width: 30px;
            }
          }
        }

        .ant-menu-submenu {
          .ant-menu-submenu-title {
            padding: 0 17px !important;
          }
        }

        .ant-menu-item {
          padding: 0 17px !important;

          .limited-search {
            .ant-progress .ant-progress-inner {
              width: 43px !important;
              height: 43px !important;

              .ant-progress-text {
                font-size: 11px;
                font-weight: 600;
                color: #000 !important;
              }
            }
          }
        }
      }
    }
  }
}

.ant-menu-submenu {
  padding: 0 !important;

  .ant-menu-submenu-title {
    margin: 0;
  }

  &.ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      margin: 0;
      background-color: getColor(primary, light) !important;

      .icon {
        color: getColor(primary) !important;
      }

      .ant-menu-title-content {
        font-weight: 600;
        color: getColor(primary) !important;
      }
    }

    .ant-menu-submenu-arrow {
      &::before,
      &::after {
        background-color: getColor(primary) !important;
      }
    }
  }

  &.ant-menu-submenu-popup {
    .ant-menu-sub {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #fff8fb !important;

      .ant-menu-item {
        width: 100%;
        transition: all 0.2s ease-in;

        &.ant-menu-item-selected {
          font-weight: 600;
          background-color: getColor(primary, light);

          .ant-menu-title-content {
            color: getColor(primary) !important;
          }
        }

        &:hover {
          background-color: getColor(primary, light);
        }
      }
    }
  }

  .ant-menu-title-content {
    margin-left: 6px !important;
    color: getColor(secondary) !important;

    .icon {
      color: getColor(secondary) !important;
    }
  }

  .ant-menu-inline {
    .ant-menu-item {
      display: flex;
      font-size: 13px;
      margin-left: 20px;
      justify-content: flex-end;
      padding: 0 0 0 42px !important;

      &.ant-menu-item-selected {
        background-color: getColor(primary, light);

        .ant-menu-title-content {
          font-weight: 600;
          color: getColor(primary) !important;
        }
      }

      .ant-menu-title-content {
        padding: 0 0 0 4px;
        transition: all 0.2s ease-in;
      }

      &:hover {
        background-color: getColor(primary, light);
      }
    }
  }

  .ant-menu-submenu-arrow {
    &::before,
    &::after {
      background-color: getColor(secondary) !important;
    }
  }
}
