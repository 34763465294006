@import '/src/common/styles/colors.scss';

.table-container {
  overflow-x: auto;

  .empty-image {
    margin: 20px auto;
  }

  table {
    width: 100%;
    border-spacing: 0 16px;
    border-collapse: separate;

    thead {
      tr {
        border-radius: 8px;
        padding-bottom: 16px;

        th {
          margin: 1px;
          color: #666;
          font-size: 13px;
          text-align: left;
          position: relative;
          white-space: nowrap;
          padding: 16px 0 16px 8px;
          background-color: #e6e6e6;

          &:first-child {
            padding-left: 16px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border: none;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          .icon-down {
            margin-top: -2px;
            margin-left: 8px;
            transition: all 0.2s ease-in-out;

            &.smaller {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    tbody {
      tr {
        position: relative;

        &.selected {
          td {
            &:first-child {
              border-bottom-left-radius: 0;
            }

            &:last-child {
              border-bottom-right-radius: 0;
            }
          }
        }

        td {
          font-size: 13px;
          text-align: left;
          line-height: 16px;
          font-weight: normal;
          padding: 16px 0 16px 8px;
          color: getColor(text, dark);
          transition: all 0.2s ease-in-out;
          background-color: getColor(background, lightest);

          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          .div-flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }

        .td-influencer {
          text-align: left;
          position: relative;
          padding-left: 16px;
          text-transform: capitalize;

          .div-flex {
            .avatar {
              margin-right: 8px;
            }

            .influencer-name {
              font-size: 14px;
              font-weight: 700;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: calc(100% - 50px);
            }
          }
        }

        .td-network {
          &.color-tiktok div img {
            width: 13px;
          }

          div {
            width: 40px;
            height: 40px;
            padding: 8px;
            display: flex;
            margin: 0 auto;
            border-radius: 6px;
            align-items: center;
            justify-content: center;
            background-color: #f2f2f2;
          }
        }

        .td-my-lists {
          padding: 0;

          .container-modal-lists {
            justify-content: flex-start;

            .icon-plus {
              padding: 6px;
              font-size: 20px;
              color: #979797;
              border-radius: 3px;
              background-color: #d9d9d9;
            }
          }
        }

        .td-insights {
          padding-right: 12px;
        }
      }

      .tr-footer {
        top: -16px;
        z-index: 4;
        width: 100%;
        position: relative;
        max-height: 0;

        &.hidden {
          max-height: auto;
        }

        td {
          font-size: 13px;
          line-height: 16px;
          background-color: #fff;

          p {
            font-weight: 700;
            margin: 0 0 8px 0;
          }

          &.td-insights-text {
            z-index: 9;
            position: relative;
            border-radius: 0 0 8px 8px;
            padding: 16px;

            pre {
              margin: 0;
              text-wrap: wrap;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              color: #484848;
            }

            .td-insights-advise {
              margin-top: 16px;
            }
          }

          &.td-classification {
            left: 0;
            display: flex;
            margin: 0 2px;
            bottom: -24px;
            position: absolute;
            padding: 0 8px;
            height: 26px;
            align-items: center;
            width: calc(100% - 4px);
            justify-content: flex-end;
            border-radius: 0 0 8px 8px;
            background-color: #e6e6e6;

            .icon {
              padding: 0 4px;
              margin-top: 2px;
              font-size: 18px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;

              &.icon-like[data-active='true'] {
                color: getColor(status, darkSuccess);
              }

              &.icon-dislike[data-active='true'] {
                color: getColor(status, error);
              }

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }
}
