@import "/src/common/styles/colors.scss";

.influencer-general-data {
    max-width: 1465px;
    margin: 0 auto 32px;

    .junction-data {
        display: flex;
        justify-content: space-between;

        .general-data-card,
        .publipost-card {
            gap: 24px;
            display: flex;
            padding: 24px;
            flex-wrap: wrap;
            border-radius: 4px;
            width: calc(50% - 16px);
            background-color: #FFF;
            justify-content: space-between;

            .subtitle-part {
                margin: 0;
                width: 100%;
            }

            .interaction {
                padding: 24px 4px;
                text-align: center;
                border-radius: 4px;
                width: calc(50% - 12px);
                background-color: #FFF9FB;

                &.full {
                    width: 100%;
                }

                .interaction-value {
                    font-size: 26px;
                    font-weight: 700;
                    margin-bottom: 12px;
                }

                .interaction-name {
                    font-size: 22px;
                }
            }

            .part-followers,
            .part-airscore {
                gap: 16px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .follower, .score {
                    padding: 12px 4px;
                    text-align: center;
                    border-radius: 4px;
                    width: calc(33% - 10px);
                    background-color: #FFF9FB;

                    .value {
                        font-size: 26px;
                        font-weight: 700;
                        margin-bottom: 12px;
                    }

                    .name {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}