@import "/src/common/styles/colors.scss";

.titles {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title-part {
        width: calc(50% - 8px);
    }
}

.part-donuts {
    display: flex;
    justify-content: space-between;

    .card-donut-chart {
        padding: 20px;
        border-radius: 8px;
        position: relative;
        width: calc(50% - 8px);
        background-color: #FFF;

        .chart-title {
            font-size: 20px;
            font-weight: 500;
        }

        .icon {
            top: 20px;
            right: 20px;
            font-size: 20px;
            position: absolute;
        }
    }
}