@import "/src/common/styles/colors.scss";

.influencer-mentions-pdf {
    display: flex;
    max-width: 1465px;
    margin: 0 auto 8px;
    justify-content: space-between;

    .junction-mentions {
        display: flex;
        flex-wrap: wrap;
        width: calc(50% - 12px);
        justify-content: space-between;

        .subtitle-part {
            width: 100%;
        }
        
        .list-mentions {
            height: 548px;
            padding: 36px;
            border-radius: 6px;
            margin-bottom: 16px;
            width: calc(50% - 8px);
            border: 1px solid #FFE5ED;
            transition: all .2s ease-in-out;
            background-color: getColor(background, lightest);
            
            &.empty {
                border: 1px solid #F2F2F2;
                background-color: #F2F2F2;
            }
            
            .list-title {
                font-size: 22px;
                max-width: 200px;
                font-weight: 600;
                text-align: center;
                margin: 0 auto 32px;
            }

            .mentions {
                display: flex;
                font-size: 20px;
                overflow: hidden;
                flex-direction: column;
                align-content: baseline;
                align-items: flex-start;
                align-content: flex-start;
                transition: .2s all ease-in-out;

                &.expanded {
                    max-height: 2200px;
                }

                .mention {
                    display: flex;
                    margin-bottom: 6px;
                    align-items: center;

                    &.mention-brand {
                        min-height: 22px;
                    }

                    .comma {
                        padding-right: 2px;
                    }

                    .value {
                        display: flex;
                        min-width: 18px;
                        font-size: 16px;
                        margin-left: 4px;
                        padding: 2px 6px;
                        color: #AB0932;
                        margin-right: 4px;
                        border-radius: 12px;
                        align-items: center;
                        justify-content: center;
                        background-color: #FFE5ED;
                    }
                }
            }
        }
    }
}