@import "/src/common/styles/colors.scss";

.aira-recommendation {
    width: 100%;
    display: flex;
    max-width: 1280px;
    padding: 10px 21px;
    // margin: 0 84px 12px;
    border-radius: 16px;
    align-items: center;
    background-color: #FFF;

    img {
        width: 48px;
        margin-right: 28px;
    }

    .presentation-text {
        gap: 2px;
        display: flex;
        flex-direction: column;

        .text-1 {
            font-size: 14px;
            font-weight: 600;
        }

        .text-2 {
            font-size: 14px;
            color: getColor(text, dark);
        }
    }

    .ant-btn {
        width: 229px;
        margin: 0 0 0 auto;

        &.pattern-dark {
            background-color: #333 !important;
        }
    }
}