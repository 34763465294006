.create_campaign_modal {
  width: 33rem !important;
  padding: 0;
  top: 0;

  form {
    > div {
      p {
        margin-bottom: 2px;
        color: #4a4a4a;
      }

      .input-junction {
        margin-top: 2px;
      }
    }

    > div + div {
      margin-top: 16px;
    }

    label,
    b {
      font-size: 14px;
      font-weight: 500;
      line-height: normal !important;
    }

    > div > div {
      display: flex;
      gap: 32px;

      div {
        width: 100%;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    margin-top: 32px;

    button:first-of-type {
      background: #ffffff !important;
      border: 1px solid #ed276a;
      color: #ed276a !important;

      &:hover {
        border-color: #ed276a;
      }
    }
  }
}

.button_action {
  height: 40px;
  border: none;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  min-width: 110px;
  text-align: center;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  box-shadow: none;
  transition: 0.2s ease-in all;
  background-color: #ed276a;

  &:hover {
    opacity: 0.6;

    &:disabled {
      opacity: 1;
    }
  }
}
