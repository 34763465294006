@import '/src/common/styles/colors.scss';

.aira-result-table {
  width: 100%;
  overflow: hidden;

  .result-table-container {
    overflow-x: auto;

    table {
      width: 100%;
      border-spacing: 0 8px;
      border-collapse: separate;

      thead {
        tr {
          border-radius: 8px;

          th {
            color: #666;
            font-size: 10px;
            max-width: 80px;
            text-align: left;
            position: relative;
            padding: 8px 0 8px 12px;
            background-color: #e6e6e6;

            span {
              word-wrap: break-word;
              overflow-wrap: break-word;
            }

            &:first-child {
              padding-left: 8px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border: none;
              padding-right: 8px;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }

            .icon-down {
              margin-top: -2px;
              margin-left: 8px;
              transition: all 0.2s ease-in-out;

              &.smaller {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 11px;
            text-align: left;
            line-height: 16px;
            font-weight: normal;
            padding: 8px 0 8px 12px;
            color: getColor(text, dark);
            background-color: getColor(background, lightest);

            &:first-child {
              padding-left: 8px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              padding-right: 8px;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }

            .div-flex {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }

          .td-influencer {
            text-align: left;
            position: relative;
            padding-left: 16px;
            text-transform: capitalize;

            .div-flex {
              .avatar {
                margin: 0 8px;
              }

              .influencer-name {
                font-size: 11px;
                font-weight: 700;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: calc(100% - 50px);
              }
            }
          }

          .td-engagement {
            gap: 8px;
            display: flex;
            height: 66px;
            align-items: center;
            justify-content: flex-start;

            > button {
              cursor: pointer;
              padding: 0;
              margin-bottom: 4px;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              background: none;
              transition: opacity 0.2s;

              &:hover {
                opacity: 0.75;
              }
            }

            .engagement-rating {
              padding: 0;
              height: 20px;

              .classification-text {
                display: none;
              }

              .columns {
                height: 16px;

                .column {
                  border-radius: 4px;
                }
              }
            }
          }

          .td-airscore > div {
            display: flex;
            align-items: baseline;
            gap: 8px;
          }

          .td-themes {
            .div-flex {
              position: relative;
              justify-content: flex-start;

              .circle-theme {
                top: 0;
                z-index: 8;
                opacity: 0.2;
                width: 28px;
                height: 28px;
                padding: 9px;
                border-radius: 50%;
                position: absolute;
              }

              .icon {
                padding: 6px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .empty-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;

    .empty-image {
      width: 150px;
    }

    img {
      margin-bottom: 0;
    }

    span {
      font-size: 10px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .aira-result-table {
    .result-table-container {
      table {
        border-spacing: 0 12px;

        thead {
          tr {
            th {
              font-size: 12px;
              padding: 12px 0 12px 12px;

              &:first-child {
                padding-left: 14px;
              }

              &:last-child {
                padding-right: 14px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 12px;
              padding: 12px 0 12px 12px;

              &:first-child {
                padding-left: 14px;
              }

              &:last-child {
                padding-right: 14px;
              }
            }

            .td-influencer {
              padding-left: 16px;

              .div-flex {
                .avatar {
                  margin: 0 8px;
                }

                .influencer-name {
                  position: relative;

                  &.hidden {
                    filter: blur(2px);
                  }

                  span {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .empty-list {
      .empty-image {
        width: 180px;
      }

      img {
        margin-bottom: 0;
      }

      span {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .aira-result-table {
    .result-table-container {
      table {
        border-spacing: 0 12px;

        thead {
          tr {
            th {
              font-size: 13px;
              line-height: 15px;
              padding: 9px 0 9px 30px;

              span {
                bottom: -2px;
                position: relative;
              }

              &:first-child {
                padding-left: 32px;
              }

              &:last-child {
                padding-right: 32px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              height: 66px !important;
              font-size: 13px;
              padding: 17px 0 17px 32px;

              &:first-child {
                padding-left: 32px;
              }

              &:last-child {
                padding-right: 32px;
              }
            }

            .td-influencer {
              padding-left: 16px;

              .div-flex {
                .avatar {
                  margin: 0 8px;
                  width: 32px !important;
                  height: 32px !important;
                }

                .influencer-name {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .empty-list {
      .empty-image {
        width: 190px;
      }

      span {
        font-size: 13px;
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
}
