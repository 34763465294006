@import "/src/common/styles/colors.scss";

.table-container {
    overflow-x: auto;
    padding-bottom: 124px;

    table {
        width: 100%;

        .checkbox-container .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
            border-color: getColor(primary) !important;
            background-color: getColor(primary) !important;
        }

        thead tr {
            th>div {
                display: flex;
                align-items: center;

                .checkbox-container {
                    margin-right: 8px;

                    .ant-checkbox-inner {
                        width: 16px !important;
                        height: 16px !important;
                    }
                }

                .icon {
                    color: getColor(primary) !important;
                }
            }
        }

        tbody tr {
            .td-simulation {
                position: relative;


                &.td-simulation>div {
                    display: flex;
                    align-items: center;

                    .checkbox-container {
                        margin-right: 8px;
                    }

                    .ant-input-number {
                        width: 112px;
                    }
                }
            }
        }
    }
}