.level_gauge {
  display: flex;
  align-items: flex-end;
  gap: 1px;
}

.level_marker__level_gauge {
  width: 6px;
  height: 8px;
  background: #bbbbbb;
  border-radius: 5px;

  &[data-level='1']:first-of-type {
    background: #ff4c4c;
  }

  &:nth-of-type(2) {
    height: 12px;
  }

  &:last-of-type {
    height: 16px;
  }

  &[data-level='2'] {
    background: #ffc845;

    &:last-of-type {
      background: #bbbbbb;
    }
  }

  &[data-level='3'] {
    background: #34bf49;
  }
}
