@import "/src/common/styles/colors.scss";

.extension-recommendation {
    width: 100%;
    height: 72px;
    display: flex;
    padding: 0 21px;
    max-width: 1280px;
    border-radius: 16px;
    align-items: center;
    background-color: #FFF;

    img {
        width: 48px;
        margin-right: 24px;
    }

    .presentation-text {
        gap: 4px;
        display: flex;
        flex-direction: column;

        .text-1 {
            font-size: 14px;
            font-weight: 600;
        }
        
        .text-2 {
            font-size: 14px;
            color: getColor(text, dark);
            
            a {
                font-weight: 700;
                color: getColor(text, dark);
                transition: all .2s ease-in-out;

                &:hover {
                    color: getColor(primary);
                }
            }
        }
    }

    .ant-btn {
        width: 229px;
        margin: 0 0 0 auto;

        &.pattern-dark {
            background-color: #333 !important;
        }
    }
}