@import '/src/common/styles/colors.scss';

.container_aira_form {
  flex: 1;
  display: flex;

  .split__container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: getColor(primary, base);

    &:last-of-type {
      background: #f7f5f6;
    }
  }
}

.aira__container {
  padding: 24px;
  padding-right: 48px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: -3px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  gap: 32px;

  strong {
    font-size: 16px;
    font-weight: 600;
    color: #1a1517;
  }

  span {
    margin: 0.5rem 0;
    display: block;
    font-size: 12px;
  }

  p {
    margin-bottom: 0;
  }
}

.form__container {
  padding: 16px;
  width: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  > h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: #31282c;
  }

  > p {
    margin: 8px 0 2rem;
    margin-top: 8px;
    text-align: center;
    color: #1a1517;
  }

  .fields__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }
}

.checkbox_wrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 0.5rem;
  accent-color: getColor(primary, base);

  label {
    color: #31282c;

    a {
      transition: all 0.2s ease-in-out;
      color: getColor(primary, base);

      &:hover,
      &:focus-visible {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }

  input {
    all: unset;
    position: relative;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4f454a;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:checked {
      border-color: getColor(primary, base);
      background: getColor(primary, base);
    }

    &::before {
      content: '✔';
      color: white;
      line-height: 1;
      font-size: 1rem;
    }

    &:focus-visible {
      border-color: getColor(primary, base);
      box-shadow: 0 0 0 2px getColor(primary, base, 0.5);
    }
  }
}

.error_message {
  color: #ff4d4f;
  font-size: 14px;
  display: block;
}

.footer__container {
  margin-top: 24px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  button {
    border: none;
    height: 48px;
    color: #ffffff;
    background: getColor(primary, base);
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:not(:disabled):hover {
      background: getColor(primary, base, 0.75);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px getColor(text, black);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.container_successful {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-size: 24px;
    font-weight: 700;
    color: #31282c;
  }

  p {
    margin: 0;
    color: #1a1517;
  }

  a {
    margin-top: 40px;
    height: 3rem;
    width: 22.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: getColor(primary, base);
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:not(:disabled):hover {
      background: getColor(primary, base, 0.75);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px getColor(text, black);
    }
  }
}

@keyframes element1 {
  from {
    stroke-dashoffset: 441.822971502571px;
    stroke-dasharray: 441.822971502571px;
    fill: transparent;
  }
  to {
    stroke-dashoffset: 0;
    fill: rgb(80, 192, 100);
  }
}

@keyframes element2 {
  from {
    stroke-dashoffset: 115.84419250488281px;
    stroke-dasharray: 115.84419250488281px;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.container_successful__icon {
  margin: 8px 0;

  .svg-elem-1 {
    stroke-dashoffset: 441.822971502571px;
    stroke-dasharray: 441.822971502571px;
    fill: transparent;
    transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s;
    animation: element1 1s forwards 0.1s;
  }

  .svg-elem-2 {
    stroke-dashoffset: 115.84419250488281px;
    stroke-dasharray: 115.84419250488281px;
    transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s,
      fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
    animation: element2 1s forwards 0.1s;
  }
}
