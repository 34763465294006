.ant-input-number {
    border: none !important;
    box-shadow: none !important;

    .ant-input-number-handler-wrap {
        display: none;
    }

    .ant-input-number-input-wrap {
        input {
            height: 40px;
            outline: none;
            color: #4a4a4a;
            max-width: 200px;
            font-weight: 400;
            padding: 6px 12px;
            border-radius: 8px;
            box-sizing: border-box;
            border: none !important;
            font-size: 15px !important;
            background-color: #FFFFFF;
            border: 1px solid #c6c6c6 !important;

            .ant-input-prefix {
                margin-right: 8px;

            }

            .ant-input:focus,
            .ant-input-focused {
                box-shadow: none;
            }
        }

        input::placeholder {
            color: #4a4a4a;
        }
    }
}