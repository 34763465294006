@import "/src/common/styles/colors.scss";

.skeleton-aira-tasting-table {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    table {
        width: 100%;
        border-spacing: 0 8px;
        border-collapse: separate;

        thead {
            tr {
                border-radius: 8px;

                th {
                    color: #666;
                    font-size: 10px;
                    max-width: 80px;
                    text-align: left;
                    position: relative;
                    padding: 8px 0 8px 12px;
                    background-color: #E6E6E6;

                    span {
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                    }

                    &:first-child {
                        padding-left: 8px;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }

                    &:last-child {
                        border: none;
                        padding-right: 8px;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 11px;
                    text-align: left;
                    line-height: 16px;
                    font-weight: normal;
                    padding: 8px 0 8px 12px;
                    color: getColor(text, dark);
                    background-color: getColor(background, lightest);

                    &:first-child {
                        padding-left: 8px;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }

                    &:last-child {
                        padding-right: 8px;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }

                    .div-flex {
                        gap: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .skeleton-aira-tasting-table {
        table {
            border-spacing: 0 12px;

            thead {
                tr {
                    th {
                        font-size: 12px;
                        padding: 12px 0 12px 12px;

                        &:first-child {
                            padding-left: 14px;
                        }

                        &:last-child {
                            padding-right: 14px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 12px;
                        padding: 12px 0 12px 12px;

                        &:first-child {
                            padding-left: 14px;
                        }

                        &:last-child {
                            padding-right: 14px;
                        }

                        .div-flex {
                            gap: 8px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .skeleton-aira-tasting-table {
        table {
            border-spacing: 0 12px;

            thead {
                tr {
                    th {
                        font-size: 13px;
                        line-height: 15px;
                        padding: 20px 0 20px 32px;

                        span {
                            bottom: -2px;
                            position: relative;
                        }

                        &:first-child {
                            padding-left: 32px;
                        }

                        &:last-child {
                            padding-right: 32px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 13px;
                        padding: 17px 0 17px 32px;

                        &:first-child {
                            padding-left: 32px;
                        }

                        &:last-child {
                            padding-right: 32px;
                        }

                        .ant-skeleton-element .ant-skeleton-avatar {
                            width: 48px !important;
                            height: 48px !important;
                        }

                        .div-flex {
                            gap: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {}