@import "/src/common/styles/colors.scss";

.avatar-network {
    position: relative;

    &.is-small {
        .ant-avatar {
            width: 48px !important;
            height: 48px !important;
        }
        
        .network-logo {
            top: -1px;
            left: -10px;
            width: 35px;
            border: none;
            position: relative;
        }
    }

    .ant-avatar {
        transition: 0.2s ease-in all;
    }
    
    .network-logo {
        right: -4px;
        bottom: -8px;
        position: absolute;
        border-radius: 50%;
    }

    .facebook-logo {
        box-shadow: 0 4px 24px rgba(59, 89, 152, 0.15);
    }

    .instagram-logo {
        box-shadow: 0 4px 24px rgba(255, 0, 255, 0.15);
    }
    
    .tiktok-logo {
        border: 1px solid getColor(background, light);
    }

    .twitter-logo {
        box-shadow: 0 4px 24px rgba(56, 151, 240, 0.15);
    }
    
    .youtube-logo {
        box-shadow: 0 4px 24px rgba(255, 0, 0, 0.18);
    }
}