@import "/src/common/styles/colors.scss";

.interaction-chart {
    border-radius: 6px;
    padding: 22px 24px;
    margin-bottom: 82px;
    background-color: #FFF;

    .container-loading {
        display: flex;
        min-height: 360px;
        align-items: center;
        justify-content: center;
    }

    .subtitle-part {
        display: flex;
        align-items: center;
    }

    .subtitle-filter-category {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-select {
            margin-left: 8px;
        }

        .filters {
            display: flex;
            align-items: center;

            .score-options {
                display: flex;
                align-items: center;

                .ant-radio-group {
                    .ant-radio-wrapper {
                        margin-right: 12px;

                        .ant-radio {
                            outline: none !important;
                            box-shadow: none !important;
                            
                            input[type="radio"]:focus {
                                border: none !important;
                                outline: none !important;
                                box-shadow: none !important;
                            }

                            .ant-radio-inner {
                                border: none !important;
                                background-color: #F2F2F2 !important;

                                &::after {
                                    background-color: #000 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .filter-period {
        display: flex;
        margin: 28px 0 20px;
        justify-content: flex-end;

        .btn-filter {
            border: none;
            font-size: 12px;
            cursor: pointer;
            padding: 6px 12px;
            background-color: #FFF;
            transition: all 0.2s ease-in-out;
            border-right: 1px solid rgba(51, 51, 51, 0.1);

            &.selected {
                color: #FFF;
                border-color: #333;
                background-color: #333;
            }

            &:first-child {
                border-right: none;
                border-radius: 6px 0 0 4px;
            }

            &:last-child {
                border-right: none;
                border-radius: 0 4px 4px 0;
            }

            &.unselected:hover {
                color: #FFF;
                background-color: rgba(0, 0, 0, 0.608);
            }
        }
    }

    .box-chart {
        position: relative;

        .icon-download {
            top: 6px;
            right: 6px;
            z-index: 98;
            padding: 8px;
            border-radius: 50%;
            position: absolute;
            background-color: #FFF;
        }
    }
}