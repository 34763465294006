.double-bar {
    .apexcharts-series:nth-child(odd) {
        opacity: 0.4;
    }

    .highcharts-legend {
        margin-top: 20px;
    }

    .highcharts-credits {
        display: none;
    }
}

.high-fixed-tooltip {
    display: flex;
    font-size: 10px;
    text-align: center;
    justify-content: center;
    font-weight: normal !important;
}