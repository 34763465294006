.direct_link {
  border-radius: 24px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
  gap: 6px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ed276a;
  color: #ed276a;
  margin-left: 8px;

  &:hover {
    color: #ed276a;
    background: #fef1f7;
  }
}
