@import '/src/common/styles/colors.scss';

$menu-collapsed: 56px;
$menu-expanded: 207px;

$options-colors: (
  #b72325,
  #d62029,
  #ea5224,
  #e96f22,
  #f6a825,
  #fcc729,
  #ebdb0a,
  #e4e044,
  #c2d335,
  #afcb3d,
  #67b44f
);

.container_nps {
  padding: 1.5rem 2rem;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  flex: 1;
  width: calc(100% - $menu-expanded);
  display: flex;
  flex-direction: column;
  background: getColor(background, lightest);
  transition: width 0.2s ease-in-out;

  &[data-collapsed-menu='true'] {
    width: calc(100% - $menu-collapsed);
  }
  > strong {
    font-weight: 400;
  }

  > button {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #666666;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
    transition: colors 0.2s;

    &:hover {
      color: black;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px black;
    }
  }
}

.container__form {
  counter-reset: count -1;
}

.container__options {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;

  &:has(input:checked) input:not(:checked) {
    opacity: 0.2;

    &:hover {
      opacity: 0.6;
    }
  }

  input[type='radio'] {
    appearance: none;
    position: relative;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b72325;
    cursor: pointer;
    transition: opacity 0.2s;
    outline: none;

    @for $i from 1 through length($options-colors) {
      &:nth-of-type(#{$i}) {
        background: nth($options-colors, $i);
      }
    }

    &::before {
      content: counter(count);
      counter-increment: count;
      color: getColor(text, white);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px black;
    }
  }
}

.container__wrapper {
  margin-top: 1.5rem;
  max-height: 0;
  display: flex;
  gap: 1rem;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transform-origin: top;
  transition: 0.2s ease-in-out;

  &.visible {
    max-height: 8.25rem;
    opacity: 1;
    overflow: visible;
    visibility: visible;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  textarea {
    padding: 0.75rem;
    position: relative;
    min-height: 3.25rem;
    width: 100%;
    border: 1px solid getColor(border, base);
    border-radius: 0.5rem;
    font-size: 14px;
    resize: none;
    outline: none;
    overflow: hidden;

    &:empty {
      padding: 1.125rem 0.75rem;
      line-height: 1;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px getColor(primary, base, 0.5);
      border-color: getColor(primary, base);
    }

    &::placeholder {
      color: getColor(text, blankItem);
    }
  }

  button {
    align-self: flex-end;
    height: 3.25rem;
    width: 12rem;
    border: none;
    border-radius: 0.5rem;
    background: getColor(primary, base);
    color: getColor(text, white);
    cursor: pointer;
    outline: none;
    transition: background 0.2s ease-in-out;

    &:not(:disabled):hover {
      background: getColor(primary, base, 0.75);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px getColor(text, black, 1);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
