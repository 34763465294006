@import "/src/common/styles/colors.scss";

.checkbox-container {
    .ant-checkbox-wrapper {
        overflow: hidden;
        text-overflow: ellipsis;

        .ant-checkbox {
            .ant-checkbox-inner {
                width: 15px;
                height: 15px;
            }

            &.ant-checkbox-indeterminate {
                .ant-checkbox-inner::after {
                    width: 10px;
                    height: 3px;
                    background-color: getColor(primary) !important;
                }
            }
        }

        .ant-checkbox-inner::after {
            display: flex;
        }

        .ant-checkbox-checked::after {
            border: 1px solid transparent !important;
        }

        &:hover .ant-checkbox-inner {
            border-color: getColor(primary) !important;
        }

        &:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus+.ant-checkbox-inner {
            border-color: getColor(primary) !important;
        }

        .ant-checkbox-inner {
            &:hover {

                border-color: getColor(primary) !important;
            }

        }

        .ant-checkbox-checked .ant-checkbox-inner {
            border-color: getColor(primary) !important;
            background-color: getColor(primary);
        }

        .checkbox-text {
            display: block;
            font-size: 13px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

    }

    .ant-checkbox+span {
        padding-right: 0;
    }
}