@import "/src/common/styles/colors.scss";

.filter-networks {
    display: flex;
    gap: 24px 16px;
    margin: 8px 0 0;
    flex-wrap: wrap;
    justify-content: flex-start;

    .option-network {
        width: 40px;
        height: 40px;
        display: flex;
        cursor: pointer;
        font-size: 22px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: getColor(text, dark);
        transition: 0.2s ease-in all;
        border: 1px solid transparent;
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 4px 0 rgba(0, 0, 0, 0.13);

        .icon-x {
            font-size: 13px;
            margin-right: -1px;
            margin-bottom: -1px;
        }

        .icon-twitch {
            font-size: 17px;
        }

        &:last-child {
            margin-right: 0;
        }

        &.option-active {
            color: getColor(primary);
            border-color: getColor(primary);
        }
    }
}
