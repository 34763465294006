@import "/src/common/styles/colors.scss";

.categories-modal {
    top: 0;
    width: 90% !important;
    padding-bottom: 0 !important;

    .ant-modal-content {
        max-width: 1044px;
        border-radius: 9px;
        height: 90vh !important;
        margin: 0 auto !important;
        background-color: #f2f2f2;

        .ant-modal-body {
            height: 100%;
            padding: 33px 13px 33px 33px;

            .modal-title,
            .modal-subtitle {
                display: none;
            }

            .modal-content {
                width: 100%;
                height: 100%;
                overflow-x: hidden;
                padding-right: 20px;

                .input-name {
                    margin-bottom: 24px;

                    .input {
                        height: 54px;
                        padding: 0 27px;
                        background-color: #FFF;

                        .ant-input-prefix {
                            font-size: 22px;
                            margin-right: 17px;
                        }
                    }
                }

                .list {
                    gap: 16px;
                    column-count: 4;
                    -moz-column-count: 4;
                    -webkit-column-count: 4;

                    .card-category {
                        padding: 16px;
                        overflow: hidden;
                        border-radius: 8px;
                        margin-bottom: 16px;
                        border: 1px solid #CCC;
                        background-color: #FFF;

                        &.full {
                            border: 1px solid #FF7095;
                            background-color: #FFE5ED;
                        }

                        .list-sub-categories {
                            padding-left: 22px;
                        }

                        .checkbox-text {
                            white-space: normal !important;
                        }
                    }

                    .card-category>.checkbox-container .checkbox-text {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }

                .empty-result {
                    display: flex;
                    font-size: 16px;
                    margin-top: 48px;
                    text-align: center;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
            
                    img {
                        width: 70%;
                        max-width: 238px;
                        margin-bottom: 40px;
                    }
                }
            }
        }

        .ant-modal-close {
            display: none;
        }
    }
}