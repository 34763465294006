@import "/src/common/styles/colors.scss";

.search-container {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 84px 12px;
    box-sizing: content-box;

    .junction-home-search>.container-loading {
        padding: 37px;
        margin: 0 !important;
        background-color: #FFF;
    }

    .junction-home-search {
        padding-bottom: 26px;
        border-bottom: 1px solid #D8D8D8;

        .filters {
            animation: fadeFilters .3s;
            transition: all 0.2s ease-in-out;

            @keyframes fadeFilters {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 100;
                }
            }
        }

        .filters-row {
            padding: 12px;
            border-radius: 8px;
            background-color: #FFF;

            &.row-fixed {
                .popover-item {
                    margin-right: 8px;

                    &:last-child {
                        .ant-btn {
                            margin-right: 12px;
                        }
                    }

                    .option-values {
                        .ant-radio-group .ant-radio-wrapper .ant-radio {
                            margin: 3px 8px 10px 0;
                        }
                    }
                }
            }

            .ant-form-item {
                margin: 0 16px 0 0;
            }

            .ant-btn {
                &.active {
                    border-radius: 8px;
                }
            }
        }

        .input-name {
            margin-bottom: 12px;

            .input {
                height: 54px;
                padding: 0 27px;
                background-color: #FFF;

                .ant-input-prefix {
                    font-size: 22px;
                    margin-right: 17px;
                }
            }
        }

        .ant-form-item-row {
            justify-content: space-between;

            .ant-form-item-label label {
                width: 100%;

                .label-item {
                    font-size: 13px;
                    color: #4a4a4a;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .ant-dropdown-trigger {
            margin: 0;
            padding: 0;
            width: 100%;

            .ant-select {
                width: 100%;
                cursor: pointer !important;

                .ant-select-selector,
                .ant-select-selection-search {
                    text-align: left;
                }
            }
        }

        .filter-places {
            .ant-select-selector {
                border-radius: 6px 4px 0 0;
                box-shadow: none !important;
            }

            .container-checks {
                display: flex;
                padding: 2px 2px 4px;
                border-radius: 0 0 4px 4px;
                justify-content: space-around;
                background-color: getColor(background, item) !important;
                box-shadow: 0 2px 1px 0 rgb(201 201 201 / 50%) !important;
            }
        }

        .search-button {
            width: 193px;
            margin-left: 16px;

            .icon {
                margin-left: 8px;
            }
        }
    }

    .filter-values {
        display: flex;
        margin-top: 26px;
        padding: 12px 8px;
        border-radius: 8px;
        background-color: #FFF;
        justify-content: space-between;

        .filter-list {
            gap: 12px;
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 156px);
            justify-content: flex-start;

            .filter-value {
                height: 40px;
                display: flex;
                padding: 0 10px;
                border-radius: 8px;
                position: relative;
                align-items: center;
                color: getColor(primary);
                justify-content: space-between;
                background-color: getColor(primary, .2);

                p {
                    margin: 0;
                    height: 100%;
                    display: flex;
                    font-size: 14px;
                    min-width: 30px;
                    font-weight: 700;
                    margin-right: 10px;
                    padding-right: 10px;
                    align-items: center;
                    border-right: 2px solid getColor(primary, .4);
                }

                .icon {
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }

        .ant-btn {
            border-radius: 0px;
            border-left: 1px solid #CCC !important;
        }

    }

    .btn-settings {
        padding: 4px 8px;
        background-color: #F2F2F2 !important;

        .icon {
            font-size: 16px !important;
        }
    }
}

@media only screen and (max-width: 1320px) {
    .search-container {
        .junction-home-search {
            .filters-row > .ant-row {
                width: 100%;
                justify-content: space-between;
            }

            .filters-row {
                justify-content: center;

                .popover-item {
                    margin-bottom: 8px;
                    width: 48% !important;

                    &:nth-child(even) {
                        .popover-button {
                            border-right-color: transparent !important;
                        }
                    }

                    .popover-button {
                        &.opened {
                            border-right-color: #C3C3C3 !important;
                        }
                    }

                    .popover-content {
                        width: 100% !important;
                    }
                }
            }
        }

        .btn-settings {
            width: 100%;
        }
    }
}