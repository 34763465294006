.ant-row.briefing-collapse {
    width: 100% !important;
    background-color: white;
    border-radius: 8px;
    margin: 40px 0 24px 0;
    
    .ant-collapse-item:not(.ant-collapse-item-active) .ant-collapse-expand-icon {
        .anticon.anticon-right.ant-collapse-arrow svg{
            transform: rotate(-90deg) !important;
        }
    }
    
    .ant-collapse.ant-collapse-icon-position-start.ant-collapse-ghost {
        
        .ant-collapse-item {
            background-color: white;
        }

        .ant-collapse-header {
            span {
                font-size: 8px !important;
            }
        }
    }
}