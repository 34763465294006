.background-page.workspaces {
  display: flex;
}

.container__workspaces {
  margin: 0 auto;
  padding: 40px;
  box-sizing: content-box;
  flex: 1;
  display: flex;
  flex-direction: column;

  header {
    padding: 0;
    margin: 0;
  }

  .input-junction .input {
    background: #ffffff;
  }

  > div {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;

    button {
      margin-top: 1rem;
      min-width: 148px;

      &:last-of-type {
        margin-left: auto;
      }
    }
  }

  main {
    margin-top: 40px;
  }
}
