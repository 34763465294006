@import "/src/common/styles/colors.scss";

.fixed-loading {
    z-index: 9;
    width: 100%;
    display: flex;
    min-height: 100vh;
    position: absolute;
    align-items: center;
    background-color: #ffffff74;
}

.pl-card-details-pdf {
    print-color-adjust: exact;
    // 
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: getColor(background, light, .9);

    .pl-card-details-content {
        margin: 0 auto;
        max-width: 1280px;
        box-sizing: content-box;
        padding: 48px 84px !important;

        .title-part {
            display: flex;
            font-size: 32px;
            font-weight: 700;
            margin: 64px 0 32px;
            align-items: center;
            justify-content: space-between;
        }

        .subtitle-part {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
}