@import "/src/common/styles/colors.scss";

.text-empty {
    font-size: 14px;
    padding-top: 10px;
    text-align: center;
}

.ant-spin {
    display: flex;
    justify-content: center;

    svg {
        color: getColor(primary);
    }
}

.search-select {
    .ant-select-selector {
        font-size: 13px;
        align-items: center;
        justify-content: center;
        height: 40px !important;
        color: #4a4a4a !important;
        box-shadow: none !important;
        border-radius: 12px !important;
        border: 1px solid #D9D9D9 !important;
        background-color: #FAFAFA !important;

        .ant-select-selection-item {
            color: getColor(text, black)
        }
    }

    .ant-select-selection-search-input {
        height: 40px !important;
    }

    .ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
        background-color: getColor(primary, light) !important;
    }

    .ant-select-item-option.ant-select-item-option-selected {
        background-color: getColor(primary, light) !important;
    }

    // item 
    .ant-select-item-option,
    .ant-select-item-option.ant-select-item-option-active {
        padding: 10px;
        font-size: 14px;
        color: getColor(text, medium);
        background-color: getColor(background, lightest) !important;

        &:hover {
            background-color: getColor(primary, light) !important;
        }
    }

    .ant-select-selector {
        .ant-select-selection-placeholder {
            display: flex;
            font-size: 13px;
            color: #767676;
            line-height: 40px;
            align-items: center;
        }
    }

    .ant-select-arrow {
        .anticon svg {
            fill: getColor(text, dark);
        }

        .icon {
            color: getColor(text, dark);
        }
    }

    .ant-select-item,
    .ant-select-selection-item {
        text-transform: capitalize;
    }
}