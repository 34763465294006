@import "/src/common/styles/colors.scss";

.category-card {
    padding: 8px 0;
    margin-bottom: 8px;
    animation: fadeCard .3s;
    transition: all 0.2s ease-in-out;

    @keyframes fadeCard {
        from {
            opacity: 0;
            transform: scale(0);
        }

        to {
            opacity: 100;
            transform: scale(1);
        }
    }

    .category-card-header {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        flex-direction: column;
        border-radius: 6px 4px 0 0;
        justify-content: space-between;

        .category-name {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 18px;
        }

        .icon {
            font-size: 25px;
            margin-right: 4px;
            margin-bottom: 16px;
            color: var(--category-color);
        }

        .category-airscore {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            color: var(--category-color);

            .icon {
                font-size: 16px;
                margin: 0 4px 2px 0;
            }

            .score {
                font-size: 17px;
                font-weight: 600;
            }
        }
    }

    .category-card-body {
        overflow: hidden;
        transition: .2s all ease-in-out;
        &.closed {
            max-height: 0;
            overflow: hidden;
        }

        &.opened {
            max-height: 500px;
        }

        .row-values {
            width: 100%;
            display: flex;
            padding: 4px 8px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .name-percent {
                width: 100%;
                display: flex;
                margin-bottom: 0;
                justify-content: space-between;

                .name {
                    width: 80%;
                    overflow: hidden;
                    text-align: left;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            &:last-child {
                border-radius: 0 0 4px 4px;
            }
        }
    }
}