@import "/src/common/styles/colors.scss";

.similar-profiles {
    margin-bottom: 82px;

    .influencers {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .similar-influencer-card {
            border-radius: 8px;
            width: calc(25% - 12px);
            background-color: #FFF;
            border: 1px solid #F2F2F2;

            .influencer-card-header {
                padding: 18px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .box-avatar {
                    position: relative;

                    &.rapid-growth {
                        .avatar {
                            border: 3px solid getColor(primary, dark);
                        }

                        .icon-rocket {
                            top: 22px;
                            left: 24px;
                            width: 14px;
                            height: 14px;
                            color: #FFF;
                            display: flex;
                            font-size: 12px;
                            position: absolute;
                            border-radius: 50%;
                            align-items: center;
                            justify-content: center;
                            background-color: getColor(primary, dark);
                        }
                    }

                    .ant-avatar {
                        width: 36px !important;
                    }
                }

                .name-network {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 54px);
                    align-items: flex-start;

                    .name {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 600;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .network {
                        display: flex;
                        align-items: center;

                        img {
                            height: 10px;
                            margin-top: -2px;
                        }

                        span {
                            font-size: 12px;
                            padding: 0 8px 0 4px;
                            text-transform: capitalize;
                        }

                        .icon-external {
                            font-size: 18px;
                        }
                    }
                }
            }

            .influencer-card-body {

                .sample-data:nth-child(1),
                .sample-data:nth-child(3) {
                    background-color: #F2F2F2;
                }

                .sample-data {
                    display: flex;
                    padding: 8px 18px;
                    justify-content: space-between;

                    .data-name {
                        font-size: 14px;
                    }

                    .data-value {
                        font-size: 14px;

                        &.themes>div {
                            width: 28px;
                            height: 28px;
                        }

                        &.themes {
                            display: flex;
                            align-items: center;

                            .circle-theme {
                                top: 0;
                                z-index: 8;
                                opacity: .2;
                                width: 28px;
                                height: 28px;
                                padding: 9px;
                                border-radius: 50%;
                                position: absolute;
                            }

                            .icon {
                                height: 7px;
                                width: 28px;
                            }
                        }
                    }
                }
            }
        }
    }

    .empty {
        width: 100%;
        padding: 76px 0;
        font-size: 24px;
        border-radius: 6px;
        text-align: center;
        background-color: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-weight: 700;
        }
    }
}