.ant-modal-content {
    border-radius: 8px !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

    .title {
        margin-bottom: 16px;
    }
    .subtitle-modal {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }
}

// .ant-modal-footer {
//     border: none !important;
//     padding-bottom: 25px !important;

//     .blue-button,
//     .red-button,
//     .white-button {
//         min-width: 150px;
//         height: 35px !important;
//     }
// }
