.add_influencer_modal__rm {
  width: 26rem !important;
  padding: 0;
  top: 0;
}

.profiles__add_influencer_modal__rm {
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.profile_selected__add_influencer_modal__rm {
  display: flex;
  padding: 8px 12px 6px;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  transition: all 0.2s;

  display: flex;
  align-items: center;
  gap: 8px;

  div:last-of-type {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    b {
      font-size: 12px;
      color: #484848;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      font-size: 12px;
      display: block;
      color: #484848;
    }
  }

  button {
    all: unset;
    color: #ed276a;
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
  }
}

.profile__add_influencer_modal__rm {
  display: flex;
  flex-direction: column;
  padding: 8px 12px 6px;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-color: #ed276a;
  }

  footer {
    margin-top: 8px;
    display: flex;
    gap: 4px;

    font-size: 12px;
  }
}

.header__add_influencer_modal__rm {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  div:last-of-type {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    b {
      font-size: 12px;
      color: #484848;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      font-size: 12px;
      display: block;
      color: #484848;
    }
  }
}

.avatar__add_influencer_modal__rm {
  height: 44px;
  min-width: 44px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    border-radius: 999px;
    object-fit: cover;
  }

  svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.buttons__add_influencer_modal__rm {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  button:first-of-type {
    background: #ffffff !important;
    border: 1px solid #ed276a;
    color: #ed276a !important;

    &:hover {
      border-color: #ed276a;
    }
  }
}
