@import "/src/common/styles/colors.scss";


.pl-card-details {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;

    .btn-back {
        margin: 50px 0 20px;
    }

    .pl-card-details-content {
        margin: 0 auto;
        max-width: 1280px;
        box-sizing: content-box;
        padding: 0 84px 48px !important;

        .title-part {
            display: flex;
            font-size: 32px;
            font-weight: 700;
            margin: 64px 0 32px;
            align-items: center;
            justify-content: space-between;
        }

        .subtitle-part {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    .empty-list {
        display: flex;
        margin-top: -82px;
        margin-bottom: 32px;
        padding-left: 260px;
        flex-direction: column;
        align-items: flex-start;
        
        .text-empty {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 15px;
            text-align: left;
        }
        
        .question-empty {
            font-size: 16px;
            min-width: 224px;
            margin-bottom: 27px;
        }
    }
}

.aira-modal {
    .modal-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        text-align: center;
    }

    .modal-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        
        img {
            width: 181px;
            margin: 0 auto 12px;
        }

        p {
            margin: 24px 0;
            text-align: center;
        }

        .ant-btn {
            width: 100%;

            &.outline-light {
                margin-top: 12px;
            }
        }
    }
}