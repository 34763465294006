@import "/src/common/styles/colors.scss";


.search-filters {

    .influencer-filters,
    .audience-filters {
        display: none;
        animation: fadeFilters .3s;
        transition: all 0.2s ease-in-out;

        @keyframes fadeFilters {
            from {
                opacity: 0;
            }

            to {
                opacity: 100;
            }
        }

        &.show {
            display: block;
        }
    }

    .filter-line {
        justify-content: space-between;

        .divider {
            width: 1px;
            height: 65px;
            background-color: #d2d2d2;

            &.transparent {
                background-color: transparent;
            }
        }
    }

    .filter-line>div {
        width: 31%;
        margin: 12px 0;
    }

    .filter-types {
        gap: 16px;
        display: flex;
        margin-top: 48px;
        position: relative;
        margin-bottom: 38px;
        border-bottom: 2px solid #E6E6E6;

        .filter-type-option {
            display: flex;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            align-items: center;
            padding-bottom: 12px;
            transition: all .3s ease-in-out .0s;

            .tooltip {
                margin-top: -3px;
                margin-left: 8px;
            }

            &.unselected {
                cursor: pointer;
                font-weight: normal;
                color: #939393 !important;

                &:hover {
                    opacity: .6;
                }
            }
        }

        .underline {
            left: 0;
            height: 2px;
            bottom: -2px;
            width: 173px;
            position: absolute;
            background-color: #000;
            transition: all .3s ease-in-out .0s;

            &.audience {
                left: 189px;
                width: 167px;
            }
        }
    }

    .popover-btn {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .popover-item {
            width: calc(100% - 48px);
        }

        .icon-expand {
            width: 40px;
            height: 40px;
            display: flex;
            font-size: 20px;
            border-radius: 12px;
            align-items: center;
            justify-content: center;
            background-color: #FAFAFA;
            border: 1px solid #C3C3C3;
        }
    }

    .popover-item {
        width: 100%;

        .box-to-close {
            top: 30px;
        }

        .popover-button {
            height: 40px;
            border-radius: 12px;
            background-color: #FAFAFA;
            border: 1px solid #c6c6c6 !important;

            &.opened {
                background-color: #FFF;
                border-radius: 12px 12px 0 0;
            }

            .button {
                margin: 0 !important;
                border: none !important;
            }
        }

        .popover-content {
            top: 38px;
            width: 100%;

            .ant-radio-group {
                flex-direction: column;
            }

            .additional-content {
                font-size: 12px;
                margin-top: 24px;
                // color: #F70B53;
                color: getColor(primary);
            }
        }
    }

    .col-theme {
        .popover-btn {
            margin-bottom: 8px;

            .title {
                text-align: left;
            }
        }

        .text-clear-all {
            font-size: 13px;
            text-align: right;
            color: getColor(primary);
        }
    }

    .label-checkbox {
        label {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 8px;
        }

        .checkbox-container {
            margin-top: 8px;

            .ant-checkbox-wrapper {
                overflow: visible;


                min-height: 40px;
                display: flex;
                padding: 4px 12px;
                align-items: center;
                border-radius: 12px;
                background-color: #FAFAFA;
                border: 1px solid #c6c6c6 !important;

                .ant-checkbox {
                    margin-top: -8px;
                }

                .checkbox-text {
                    white-space: break-spaces;
                }
            }
        }
    }

    .card-form-items {
        padding: 12px 20px;
        border-radius: 8px;
        background-color: #FFF;
    }

    label {
        gap: 8px;
        display: flex;
    }
}

.ant-modal.engagement-modal {
    top: 0 !important;
    width: 90% !important;
    max-width: 646px !important;

    .title {
        margin-bottom: 8px !important;
    }

    .subtitle {
        margin-bottom: 8px !important;
    }

    table,
    td,
    th {
        border: 1px solid;
    }

    .text {
        width: 100%;
        overflow-x: auto;

        table {
            width: 100%;
            margin-bottom: 8px;
            border-collapse: collapse;
    
            thead th {
                padding: 17px 8px;
            }
    
            tbody td {
                padding: 2px 6px;
            }
    
            .engagement-rating {
                justify-content: flex-end;
                flex-direction: row-reverse;
    
                .columns {
                    margin-right: 8px;
                }
            }
        }
    }

    .buttons {
        margin-top: 20px !important;
    }
}