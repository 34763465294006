.skeleton-coparative-table {
    width: 100%;

    > .ant-row {
        width: 100%;
    }

    // .header {
    //     padding: 24px 0;
    //     .title {
    //         font-size: 24px;
    //     }
    // }

    .skeleton-right {
        text-align: right;
    }

    .skeleton-left {
        text-align: left;

        .ant-skeleton {
            width: 100%;

            .ant-skeleton-input {
                width: 50px !important;
                height: 21px !important;
            }
        }
    }

    .box-rounded {
        border-radius: 8px;
        background-color: white;
        width: 100%;
        padding: 8px;
    }

    .box-bar {
        padding: 16px;
        margin-bottom: 24px;
    }

    .row-metrics {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .col-metrics {
            display: inline-block;
            width: 18%;

            &:not(:first-child) {
                margin-left: 2%;
            }

            .box-content {
                padding: 0 20%;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .row-metrics {
            width: 100%;
            display: block;

            .col-metrics {
                display: block;
                width: 100%;
                margin: 0 !important;

                &:not(:last-child) {
                    margin-bottom: 12px !important;
                }
            }
        }
    }

    .box-metrics {
        height: 98px;
        text-align: center;

        .box-title {
            margin-bottom: 24px;
        }

        .tooltip-info {
            width: 100%;
            text-align: right;
            
            .tooltip-icon {
                position: relative;
                right: 4px;
                top: 4px;
                float: right;
            }
        }
    }

    .box-table-header {
        background-color: #e6e6e6;
        margin: 24px 0 12px 0;
    }

    .box-table-row {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 60px !important;
        }
    }
}
