@import "/src/common/styles/colors.scss";

.multi-select {
    .container-loading {
        margin: 35px auto 40px !important;
    }

    .options {
        height: 185px;
        overflow-y: auto;
        margin-top: 16px;
        overflow-x: hidden;
        padding-right: 8px;

        .checkbox-container {
            padding: 8px;
            // margin-bottom: 8px;

            .ant-checkbox-wrapper-checked {
                color: getColor(primary);
            } 

            .checkbox-text {    
                font-size: 14px;
            }
        }
    }

    .btn-clear {
        width: 100%;
        margin-top: 16px;
        padding-top: 16px;
        border-radius: 0;
        border-top: 1px solid #d8d8d8 !important;
    }
}