
@import "/src/common/styles/colors.scss";

.picked-item-include-exclude {
    width: 100%;
    margin-top: 8px;
    align-items: center;
    display: inline-flex;
    justify-content: space-between;

    .junction-item {
        display: flex;
        padding: 0px 8px;
        border-radius: 8px 8px 0 0;
        align-items: center;
        width: 100%;
        border: 1px solid #4a4a4a;

        &:not(.active) {
            border-radius: 8px;
        }

        &.rounded {
            border-radius: 8px;
        }
        
        .picked-label {
            margin: 0;
            width: 100%;
            height: 30px;
            display: flex;
            text-align: left;
            color: #4a4a4a;
            overflow: hidden;
            align-items: center;
            white-space: nowrap;
            
            span {
                width: 100%;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .btn-action {
            width: 16px;
            height: 16px;
            display: flex;
            font-size: 8px;
            cursor: pointer;
            margin-right: 8px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            border: 1px solid #999;
            background-color: #FFF;
            transition: all 0.2s ease-in-out;
            
            &:hover {
                opacity: .6;
            }

            &.active {
                cursor: default;
                border-color: #D8125E;
                background-color: #D8125E;
                color: getColor(text, white);
                transition: all 0.2s ease-in-out;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .btn-close {
        width: 12px !important;
        height: 12px !important;
        border: none !important;
        cursor: pointer;
        border-radius: 6px;
        background-color: #dddddd !important;
        transition: all 0.2s ease-in-out;
        position: relative;
        top: -15px;
        right: -22px;
        
        .icon {
            font-size: 8px;
            position: relative;
            left: 0.05em;
        }
  
        &:hover {
            opacity: .6;
        }
    }
}

.picked-item-options {
    width: 100%;
    display: block;
    text-align: left;
    border: 1px solid #4a4a4a;
    border-radius: 0 0 8px 8px;
    border-top: none;
    padding: 8px;

    .item-options-title {
        font-size: 12px;
    }

    &:not(.active) {
        display: none;
    }
}