@import "/src/common/styles/colors.scss";

.pl-box-floating-actions {
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    position: fixed;
    padding: 0 84px;
    transition: all .2s ease-in-out;
    background-color: #F2F2F2;

    .floating-actions {
        width: 100%;
        height: 48px;
        z-index: 99;
        display: flex;
        padding: 7px 0;
        margin: 0 auto;
        max-width: 1280px;
        position: relative;
        align-items: center;
        justify-content: flex-end;

        .popup-influencers-options {
            top: -15px;
            width: 364px;
            padding: 16px;
            display: flex;
            min-height: 250px;
            position: absolute;
            flex-direction: column;
            background-color: #FFF;
            transform: translateY(-100%);
            justify-content: space-between;
            box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.1);

            .influencer-options {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                justify-content: space-between;

                &.succeeded::after {
                    content: " ";
                    top: 86px;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background-color: #E6E6E6
                }

                .influencer-option {
                    width: 19%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .ant-avatar {
                        width: 40px;
                        height: 40px;
                        margin-bottom: 4px;
                    }

                    .influencer-name {
                        width: 100%;
                        height: 38px;
                        font-size: 12px;
                        overflow: hidden;
                        text-align: center;
                        margin-bottom: 24px;
                        word-wrap: break-word;
                    }
                }
            }
        }

        .comments {
            position: relative;

            &.with-comments {
                .icon {
                    color: getColor(primary);
                }
            }

            .counter {
                right: 6px;
                bottom: 4px;
                height: 14px;
                color: #FFF;
                padding: 0 2px;
                font-size: 8px;
                min-width: 14px;
                font-weight: 600;
                line-height: 14px;
                border-radius: 50%;
                position: absolute;
                text-align: center;
                background-color: getColor(primary);
            }
        }

        .container-modal-lists {
            height: 40px;
            font-size: 13px;
            color: #4a4a4a;
        }

        .ant-btn {
            padding: 0;
            font-weight: 400;
            min-width: auto !important;

            &:first-child {
                border-left: none !important;
            }

            >span {
                margin-bottom: 0;
            }
        }

        .ant-btn,
        .container-modal-lists {
            border-radius: 0;
            height: 20px !important;
            margin-left: 16px !important;
            padding-left: 16px !important;
            border-left: 1px solid #CCC !important;
        }

        .container-modal-lists {
            cursor: pointer;
            transition: all .2s ease-in-out;

            &:hover {
                opacity: .6;
            }
        }

        .icon {
            width: 32px;
            height: 32px;
            display: flex;
            font-size: 24px;
            cursor: pointer;
            margin: 0 4px 0 0;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            transition: all .2s ease-in-out;
            color: #FF7095 !important;

            &.icon-favorite {
                font-size: 20px;
            }

            &.icon-arrow-top {
                font-size: 13px;
            }

            &.icon-trash {
                color: getColor(status, error) !important;
            }
        }

        .icon-pink-star::before {
            font-size: 28px;
            color: getColor(primary);
            animation: openStarScale .4s;
            transition: all 0.4s ease-in;

            @keyframes openStarScale {
                0% {
                    transform: scale(2.5);
                }

                100% {
                    transform: scale(1);
                }
            }
        }
    }
}
