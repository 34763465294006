@import '/src/common/styles/colors.scss';

.drop-down {
  height: 40px;
  display: flex;
  font-size: 18px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  border: none !important;
  box-shadow: none !important;
  transition: 0.2s ease-in all;
  color: getColor(text, dark) !important;
  z-index: 99;

  &.ant-dd-children {
    font-size: 16px;
    padding: 0 11px !important;
    justify-content: space-between;
    color: getColor(text, medium, 0.5) !important;
    background-color: getColor(background, lightest) !important;
    border: 1px solid getColor(background, lightGrey) !important;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.ant-dropdown {
  height: 40px;

  ul {
    overflow-y: auto;
    max-height: 305px;

    li {
      font-size: 16px;
      padding: 10px 21px;
      color: getColor(text, medium);

      &.ant-dropdown-menu-submenu {
        padding: 0;

        .ant-dropdown-menu-submenu-title {
          font-size: 16px;
          color: #7e7e7e;
          padding: 10px 21px;

          &:hover {
            background-color: getColor(primary, light) !important;
          }
        }
      }

      &:hover {
        background-color: getColor(primary, light) !important;
      }

      .icon {
        margin-right: 12px;
      }

      .option-with-icon {
        display: flex;
        align-items: center;

        i {
          font-size: 19px;
          margin-right: 32px;
        }
      }
    }
  }

  &:after {
    box-shadow: none !important;
  }
}

.ant-dropdown-menu {
  border-radius: 8px;

  .ant-dropdown-menu-item {
    font-size: 16px;
    color: #7e7e7e;
    padding: 10px 21px;

    &:hover {
      background-color: getColor(primary, light) !important;
    }
  }
}
