.input_tags {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 12px;
  background: #ffffff;
  border-radius: 12px;
  min-height: 40px;
  border: 1px solid #d9d9d9;
  gap: 4px !important;
  overflow: hidden;

  > input {
    flex: 1;
    min-width: 11rem;
    font-size: 13px;
    color: #4a4a4a;
    border: none;
    outline: none;

    &::placeholder {
      color: #767676;
    }
  }
}

.tags__input_tags {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}

.tag__input_tags {
  background: #fee5f0;
  color: #ed276a;
  padding: 2px 12px;
  padding-right: 6px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #cd0945;
    }
  }
}
