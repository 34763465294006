@import "/src/common/styles/colors.scss";

.chart-container {
    position: relative;

    .highcharts-legend-checkbox:checked {
        background-color: green;
    }

    .highcharts-legend-checkbox::checked {
        background-color: blue;
    }

    .highcharts-legend-checkbox[type=checkbox]:checked {
        background-color: red;
    }

    .highcharts-legend-checkbox {
        margin-top: 1px;
        transform: translateX(-100%);
    }
}

.highcharts-legend-item {
    text-transform: capitalize;
}

.highcharts-point {
    cursor: pointer;
}

.highcharts-label .highcharts-data-label {
    .highcharts-data-label-color-0 {
        height: 60px;
    }
}

.donut-legend {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.label-circle-chart {
    width: 64px;
    height: 64px;
    display: flex;
    font-size: 18px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    color: #000 !important;
    box-shadow: 2px 7px 4px rgba(0, 0, 0, 0.02), 1px 3px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03);


    &.label-circle-chart-1 {
        top: -40px;
        left: 4px;
    }

    &.label-circle-chart-0 {
        top: -20px;
        left: -20px;
    }
}

.highcharts-credits {
    display: none;
}