@import "/src/common/styles/colors.scss";

.popover-btn-categories {
    .popover-content{
        padding: 0 !important;

        .title {
            padding: 18px 12px 0;
        }
    }
}

// padding: 18px 12px 12px;

.multi-select-categories {
    .input-junction {
        padding: 0 12px;
    }

    .options {
        height: 185px;
        overflow-y: auto;
        margin-top: 16px;
        padding-right: 0;
        overflow-x: hidden;

        .checkbox-container {
            padding: 8px 12px;

            &.subcategory {
                padding-left: 40px;
            }

            &.category {
                border-radius: 0;
                background-color: #E6E6E6;

                .ant-checkbox-wrapper {
                    margin-bottom: 0;
                }
            }

            .ant-checkbox-wrapper-checked {
                color: getColor(primary);
            }

            .ant-checkbox-wrapper {
                margin-bottom: 0;
            }

            .ant-checkbox + span {
                width: calc(100% - 15px) !important;
            }
            
            .checkbox-text {
                font-size: 14px;
                overflow: hidden;
                text-align: left;
                width: 100% !important;
            }
        }
    }

    .ant-btn {
        height: 52px;
        padding: 16px 0 !important;
    }
}