@import '/src/common/styles/colors.scss';

.field_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  input,
  select {
    appearance: none;
    padding: 0 16px;
    height: 3rem;
    border: 2px solid #4f454a;
    font-size: 14px;
    color: #4f454a;
    outline: none;
    transition: all 0.2s ease-in-out;

    &::placeholder {
      color: #4f454a;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px getColor(primary, base, 0.5);
      border-color: getColor(primary, base);
    }
  }

  input:autofill {
    -webkit-box-shadow: 0 0 0 2px getColor(primary, base, 0.5), 0 0 0px 40rem #ffffff inset;
  }

  input:not(:focus):autofill {
    -webkit-box-shadow: 0 0 0 2px transparent, 0 0 0px 40rem #ffffff inset;
  }

  label {
    padding: 0 4px;
    position: absolute;
    top: -11px;
    left: 14px;
    font-size: 14px;
    color: #4f454a;
    font-weight: 700;
    background: white;
  }

  select option:checked {
    background-color: getColor(primary, base);
    color: #ffffff;
  }

  .error_message__field_wrapper {
    color: #ff4d4f;
    font-size: 14px;
  }
}
