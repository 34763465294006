@import '/src/common/styles/colors.scss';

.header {
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 32px 0;
  max-width: 1280px;
  box-sizing: content-box;
  flex-direction: column;
  box-sizing: content-box;

  .title {
    margin: 0;
    display: flex;
    font-size: 24px;
    color: #4a4a4a;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 35px;
    align-items: center;

    .icon {
      font-size: 17px;
      cursor: pointer;
      margin-right: 12px;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .subtitle {
    font-size: 13px;
    margin: 16px 0 0 0;
  }
}

// @media only screen and (max-width: 1444px) {
//     .header {
//         box-sizing: border-box;
//     }
// }
