.add_influencer_modal__rm {
  width: 26rem !important;
  padding: 0;
  top: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profiles__add_influencer_modal__rm {
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    svg {
      color: #ed276a;
      animation: spin 1s linear infinite;
    }
  }
}

.profile_selected__add_influencer_modal__rm {
  display: flex;
  padding: 8px 12px 6px;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  transition: all 0.2s;

  display: flex;
  align-items: center;
  gap: 8px;

  div:last-of-type {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    b {
      font-size: 12px;
      color: #484848;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      font-size: 12px;
      display: block;
      color: #484848;
    }
  }

  button {
    all: unset;
    color: #ed276a;
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
  }
}

.profile__add_influencer_modal__rm {
  display: flex;
  flex-direction: column;
  padding: 8px 12px 6px;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-color: #ed276a;
  }

  footer {
    margin-top: 8px;
    display: flex;
    gap: 4px;

    font-size: 12px;
  }
}

.no-results__add_influencer_modal__rm {
  margin-top: 16px;
  display: flex;
  justify-content: center;

  p {
    margin: 0;
    text-align: center;
  }
}

.header__add_influencer_modal__rm {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  div:last-of-type {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    b {
      font-size: 12px;
      color: #484848;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      font-size: 12px;
      display: block;
      color: #484848;
    }
  }
}

// .avatar__add_influencer_modal__rm {
//   height: 44px;
//   min-width: 44px;
//   position: relative;
// }

.buttons__add_influencer_modal__rm {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  button:first-of-type {
    background: #ffffff !important;
    border: 1px solid #ed276a;
    color: #ed276a !important;

    &:hover {
      border-color: #ed276a;
    }
  }
}

.avatar-network .network-logo {
  height: 16px;
  width: 16px;
  right: 0;
  bottom: 0;
}