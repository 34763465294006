@import "/src/common/styles/colors.scss";

.aira-curation-result-page {
    color: getColor(text, dark);

    .logo {
        height: 20px;
    }

    h1 {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 4px 0;
    }

    h2 {
        font-size: 14px;
        font-weight: 600;
        margin: 20px 0 2px;
    }

    .box {
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        background-color: #FFF;
        border: 1px solid #CBC4C7;

        .aira {
            width: 50px;
            height: 50px;
            margin-right: 16px;
        }

        .observation {
            color: #333;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 0;
            width: calc(100% - 66px);

            .chat-name {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .ant-btn {
            width: 100%;
            margin-top: 10px;
            margin-left: 66px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .aira-curation-result-page {
        h1 {
            font-size: 22px;
            margin: 0 0 4px 0;
        }

        h2 {
            font-size: 14px;
            margin: 20px 0 2px;
        }

        .box {
            padding: 14px;

            .aira {
                width: 60px;
                height: 60px;
            }

            .observation {
                font-size: 13px;
                width: calc(100% - 76px);

                .chat-name {
                    font-size: 18px;
                }
            }

            .ant-btn {
                margin-top: 12px;
                margin-left: 76px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .aira-curation-result-page {
        width: 100%;
        box-sizing: border-box !important;

        h1 {
            font-size: 24px;
            margin: 0 0 24px 0;
        }

        h2 {
            font-size: 22px;
            margin: 24px 0 4px;
        }

        .box {
            padding: 16px;

            .aira {
                width: 50px;
                height: 50px;
            }

            .observation {
                font-size: 16px;
                width: calc(100% - 66px);

                .chat-name {
                    font-size: 20px;
                }
            }

            .ant-btn {
                margin-top: 12px;
                margin-left: 66px;
            }
        }
    }
}

@media only screen and (min-width: 1495px) {
    .aira-curation-result-page {
        width: 100%;
        box-sizing: content-box !important;
    }
}