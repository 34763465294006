@import "/src/common/styles/colors.scss";

.container-modal-lists>span {
    display: flex;
    align-items: center;
}

.container-modal-lists {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        cursor: pointer;
        margin-left: 4px;
        transition: .2s ease-in all;

        &:hover {
            opacity: 0.6;
            transition: .2s ease-in all;
        }
    }

    .icon-favorite {
        padding: 8px;
        font-size: 22px;
        margin-left: -8px;

        &.active {
            color: #FFF;
            border-radius: 50%;
            background-color: getColor(primary) !important;
        }
    }

    .icon-my-list-circle {
        color: getColor(text, dark);
    }

    .ant-btn {
        background-color: #333 !important;
    }
}

.ant-popover-placement-bottom {
    padding: 0;
}

.ant-popover {
    .ant-popover-content {
        .ant-popover-inner {
            border-radius: 40px;
        }

        .ant-popover-arrow {
            display: none;
            border-top-color: getColor(text, dark);
            border-left-color: getColor(text, dark);
        }
    }
}

.ant-popover-inner-content {
    padding: 0;

    .modal-lists {
        width: 280px;
        overflow: hidden;
        border-radius: 6px;
        transition: .2s ease-in all;
        transition: max-height 0.3s ease-out;
        background-color: getColor(background, lightest);
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.13);

        &.open-modal-lists {
            height: 330px;
            transition: max-height 0.3s ease-out;
        }

        .modal-lists-header {
            padding: 14px 16px;
            position: relative;
            border-radius: 6px 4px 0 0;
            color: getColor(text, dark);

            slice .modal-lists-header-title {
                font-size: 18px;
                margin-right: 10px;
                text-transform: uppercase;
            }

            .icon {
                top: 54%;
                right: 16px;
                cursor: pointer;
                font-size: 18px;
                position: absolute;
                transform: translateY(-62%);
                transition: all .2s ease-in-out;

                &:hover {
                    opacity: .4;
                }
            }
        }

        .modal-lists-content {
            padding: 10px 16px 16px;

            .input-junction {
                flex-direction: row;

                .ant-input::placeholder {
                    font-size: 14px;
                }

                .input {
                    background-color: #FFF;
                    box-shadow: none !important;
                    border-radius: 12px 0 0 12px;
                    border: 1px solid #d9d9d9 !important;
                }

                .suffix {
                    width: 40px;
                    height: 40px;
                    z-index: 99;
                    position: relative;
                    background-color: getColor(primary);
                    
                    .icon {
                        padding: 10px;
                    }
                    
                    &.disabled {
                        cursor: not-allowed;
                        background-color: #fb90b4 !important;                        
                    }

                    &:hover {
                        opacity: 1 !important;
                        background-color: #fb90b4 !important;                        
                    } 
                }
            }

            .modal-lists-junction {
                margin-top: 10px;
                overflow-y: auto;
                max-height: 200px;

                .option-list {
                    width: 100%;
                    height: 32px;
                    display: flex;
                    text-align: left;
                    overflow: hidden;
                    text-align: left;
                    align-items: center;

                    .ant-checkbox-wrapper {
                        width: 100%;
                        
                        .ant-checkbox+span {
                            text-wrap: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: calc(100% - 30px);
                        }
                    }

                    .ant-checkbox-inner::after {
                        top: 50%;
                        left: 50%;
                        transform: translate(-35%, -60%) rotate(45deg);
                    }

                    .ant-checkbox-checked::after {
                        border: 1px solid transparent !important;
                    }

                    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
                        border-color: getColor(primary) !important;
                    }

                    .ant-checkbox-wrapper {

                        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
                        .ant-checkbox:hover .ant-checkbox-inner,
                        .ant-checkbox-input:focus+.ant-checkbox-inner {
                            border-color: getColor(primary) !important;
                        }

                        .ant-checkbox-inner {
                            &:hover {
                                border-color: getColor(primary) !important;
                            }
                        }

                        .ant-checkbox-checked .ant-checkbox-inner {
                            border-color: getColor(primary) !important;
                            background-color: getColor(primary);
                        }

                    }
                }

                .pl-loading {
                    display: flex;
                    align-items: center;

                    .ant-spin {
                        display: flex;
                        align-items: center;

                        .ant-spin-dot {
                            width: 16px;
                            height: 16px;

                            .ant-spin-dot-item {
                                width: 8px;
                                height: 8px;
                                background-color: getColor(primary);
                            }
                        }
                    }

                    span {
                        padding: 0 8px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}