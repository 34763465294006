@import "/src/common/styles/colors.scss";

.influencer-card-container {
    transition: all .2s ease-in-out;
}

.influencer-card-pdf {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    color: getColor(text, black);
    // background-color: getColor(background, light, .9) !important;

    .influencer-card-content {
        height: 100%;
        margin: 0 auto;
        position: relative;
        box-sizing: content-box;

        header {
            max-width: none;
            padding: 16px 64px;
            margin: 48px auto 56px;
            box-sizing: border-box;
            background-color: #FFF;

            div {
                width: 100%;
                display: flex;
                margin: 0 auto;
                max-width: 1465px;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 312px;
                }

                span {
                    font-size: 18px;
                }
            }
        }

        .icon-certificate {
            cursor: auto;
            font-size: 22px;
            margin-right: 12px;
            color: getColor(status, info);
        }

        .classification {
            height: 26px;
            color: #FFF;
            font-size: 12px;
            font-weight: 700;
            max-width: 100px;
            padding: 4px 16px;
            text-align: center;
            margin-right: 12px;
            border-radius: 24px;
            text-transform: capitalize;
            background-color: getColor(primary) !important;
        }

        .title-part {
            color: #FFF;
            display: flex;
            font-size: 30px;
            max-width: 1465px;
            font-weight: 700;
            padding: 18px 48px;
            border-radius: 10px;
            align-items: center;
            margin: 0 auto 24px;
            background-color: getColor(primary) !important;

            .icon {
                font-size: 34px;
                margin-right: 24px;
            }
        }

        .subtitle-part {
            font-size: 24px;
            max-height: 70px;
            font-weight: 600;
            padding: 16px 32px;
            border-radius: 8px;
            margin-bottom: 16px;
            color: #AB0932 !important;
            background-color: #FFE5ED !important;

            .icon {
                font-size: 28px;
                margin-right: 12px;
            }
        }

        .category-card {
            animation: none !important;
            transition: none !important
        }

        .text-empty {
            height: 100%;
            font-size: 22px;
            max-width: 260px;
            margin: 146px auto 0;
        }

        .text-error {
            display: flex;
            padding: 24px;
            margin: 0 auto;
            font-size: 16px;
            min-height: 200px;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
        }

        .data-types {
            display: flex;
            position: relative;
            margin-bottom: 38px;

            .data-type-option {
                width: 50%;
                font-size: 24px;
                font-weight: 700;
                padding-bottom: 4px;
                border-bottom: 4px solid #CCC;
                transition: all .2s ease-in-out;

                &.unselected {
                    cursor: pointer;
                    color: #939393 !important;

                    &:hover {
                        opacity: .6;
                    }
                }
            }

            .underline {
                left: 0;
                bottom: 0;
                width: 50%;
                height: 4px;
                position: absolute;
                background-color: #333;
                transition: all .2s ease-in-out;

                &.publipost {
                    left: 50%;
                }
            }
        }

        .chart-interactions-description {
            display: flex;
            justify-content: space-between;

            .total-values {
                width: 49%;
                display: flex;
                padding: 12px;
                font-size: 16px;
                color: #333333;
                margin-top: 16px;
                border-radius: 8px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: #FAFAFA;

                .title-value {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 4px;
                }

                .values {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;

                    .value {
                        width: 49%;
                        font-size: 14px;
                        padding-top: 12px;
                        text-align: center;
                        white-space: nowrap;

                        b {
                            font-size: 14px;
                        }

                        &:nth-child(3) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .modal-mention-posts {
        top: 0;
        opacity: 1;
        z-index: 99;
        width: 100%;
        display: flex;
        height: 100vh;
        position: absolute;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.4);

        .mention-posts-card-content {
            width: 80%;
            overflow: auto;
            overflow: hidden;
            max-width: 1226px;
            min-height: 360px;
            padding: 42px 32px;
            border-radius: 8px;
            background-color: #FFF;
            transition: .2s all ease-in-out;
            animation: openScale .4s;
            transition: all 0.4s ease-in;

            .influencer-posts {
                margin: 0;
            }

            @keyframes openScale {
                0% {
                    transform: scale(0);
                }

                100% {
                    transform: scale(1);
                }
            }

            .posts-card {
                padding: 36px 0 24px;
            }
        }
    }
}

.highcharts-tooltip-container {
    z-index: 30 !important;
}