@import "/src/common/styles/colors.scss";

.solicitation-page {
    width: 100%;
    height: 100%;

    .container-btn-back {
        padding: 0 0 7px;

        .btn-back {
            padding: 0;
            box-shadow: none;
        }
    }

    .solicitation-content>div {
        padding: 20px 84px 84px;
    }

    .solicitation-content {
        height: 100%;
        margin: 0 auto;
        max-width: 1280px;
        position: relative;
        box-sizing: content-box;

        .title {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 24px;
        }

        .date,
        .influencer-link,
        .list {
            font-size: 13px;
            margin-bottom: 8px;
        }

        .list {
            margin-bottom: 24px;
        }

        .subtitle {
            font-size: 24px;
            font-weight: 600;
            margin: 40px 0 24px;
        }

        .tabs {
            margin-bottom: 24px;
        }

        table {
            width: 100%;
            overflow-x: auto;
            border-spacing: 0 8px;
            border-collapse: separate;

            .div-flex {
                display: flex;
                align-items: center;
            }

            thead {
                tr {
                    border-radius: 8px;
                    padding-bottom: 16px;

                    th {
                        margin: 1px;
                        padding: 16px;
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        position: relative;
                        background-color: #E6E6E6;
                        color: getColor(text, dark);
                        border-right: 1px solid #D9D9D9;

                        &.network {
                            white-space: nowrap;
                        }

                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:last-child {
                            border: none;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }

                        .icon-down {
                            margin-top: -2px;
                            margin-left: 8px;
                            transition: all .2s ease-in-out;

                            &.smaller {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        .influencers-declined {
            width: 100%;
            display: flex;
            overflow: hidden;
            flex-direction: column;

            .influencer-card-error {
                width: 100%;
                display: flex;
                border-radius: 8px;
                padding: 15px 32px;
                align-items: center;
                margin-bottom: 8px;
                background-color: #FFF;
                justify-content: flex-start;

                .tag-box {
                    .tag {
                        color: #000;
                        font-size: 13px;
                        padding: 6px 10px;
                        border-radius: 18px;
                        border: 1px solid #000;
                        background-color: #FFF;

                        &.tag-error {
                            color: #760909;
                            border: 1px solid #760909;
                            background-color: #FADDDD;
                        }
                    }
                }

                .values {
                    display: flex;
                    overflow: hidden;
                    margin-left: 40px;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;

                    .link {
                        font-size: 13px;
                        margin-bottom: 0;
                    }

                    .text-error {
                        font-size: 13px;
                        font-weight: 700;
                        color: #CC1F1F;
                        margin: 13px 0 0;

                        span {
                            padding-left: 4px;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}