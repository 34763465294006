@import "/src/common/styles/colors.scss";

.slider-container {
    &.with-marks {
        .slider-values {
            margin-top: 30px;
        }
    }

    .slider-values {
        display: flex;
        margin-top: 16px;
        border-radius: 6px;
        text-align: center;
        align-items: center;
        justify-content: space-between;

        .slider-value {
            width: 49%;
            display: flex;
            padding: 4px 8px;
            border-radius: 8px;
            flex-direction: column;
            align-items: flex-start;
            border: 1px solid rgba(51, 51, 51, 0.15);

            .span-title {
                font-size: 10px;
                color: #989898;
            }

            .span-value {
                font-size: 14px;
                font-weight: 500;
            }

            input {
                padding: 0;
                width: 100%;
                border: none; 
                outline: none;
            }
        }

        &.one-vlaue .slider-value {
            width: 100%;
        }
    }

    .ant-slider {
        margin: 11px 6px 0;

        .ant-slider-step,
        .ant-slider-rail {
            height: 1px;
            border-radius: 6px;
        }

        .ant-slider-track.ant-slider-track-1,
        .ant-slider-track {
            height: 1px;
            border-radius: 6px;
            background-color: #000;
        }

        .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: #000;
        }

        .ant-slider-handle.ant-slider-handle-1.ant-tooltip-open,
        .ant-slider-handle.ant-slider-handle-1,
        .ant-slider-handle.ant-slider-handle-2,
        .ant-slider-handle.ant-slider-handle-2.ant-tooltip-open,
        .ant-slider-handle {
            width: 18px;
            height: 18px;
            background-color: #FFF;
            border: 3px solid getColor(primary);
            margin-top: -8px;
        }

        .ant-slider-handle:focus {
            box-shadow: none;
        }

        .ant-slider-step {
            .ant-slider-dot {
                
                &.ant-slider-dot-active {
                    margin-top: -1px;
                    border-color: getColor(primary);
                    background-color: getColor(primary);
                }
            }
        }

        .ant-slider-mark {
            .ant-slider-mark-text {
                font-size: 9px;
                margin-top: 3px;
            }
        }
    }

    .ant-tooltip {
        z-index: 1;

        .ant-tooltip-inner {
            padding: 0 6px;
            min-height: auto;
            margin-bottom: -8px;

        }
    }

    .slider-indicators {
        width: 100%;
        position: relative;

        .slider-indicator {
            top: -15px;
            font-size: 12px;
            padding: 3px 6px;
            letter-spacing: 0;
            line-height: 16px;
            position: absolute;
            border-radius: 6px;
            box-sizing: border-box;
            color: getColor(text, white);
            transform: translate(-50%, -150%);
            background-color: getColor(text, dark);

            &::after {
                content: " ";
                width: 0;
                height: 0;
                left: 50%;
                bottom: -4px;
                position: absolute;
                transform: translate(-50%);
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 6px solid getColor(text, dark);
            }
        }
    }

    &.slider-simple {
        .ant-slider {

            .ant-slider-step,
            .ant-slider-track.ant-slider-track-1,
            .ant-slider-track {
                background-color: getColor(secondary) !important;
            }

            .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
                border-color: getColor(secondary) !important;
            }

            .ant-slider-handle {
                background-color: #FFF !important;
                border-color: getColor(primary) !important;
            }
        }
    }

    .option-values {
        .ant-radio-group {
            margin-top: 16px;
            flex-direction: column;

            .ant-radio-wrapper {
                margin-bottom: 4px;

                .ant-radio {
                    margin: 10px 0;
                    height: 18px !important;
                }
            }
        }
    }
}

.ant-tooltip.ant-slider-tooltip {
    .ant-tooltip-content {
        .ant-tooltip-inner {
            margin-bottom: -2px;
            padding: 4px 8px !important;
            min-height: auto !important;
        }
    }
}