@import '/src/common/styles/colors.scss';

.influencer-preview-modal-content {
  padding: 0 !important;

  .influencer-preview {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;

    .container-loading {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 !important;
      justify-content: center;
    }

    .anticon-close {
      top: 16px;
      left: -50px;
      padding: 6px;
      font-size: 20px;
      border-radius: 8px;
      position: absolute;
      background-color: #fff;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.13);
    }

    .influencer-profile {
      margin-bottom: 0;
      overflow: hidden;

      .avatar-network {
        span {
          width: 100px !important;
          height: 100px !important;
        }
      }

      .network-logo {
        right: 0;
        bottom: 0;
        width: 32px !important;
      }

      .influencer-data {
        width: calc(100% - 120px);
        margin-left: 11px;

        .name {
          width: 100%;
          overflow: hidden;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 20px !important;

          .icon {
            font-size: 16px !important;
          }
        }

        .bio {
          font-size: 12px;
          height: auto !important;
          margin: 4px 0 8px !important;
        }

        .about {
          margin: 0 0 8px !important;

          .about-data-junction {
            flex-direction: column;

            .about-data {
              padding: 0;
              border: none;
              height: auto;
              display: block;
              margin: 0 0 4px;
            }
          }
        }

        .stamps {
          display: flex;
          flex-wrap: wrap;
          margin: 0 0 8px;

          > div {
            margin: 0;

            &:first-child {
              margin-top: 4px;
            }
          }

          p {
            margin-bottom: 4px;
          }

          .accelerated-growth {
            margin: 0;
          }
        }
      }
    }

    .data-scroll {
      overflow-y: auto;
      padding-right: 4px;
      padding: 16px 24px;
      height: calc(100vh - 74px) !important;

      .comments {
        margin-bottom: 10px;

        .title-part {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .comment {
          padding: 16px;
          overflow: hidden;
          position: relative;
          border-radius: 8px;
          margin-bottom: 12px;
          background-color: #f2f2f2;

          .text {
            font-size: 14px;
            color: #4a4a4a;
            line-height: 18px;
          }

          .about {
            gap: 12px;
            display: flex;
            font-size: 12px;
            margin-top: 24px;
            align-items: center;
            justify-content: flex-end;

            .is-edited,
            .author {
              position: relative;

              &:after {
                top: 7px;
                right: -8px;
                content: '';
                width: 3px;
                height: 3px;
                position: absolute;
                background: #999;
                border-radius: 50%;
              }
            }
          }
        }

        .other-comments {
          display: flex;
          margin-top: 12px;
          margin-bottom: 24px;
          justify-content: flex-end;

          .text-other-comments {
            font-size: 14px;
            padding-right: 7px;
            text-decoration: underline;
          }

          .count {
            color: #fff;
            display: flex;
            padding: 0 4px;
            font-size: 12px;
            border-radius: 50%;
            text-align: center;
            align-items: center;
            justify-content: center;
            min-width: 22px !important;
            background-color: getColor(primary);
          }
        }
      }

      .data-values {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        justify-content: space-between;

        .container-loading {
          width: 100%;
          margin: 40px auto 70px !important;
        }

        .data-value {
          padding: 16px;
          border-radius: 8px;
          margin-bottom: 12px;
          width: calc(50% - 8px);
          background-color: #f2f2f2;

          .value {
            width: 100%;
            font-size: 34px;
            font-weight: 700;
            overflow: hidden;
            margin-bottom: 12px;
            position: relative;
            white-space: nowrap;
            text-decoration: none;
            text-overflow: ellipsis;
          }

          .name {
            margin: 0;
            display: flex;
            font-size: 14px;
            color: #747474;
            font-weight: 400;
            align-items: baseline;

            > button {
              cursor: pointer;

              &:hover {
                opacity: 0.75;
              }
            }

            .level_gauge__wrapper {
              margin-left: 8px;
              padding: 0;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              background: none;
              transition: opacity 0.2s;
            }

            .tooltip {
              margin-left: 8px;
            }
          }
        }
      }

      .title-part {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 16px;
      }

      .follower-data {
        gap: 16px;
        display: flex;
        border-radius: 8px;
        padding: 16px 24px;
        margin-bottom: 32px;
        flex-direction: column;
        background-color: #f2f2f2;

        .item {
          width: 100%;

          .value-name {
            font-size: 16px;
            margin-bottom: 4px;
          }

          .junction-value {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
              display: flex;
              align-items: center;

              .icon {
                font-size: 24px;
                margin-right: 8px;
                color: getColor(primary);
              }

              b {
                font-size: 16px;
              }
            }

            .value {
              font-size: 14px;
            }
          }

          .no-data {
            color: #000000;
          }
        }
      }

      .influencer-posts {
        margin: 24px 0;

        .title-part {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .post-filters {
          justify-content: space-between;
        }

        .posts-card {
          border-radius: 8px;
          padding-bottom: 16px;
          background-color: #f2f2f2;

          .posts {
            overflow-x: auto;
            flex-wrap: nowrap;
            min-height: 300px;
            margin-bottom: 16px;
            padding-bottom: 11px;

            .instagram-post {
              width: 286px;
              background-color: #fff;
            }

            .youtube-post {
              width: 266px;
              min-height: 220px;
              max-height: 270px;
              background-color: #fff;

              .thumb {
                height: 188px !important;
              }
            }

            .twitch-post {
              width: 266px;
              min-height: 220px;
              max-height: 290px;
              background-color: #fff;

              .thumb {
                height: 188px !important;
              }
            }

            .twitter-post {
              width: 275px;
              background-color: #fff;

              .post-interactions {
                gap: 8px;

                .interaction-data {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .influencer-mentions {
        display: flex;
        margin-bottom: 0;
        flex-direction: column;

        .title-part {
          display: flex;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 16px;
          align-items: center;
          justify-content: space-between;
        }

        .junction-mentions {
          .list-mentions {
            width: 100%;
            overflow: hidden;
            min-height: 240px;
            max-height: 240px;
            background-color: #f2f2f2;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .influencer-preview-actions {
      display: flex;
      padding: 0 24px 16px;
      align-items: flex-end;
      height: 60px !important;
      justify-content: space-between;

      .ant-btn {
        width: 49%;
      }
    }
  }
}
