.display_mode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.button__display_mode {
  padding: 2px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  outline: 2px solid transparent;
  transition: 0.2s;

  &[data-state='true'] svg {
    color: #ed276a;
  }

  &:focus-visible {
    background: #fef1f7;
    outline: solid 2px #ed276a;
  }
}
