@import "/src/common/styles/colors.scss";

.home-page {
    .container-btn-back {
        margin: 0 auto;
        max-width: 1280px;
        padding: 50px 84px 0;
        box-sizing: content-box;

        .btn-back {
            margin-bottom: -30px;
        }
    }

    header {
        padding: 50px 84px;
    }

    .home-content {
        .container-search-limit-exceeded {
            padding: 0 84px;
            max-width: 1440px;
            margin: -32px auto 8px;
            box-sizing: border-box;
            
            .box-search-limit-exceeded {
                width: 100%;
                display: flex;
                padding: 7px 21px;
                border-radius: 8px;
                align-items: center;
                color: #FFF !important;
                background-color: #000;
                justify-content: space-between;

                .left-side {
                    display: flex;
                    align-items: center;

                    .icon {
                        font-size: 24px;
                        margin-right: 24px;
                    }

                    p {
                        margin: 0;
                        display: flex;
                        font-size: 18px;
                        font-weight: 700;
                        flex-direction: column;

                        span {
                            font-size: 14px;
                            font-weight: normal;
                        }

                        .date-text {
                            padding-top: 4px;
                            font-style: italic;
                        }
                    }
                }

                .ant-btn {
                    color: #000 !important;
                    background-color: #FFF !important;
                }
            }
        }

        .container-recommendations {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
            max-width: 1440px;
            padding: 12px 84px;

            .recommendations {
                width: 100%;
                display: flex;
                overflow: hidden;
                position: relative;
                flex-direction: column;
                justify-content: flex-end;

                .slider {
                    gap: 16px;
                    width: 100%;
                    display: flex;
                    transition: transform 0.5s ease;


                    &.slider-selected-1::before,
                    &.slider-selected-2::before {
                        content: " ";
                        top: 0;
                        width: 72px;
                        height: 72px;
                        position: absolute;
                        transition: all .2s ease-in-out .2s;
                    }

                    &.slider-selected-1::before {
                        left: calc(100% - 72px);
                        background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
                    }

                    &.slider-selected-2::before {
                        left: 75%;
                        background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
                    }

                    .extension-recommendation,
                    .aira-recommendation {
                        height: 72px;
                        flex: 0 0 87%;
                    }
                }

                .dots {
                    display: flex;
                    margin-top: 13px;
                    justify-content: center;

                    span {
                        padding: 8px;
                        position: relative;

                        &.active::before {
                            background-color: #000;
                        }

                        &::before {
                            content: " ";
                            width: 6px;
                            height: 6px;
                            position: absolute;
                            border-radius: 50%;
                            transition: all 0.4s ease;
                            background-color: #D9D9D9;
                        }
                    }
                }
            }
        }

        .home-list-container {
            padding: 31px 84px 48px;

            .home-list {
                margin: 0 auto;
                max-width: 1280px;


                .list-filters {
                    display: flex;
                    align-items: center;
                    margin-bottom: 43px;
                    justify-content: space-between;

                    .filters {
                        display: flex;
                        flex-wrap: wrap;

                        .icon {
                            font-size: 21px;
                            margin-right: 8px;
                            margin-right: 6px;
                        }

                        .tag-filter {
                            font-size: 12px;
                            padding: 6px 10px;
                            margin-right: 6px;
                            line-height: 16px;
                            letter-spacing: 0;
                            border-radius: 3px;
                            color: getColor(text, dark);
                            background-color: #D3D3D3;
                            box-shadow: 0 2px 1px 0 rgba(201, 201, 201, 0.5);

                        }
                    }

                    .card-options {
                        display: flex;

                        button {
                            width: 40px;
                            height: 40px;
                            border: none;
                            display: flex;
                            outline: none;
                            font-size: 20px;
                            cursor: pointer;
                            margin-left: 12px;
                            align-items: center;
                            justify-content: center;
                            background-color: #FFF;
                            transition: all 0.2s ease-in-out;
                            box-shadow: 0 2px 1px 0 rgba(201, 201, 201, 0.5);

                            &.active {
                                color: getColor(primary);

                                &:hover {
                                    color: getColor(primary);
                                }
                            }

                            &:hover {
                                color: getColor(primary, .6);
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-page {
        z-index: 1;
        width: 100%;
        height: 100vh;
        display: flex;
        position: absolute;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.508);

        &.modal-page-collapsed .modal-influencer-card {
            margin-left: -65px;
            width: calc(85% - 65px);
        }

        .modal-influencer-card {
            height: 100%;
            max-width: 1100px;
            margin-left: -160px;
            width: calc(85% - 210px);
            animation: createBox .3s;
            transition: all 0.2s ease-in-out;

            @keyframes createBox {
                from {
                    transform: scale(0);
                }

                to {
                    transform: scale(1);
                }
            }
        }
    }
}