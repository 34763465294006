@import "/src/common/styles/colors.scss";

.tooltip {
    top: 20px;
    cursor: pointer;
    transition: 0.3s all ease-in;

    .tooltip-icon {
        width: 12px;

        &:hover~ {

            .tooltip-text,
            .tooltip-triangle {
                opacity: 1;
                transition: 0.3s all ease-in;
            }
        }
    }
}

.ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
        background-color: getColor(text, dark);
    }
}

.ant-tooltip {
    .ant-tooltip-inner {
        font-size: 10px;
        max-width: 153px;
        line-height: 16px;
        align-self: center;
        letter-spacing: 0.33px;
        padding: 8px 6px !important;
        background-color: getColor(text, dark);
    }
}


.tooltip-influencers-classification {
    .ant-tooltip-arrow-content {
        background: #FFF !important;
        
        &::before {
            border: #FFF !important;
            background: #FFF !important;
        }
    }

    .ant-tooltip-inner {
        min-width: 238px;
        border-radius: 8px;
        color: getColor(text, black);
        background-color: #FFF !important;

        p {
            font-size: 12px;
            font-weight: 600;
            text-align: left;
            margin: 0 auto 12px;
            padding-bottom: 3px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        .junction {
            display: flex;
            font-size: 12px;
            margin-bottom: 8px;
            align-items: center;
            justify-content: space-between;

            &:last-child {
                margin-bottom: 0;

            }

            b {
                white-space: nowrap;
            }

            span {
                width: 100%;
                height: 1px;
                margin: 0 8px;
                position: relative;
                border-top: 1px dashed #4a4a4a85;
                // background-color: getColor(text, black);

                &::before {
                    content: "";
                    right: 0;
                    top: 50%;
                    width: 4px;
                    height: 4px;
                    font-size: 32px;
                    position: absolute;
                    border-radius: 50%;
                    background-color: getColor(text, black);
                    transform: translateY(-50%);
                }
            }
        }

    }
}