@import "/src/common/styles/colors.scss";

.pl-profile {
    display: flex;
    margin-bottom: 82px;
    align-items: flex-start;
    justify-content: flex-start;

    .pl-avatar {
        overflow: hidden;
        border-radius: 50%;
        background-color: #FFFF;
        width: 206px !important;
        height: 206px !important;
        min-width: 206px !important;
        min-height: 206px !important;
        
        div {
            width: 100%;
            height: 100%;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .empty-avatar {
        display: flex;
        border-radius: 50%;
        margin-right: 19px;
        align-items: center;
        justify-content: center;
        width: 206px !important;
        height: 206px !important;
        min-width: 206px !important;
        min-height: 206px !important;
        background-color: #B3B3B3;
        
        img {
            width: 106px;
            margin-top: -7px;
            margin-left: -7px;
        }
    }

    .pl-data {
        margin-left: 38px;
        width: calc(100% - 280px);

        .name-edit-values {
            margin: 0 0;
            width: 100%;
            display: flex;
            letter-spacing: 0;
            flex-direction: column;
            color: getColor(text, dark);

            .name {
                width: 100%;
                overflow: hidden;
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .created-by-aira {
                height: 26px;
                color: #FFF;
                width: 281px;
                display: flex;
                font-size: 14px;
                padding: 0 16px;
                border-radius: 24px;
                align-items: center;
                margin-bottom: 16px;
                background-image: linear-gradient(to right,#F70B53, #0D47A1);

                img {
                    width: 18px;
                    margin-right: 16px;
                }
            }

            .btn-edit {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 16px;
                text-decoration: underline;
                color: getColor(text, dark);
            }

            .data-values {
                gap: 40px;
                display: flex;
                
                .data-value {
                    display: flex;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    color: getColor(text, dark);
        
                    .icon {
                        font-size: 24px;
                        margin-right: 9px;
                    }
        
                    p {
                        margin: 0;
                    }
                }
            }
        }

        .pl-actions {
            display: flex;
            margin-top: 30px;
            align-items: center;

            .button {
                min-width: 224px;
                margin-right: 16px;
            }
        }
    }
}