.influencer_card {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.header__influencer_card {
  padding: 0 !important;
  margin: 0 16px;
  display: flex;
  gap: 16px;

  .avatar_wrapper__influencer_card {
    position: relative;
    display: flex;
    height: 80px;
    width: 80px;

    .avatar__influencer_card {
      height: 76px;
      width: 76px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #dddddd;
      border-radius: 999px;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      color: #333333;
      user-select: none;
      overflow: hidden;

      > img {
        height: 100%;
        width: 100%;
      }
    }

    > img {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 32px;
      width: 32px;
      border-radius: 999px;
      box-shadow: 0 0 0 2px #ffffff;
    }
  }

  .influencer_data__influencer_card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 700;
    color: #1a1517;
    overflow: hidden;

    > strong {
      max-width: 100%;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > small {
      margin-top: 2px;
      font-size: 14px;
      font-weight: 400;
    }

    > p {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 14px;
      display: flex;
      gap: 8px;

      > i {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background: #ed276a;
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
    }
  }

  .my_lists_button__influencer_card {
    padding: 0;
    min-height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    color: #333333;
    cursor: pointer;
    outline: 2px solid transparent;
    transition: 0.2s;

    &:not(:focus-visible):hover {
      border-color: #ed276a;
      background: #fef1f7;
      color: #ed276a;
    }

    &:focus-visible {
      background: #fef1f7;
      outline: solid 2px #ed276a;
    }
  }
}

.social_media_data_wrapper__influencer_card {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  .social_media_data__influencer_card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    color: #1a1517;

    > p {
      margin-bottom: 0;
      align-self: flex-start;
    }

    > div {
      display: flex;
      align-items: baseline;
      gap: 8px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  .topics_wrapper__influencer_card {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    list-style: none;
    grid-column: 1 / -1;

    .topics__influencer_card {
      padding: 2px 12px;
      border-radius: 20px;
      background: #ffe5ed;
      font-size: 14px;
      color: #1a1517;
    }
  }
}

.footer__influencer_card {
  margin: auto 16px 16px;
  display: flex;
  gap: 8px;

  > button {
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #ed276a;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #ed276a;
    cursor: pointer;
    outline: none;
    transition: 0.2s;

    &:hover {
      background: #fef1f7;
    }

    &:focus-visible {
      background: #fef1f7;
      outline: solid 1px #ed276a;
    }

    &:first-of-type {
      border-color: transparent;
    }
  }
}

.search-found {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  font-size: 12px;
  align-items: center;
  border-radius: 0 0 8x 8px;
  border-top: 1px solid #e6e6e6;
  // background: #e6e6e6;
  padding: 8px 16px;

  b {
    padding-right: 4px;
    white-space: nowrap;
  }
}
