.create_campaign_modal {
  width: 33rem !important;
  padding: 0;
  top: 0;

  form {
    > div {
      p {
        margin-bottom: 2px;
        color: #4a4a4a;
      }

      .input-junction {
        margin-top: 2px;
      }
    }

    > div + div {
      margin-top: 16px;
    }
    label {
      font-size: 14px;
      font-weight: 800 !important;
    }

    b {
      font-size: 14px;
      font-weight: 500;
      line-height: normal !important;
    }

    > div > div {
      display: flex;
      gap: 32px;

      div {
        width: 100%;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    margin-top: 32px;

    button:first-of-type {
      background: #ffffff !important;
      border: 1px solid #ed276a;
      color: #ed276a !important;

      &:hover {
        border-color: #ed276a;
      }
    }
  }
}
