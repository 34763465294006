@import '/src/common/styles/colors.scss';

.influencer-row {
  td {
    font-size: 14px;
    line-height: 16px;
    padding: 20px 14px;
    text-align: center;
    font-weight: normal;
    color: getColor(text, dark);
    background-color: getColor(background, lightest);

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .td-influencer {
    padding-right: 0;
    text-align: left;
    position: relative;
    text-transform: capitalize;

    &.rapid-growth {
      .avatar {
        border: 3px solid getColor(primary, dark);
      }

      .icon-rocket {
        top: 52px;
        left: 50px;
        width: 24px;
        height: 24px;
        color: #fff;
        display: flex;
        font-size: 16px;
        position: absolute;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: getColor(primary, dark);
      }
    }

    .avatar {
      margin-right: 24px;
    }

    .name-username {
      margin: 0;
      display: flex;
      max-width: 280px;
      flex-direction: column;

      .name {
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        margin-bottom: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;

        .icon {
          font-size: 16px;
          color: #3897f0;
          margin-left: 8px;
          margin-top: -2px;
        }
      }

      .username {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        align-items: center;

        span {
          text-transform: lowercase;
          text-decoration: underline;
        }

        .icon-external-link {
          padding: 3px;
          color: #000;
          font-size: 16px;
          margin-bottom: -1px;
        }
      }
    }
  }

  .td-network {
    &.color-tiktok div img {
      width: 13px;
    }

    div {
      width: 40px;
      height: 40px;
      padding: 8px;
      display: flex;
      margin: 0 auto;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
    }
  }

  .td-airscore > div {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }

  .td-engagement > div {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }

  .td-themes {
    .div-flex {
      position: relative;
      justify-content: center;

      .circle-theme {
        top: 0;
        z-index: 8;
        opacity: 0.2;
        width: 28px;
        height: 28px;
        padding: 9px;
        border-radius: 50%;
        position: absolute;
      }

      .icon {
        padding: 6px;
        font-size: 16px;
      }
    }
  }

  .td-simulation {
    .icon {
      cursor: pointer;
      padding: 8px 0 8px 8px;
      color: getColor(status, warning);
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .td-interactions {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    .interaction-value {
      width: 100%;
    }
  }

  .td-my-lists {
    .icon-plus {
      padding: 6px;
      font-size: 20px;
      color: #979797;
      border-radius: 3px;
      background-color: #d9d9d9;
    }
  }
}

.with-footer {
  position: relative;

  td {
    padding-bottom: 40px;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:nth-child(7) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .td-footer {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 24px;
    display: flex;
    font-size: 12px;
    position: absolute;
    align-items: center;
    padding: 0 0 0 14px;
    border-radius: 0 0 8px 8px;
    border-top: 1px solid #e6e6e6;

    b {
      padding-right: 4px;
    }
  }
}
