.engagement_rate_table {
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;

  table {
    background: white;
    border-radius: 6px !important;
    margin-bottom: 0 !important;
    border: 0 !important;
  }

  thead {
    tr {
      border-bottom: 1px solid black !important;
    }

    th {
      padding: 4px 0 !important;
      border: 0 !important;

      & + th {
        border-left: 1px solid black !important;
      }
    }
  }

  tbody {
    td {
      padding: 4px 0 !important;
      text-align: center;
      border: 0 !important;
      border-top: 1px solid black !important;

      &:first-of-type {
        width: 7.5rem;
      }

      & + td {
        border-left: 1px solid black !important;
        width: 160px;
      }

      > div {
        padding: 0 12px;
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
}
