@import "/src/common/styles/colors.scss";

.fixed-loading {
    z-index: 9;
    width: 100%;
    display: flex;
    min-height: 100vh;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF;

    span {
        color: #4a4a4a;
        margin-top: 32px;
    }
}

.ct-page-pdf {
    width: 100% !important;

    .ct-content-container {
        margin: 0 auto;
        padding: 42px 84px;
        box-sizing: border-box;

        .btn-back {
            margin: 50px 0 20px;
        }

        .header {
            padding-top: 0;
        }

        .ct-content {
            margin: 0 auto;
            max-width: 1280px;

            .ct-cards {
                display: flex;
                gap: 6px 16px;
                margin-bottom: 30px;

                &.ct-cards-4 {
                    .ct-card {
                        width: 25% !important;
                    }
                }
                
                .ct-card {
                    width: 20%;
                    display: flex;
                    border-radius: 8px;
                    padding: 21px 26px;
                    text-align: center;
                    position: relative;
                    align-items: center;
                    flex-direction: column;
                    color: getColor(text, dark);
                    justify-content: space-between;
                    background-color: getColor(background, lightest);

                    .ct-card-title {
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 8px;
                    }

                    .ct-card-value {
                        cursor: default;
                        font-size: 32px;
                        font-weight: 700;
                    }

                    .tooltip {
                        top: 4px;
                        right: 10px;
                        position: absolute;
                    }
                }
            }

            .title-table {
                display: flex;
                font-size: 24px;
                font-weight: 700;
                margin: 64px 0 0;
                align-items: center;
            }

            .ct-table {
                .select-label {
                    font-size: 16px;
                    margin-bottom: 8px;
                }

                .ant-select {
                    width: 50%;
                    max-width: 476px;
                    margin-bottom: 12px;
                }

                .table-container {
                    .influencer-name {
                        display: flex;
                        flex-direction: column;

                        span {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}