@import '/src/common/styles/colors.scss';

.influencer-card-container {
  transition: all 0.2s ease-in-out;
}

.disabled-profile {
  padding: 16px;
  margin: 16px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  background: #ffdfdf;
  border-radius: 8px;
  align-self: center;

  p {
    margin: 0;
    color: #ed1515;
  }

  button {
    all: unset;
    color: #333333;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
    text-wrap: nowrap;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.influencer-card {
  width: 100%;
  height: 100%;
  position: relative;
  color: getColor(text, black);

  .container-btn-back {
    margin: 0 auto;
    max-width: 1280px;
    padding: 50px 84px 0;
    box-sizing: content-box;

    .btn-back {
      padding: 0;
      min-width: auto;
      box-shadow: none;
    }
  }

  header {
    padding: 50px 84px;
  }

  .influencer-card-content {
    height: 100%;
    margin: 0 auto;
    max-width: 1280px;
    position: relative;
    padding: 20px 84px 84px;
    box-sizing: content-box;

    .arrows {
      top: 50%;
      z-index: 9;
      width: 100%;
      display: flex;
      position: fixed;
      max-width: 1280px;
      justify-content: space-between;

      .arrow-icon {
        width: 46px;
        height: 46px;
        color: #333;
        display: flex;
        font-size: 22px;
        position: relative;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        &.icon-arrow-back {
          transform: translate(-180%);
        }

        &.icon-arrow-next {
          transform: translate(180%);
        }

        &.blocked {
          opacity: 0;
          cursor: default;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    .box-interactions-chart {
      position: relative;

      .popup-interactions-data {
        top: 0;
        right: 0;
        z-index: 35;
        display: flex;
        min-width: 400px;
        max-width: 610px;
        position: absolute;
        border-radius: 8px;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.13);

        .popup-header {
          color: #fff;
          display: flex;
          font-size: 15px;
          font-weight: 600;
          padding: 8px 24px;
          background-color: #000;
          border-radius: 8px 8px 0 0;
          justify-content: space-between;

          .popup-title {
            display: flex;
            align-items: center;

            .count {
              width: 19px;
              height: 19px;
              color: #fff;
              display: flex;
              font-size: 12px;
              font-weight: 600;
              margin-left: 8px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              background-color: getColor(primary);
            }
          }
        }

        .popup-body {
          gap: 12px;
          width: 100%;
          display: flex;
          padding: 24px;
          align-items: flex-start;
          justify-content: space-between;

          .container-loading {
            min-height: auto !important;
            margin: 20px auto 30px !important;
          }

          .part-right {
            table {
              width: 100%;
              min-width: 280px;
              border-spacing: 2px;
              border-collapse: separate;

              thead {
                tr {
                  th {
                    margin: 1px;
                    color: #333;
                    font-size: 14px;
                    font-weight: 700;
                    text-align: left;
                    padding-left: 8px;
                    position: relative;
                    white-space: nowrap;
                    padding: 0 3px 4px 8px;

                    &:first-child {
                      border-top-left-radius: 4px;
                    }

                    &:last-child {
                      border: none;
                      border-top-right-radius: 4px;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    font-size: 12px;
                    border-radius: 0;
                    font-weight: 700;
                    text-align: left;
                    line-height: 16px;
                    font-weight: normal;
                    padding: 6px 8px 5px 8px;
                    text-transform: capitalize;
                    color: getColor(text, dark);

                    .icon {
                      font-size: 14px;
                      margin-left: 7px;
                      margin-right: 4px;
                      color: getColor(primary);
                    }

                    .name {
                      font-weight: 700;

                      &::after {
                        content: ': ';
                      }
                    }

                    &:first-child {
                      text-align: left;
                    }
                  }

                  &:last-child td {
                    &:first-child {
                      border-bottom-left-radius: 4px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 4px;
                    }
                  }
                }
              }
            }
          }

          .part-left {
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-back,
            .icon-advance {
              width: 24px;
              height: 24px;
              padding: 4px;
              display: flex;
              color: #333;
              font-size: 20px;
              margin-top: -30px;
              border-radius: 50%;
              align-items: center;
              margin: -30px 0 0 8px;
              justify-content: center;
              background-color: #d9d9d9;
              transform: translateY(-50%);

              &::before {
                font-size: 14px;
              }

              &.block {
                opacity: 0.4;
                cursor: not-allowed;
              }

              &.hidden {
                opacity: 0;
                cursor: default;
              }
            }

            .icon-back {
              margin: -30px 8px 0 0;
            }

            .img-description {
              .box-img {
                width: 110px;
                margin: 0 auto;
                overflow: hidden;
                min-height: 140px;
                position: relative;

                .icon-air-symbol {
                  top: 50%;
                  left: 50%;
                  padding: 8px;
                  font-size: 75px;
                  position: absolute;
                  color: getColor(primary);
                  transform: translate(-50%, -50%);
                }

                .icon-external-link {
                  top: 6px;
                  right: 6px;
                  z-index: 3;
                  padding: 3px;
                  color: #000;
                  font-size: 13px;
                  position: absolute;
                  border-radius: 50%;
                  margin-bottom: -1px;
                  background-color: #fff;
                }

                img {
                  z-index: 2;
                  width: 110px;
                  height: 137px;
                  border-radius: 8px;
                  position: relative;
                }
              }

              .ant-pagination {
                width: 110px;

                .ant-pagination-jump-prev,
                .ant-pagination-jump-next {
                  margin: 0;
                  height: 22px;
                  min-width: 22px;

                  .anticon {
                    margin-bottom: -10px;
                  }
                }

                .ant-pagination-item a {
                  font-size: 12px;
                  padding: 0 4px 0 0;
                }
              }

              .description {
                height: 30px;
                width: 110px;
                font-size: 9px;
                margin-top: 4px;
                font-weight: 700;
                overflow: hidden;
                text-align: center;
                font-style: italic;
              }
            }

            .ant-pagination {
              justify-content: center;

              .ant-pagination-prev,
              .ant-pagination-next {
                display: none;
              }
            }
          }
        }
      }
    }

    .content {
      z-index: 10;
      position: relative;

      .profile-hide-like {
        height: 32px;
        color: #fff;
        margin: 8px;
        display: flex;
        color: #1a1517;
        font-size: 14px;
        align-items: center;
        border-radius: 24px;
        font-weight: 400 !important;
        padding: 0 16px 0 8px;
        border: 1px solid #1a1517;

        img {
          color: #000;
          margin-right: 12px;
        }
      }
    }

    .icon-certificate {
      cursor: auto;
      font-size: 22px;
      margin-right: 12px;
      color: getColor(status, info);
    }

    .classification {
      height: 26px;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      max-width: 100px;
      padding: 4px 16px;
      text-align: center;
      margin-right: 12px;
      border-radius: 24px;
      text-transform: capitalize;
      background-color: getColor(primary) !important;
    }

    .title-part {
      display: flex;
      font-size: 32px;
      font-weight: 700;
      align-items: center;
      margin-bottom: 24px;
      justify-content: space-between;

      .observation-text {
        font-size: 12px;
        font-weight: 600;
      }
    }

    .subtitle-part {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;

      .icon {
        margin-left: 8px;
        font-weight: normal;
      }
    }

    .ant-select {
      width: 210px !important;

      .ant-select-selector {
        box-shadow: none !important;
        background-color: #fff !important;
        border: 1px solid rgba(51, 51, 51, 0.15) !important;
      }

      .ant-select-selection-placeholder {
        color: #333 !important;
      }
    }

    .text-error {
      display: flex;
      padding: 24px;
      margin: 0 auto;
      font-size: 16px;
      min-height: 200px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background-color: #fff;
    }

    .data-types {
      display: flex;
      position: relative;
      margin-bottom: 38px;

      .data-type-option {
        width: 50%;
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 4px;
        border-bottom: 4px solid #ccc;
        transition: all 0.2s ease-in-out;

        &.unselected {
          cursor: pointer;
          color: #939393 !important;

          &:hover {
            opacity: 0.6;
          }
        }
      }

      .underline {
        left: 0;
        bottom: 0;
        width: 50%;
        height: 4px;
        position: absolute;
        background-color: #333;
        transition: all 0.2s ease-in-out;

        &.publipost {
          left: 50%;
        }
      }
    }

    .chart-interactions-description {
      display: flex;
      justify-content: space-between;

      .total-values {
        width: 49%;
        display: flex;
        padding: 12px;
        font-size: 16px;
        color: #333333;
        margin-top: 16px;
        border-radius: 8px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #fafafa;

        .title-value {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        .values {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;

          .value {
            width: 49%;
            font-size: 14px;
            padding-top: 12px;
            text-align: center;
            white-space: nowrap;

            b {
              font-size: 14px;
            }

            &:nth-child(3) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .modal-mention-posts {
    top: 0;
    opacity: 1;
    z-index: 99;
    display: flex;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: calc(100% - 208px);
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.4);

    &.collapsed {
      width: calc(100% - 56px);
    }

    .mention-posts-card-content {
      width: 80%;
      overflow: auto;
      overflow: hidden;
      max-width: 1226px;
      min-height: 360px;
      padding: 42px 32px;
      border-radius: 8px;
      background-color: #fff;
      transition: 0.2s all ease-in-out;
      animation: openScale 0.4s;
      transition: all 0.4s ease-in;

      .title-part {
        display: flex;
        font-size: 32px;
        font-weight: 700;
      }

      .influencer-posts {
        margin: 0;
      }

      @keyframes openScale {
        0% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }

      .posts-card {
        padding: 36px 0 24px;
      }
    }
  }

  .feedback-error {
    display: flex;
    font-size: 16px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: 80%;
      max-width: 290px;
      margin-bottom: 40px;
    }
  }
}

.tooltip-network-interactions {
  .ant-tooltip-inner {
    min-width: 238px;
    border-radius: 8px;

    p {
      font-size: 12px;
      font-weight: 600;
      text-align: left;
      margin: 0 auto 12px;
      padding-bottom: 3px;
    }

    .junction {
      font-size: 12px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      b {
        white-space: nowrap;
      }

      span {
        width: 100%;
        height: 1px;
        margin: 0 8px;
        position: relative;
      }
    }
  }
}

.ant-popover-inner {
  border-radius: 6px;
  background-color: getColor(text, dark);

  .popup-bio {
    border-radius: 6px;
    background-color: getColor(text, dark);
    padding: 20px;
    max-width: 350px;
    color: getColor(text, white);
  }
}

.highcharts-tooltip-container {
  z-index: 30 !important;
}

.popup-interactions-simple-data {
  padding: 8px;

  .popup-header {
    font-size: 12px;
    font-weight: 700;
    color: getColor(primary);
  }

  .popup-body {
    margin-top: 24px;
    flex-direction: column;
    align-items: flex-start;

    .interaction {
      z-index: 1;
      display: flex;
      min-height: 18px;
      margin-top: -5px;
      position: relative;
      align-items: center;
      background-color: #fff;
      justify-content: flex-start;

      .icon {
        font-size: 20px;
        margin-right: 4px;
        color: getColor(primary);
      }

      .name {
        font-weight: 600;
        padding-right: 2px;
        text-transform: capitalize;
      }
    }

    .button {
      margin: 16px 0 8px;
    }
  }
}
