@import '/src/common/styles/colors.scss';

.profile_modal {
  width: 50rem !important;
  top: 0;
  padding: 0;
  max-height: 100%;

  .ant-modal-content {
    border-radius: 12px !important;
  }

  .modal-title,
  .modal-subtitle {
    display: none;
  }
}

.header__profile_modal {
  display: flex;
  gap: 24px;
  padding: 8px 48px 8px 8px;

  .avatar-network .network-logo {
    height: 32px;
    width: 32px;
    right: 0;
    bottom: 0;
  }
}

.info__header__profile_modal {
  width: 100%;

  > div {

    strong {
      font-size: 20px;
      flex: 1;
    }

    b {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    span {
      display: flex;
      align-items: center;

      svg {
        color: #4a4a4a;
      }
    }
  }

  > ul {
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 16px;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 32px;

      svg {
        min-height: 20px;
        min-width: 20px;
      }

      & + li {
        padding-left: 16px;
        border-left: 1px solid #363636;
      }
    }
  }
}

.tabs__profile_modal {
  margin: 0 5px;
  margin-top: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 16px;
  border-bottom: 4px solid #939393;
  box-sizing: border-box;
  height: 37px;

  button {
    padding: 0px 20px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: none;
    background: transparent;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    // height: 56px;
    transition: 0.2s;
    font-weight: 600;
    color: #939393;

    &:hover {
      opacity: .6;
    }

    &[data-active='true'] {
      border-color: #363636;
      color: #363636;
    }
  }
}

.negotiation__profile_modal {

  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  
  section {
    border-radius: 12px;
    background: #ffffff;

    > b {
      font-size: 16px;
    }

    > div {
      margin-top: 12px;
      display: flex;
      gap: 2rem;

      > div:not(.input_checkbox) {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &:only-of-type {
          max-width: calc((100% / 2) - 16px);
          margin-left: auto;
        }
      }

      > .input_checkbox {
        flex: 1;
        border: 1px solid #d9d9d9;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0 16px;
        height: 40px;
        border-radius: 12px;
      }
    }

    > footer {
      margin-top: 1rem;
      display: flex;
      border-top: 1px solid #dfdfdf;
      padding-top: 16px;

      button {
        margin: 0 auto;
        font-weight: bold;
        height: 36px;
        width: 200px;
        background: transparent;
        cursor: pointer;
        border: none;
        transition: 0.2s ease-in-out;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .spin_button {
    color: #4a4a4a;
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    background: #ffffff;

    input {
      appearance: none;
      all: unset;
    }

    button {
      margin-left: auto;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: #484848;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:not(:disabled):hover {
        color: #323232;
        border-color: #ed276a;
      }

      &:last-of-type {
        margin-left: 12px;
      }

      svg {
        min-width: 16px;
        min-height: 16px;
      }
    }
  }

  .input-junction input {
    background: #ffffff;
    text-align: center;
  }

  > footer {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    gap: 48px;

    button {
      width: 10rem;
    }

    button:first-of-type {
      background: #ffffff !important;
      border: 1px solid #ed276a;
      color: #ed276a !important;

      &:hover {
        border-color: #ed276a;
      }
    }
  }
}

.performance__profile_modal {
  height: 500px;
  overflow-y: scroll;
  scrollbar-width: 0px;

  .insights__profile_modal {
    margin: 0;
    margin-top: 16px;
    padding: 0;
    list-style: none;

    display: flex;
    justify-content: space-between;

    li {
      padding: 16px;
      padding-bottom: 4px;
      width: 176px;
      border-radius: 12px;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;

      b {
        font-size: 20px;
        line-height: 1;
      }

      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

.posts_container__profile_modal {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  b {
    font-size: 18px;
  }

  > div {
    padding: 12px;
    margin-top: 12px;
    border-radius: 12px;
    background: #ededed;
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      justify-content: space-between;
    }

    .ant-select {
      width: 10.5rem;
    }
  }
}

.pagination__posts_container__profile_modal {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    background: #ffffff;
    border: none;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    // height: 32px;
    color: #484848;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      color: #323232;
    }
  }

  li:nth-of-type(2) {
    text-align: center;
    min-width: 50px;
  }

  span {
    font-weight: 600;
  }
}

.posts_list__profile_modal {
  margin: 0;
  margin-top: 16px;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  > li {
    background: rgb(2, 184, 245);
    border-radius: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 12px;
    aspect-ratio: 9 / 16;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 12px;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        gap: 6px;

        span {
          font-size: 8px;
        }
      }
    }
  }
}

.title-part {
  font-size: 16px;
  font-weight: 800;
}

.metrics_container__profile_modal {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding:  10px;
  padding-top: 15px;

  .chart-items {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 16px 24px;
    background-color: #fff;
    justify-content: space-between;

    .icon {
      font-size: 24px;
      margin-right: 8px;
      color: getColor(primary);
    }

    .chart-item {
      width: calc(50% - 24px);

      .value-name {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .junction-value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        align-items: center;

        div {
          display: flex;
          align-items: center;

          .icon {
            font-size: 24px;
            margin-right: 8px;
            color: getColor(primary);
          }

          b {
            font-size: 16px;
          }
        }

        .value {
          font-size: 16px;
        }

        .release_data__junction_value {
          all: unset;
          cursor: pointer;
          text-decoration: underline;
          font-size: 16px;
          color: #1a1517;
          text-underline-offset: 0.25rem;
          transition: 0.2s;

          &:hover {
            color: getColor(primary, base);
          }
        }
      }

      .no-data {
        color: #000000;
      }
    }
  }
}
.posts-container {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  padding-top: 15px;
  background-color: white;
  border-radius: 8px;
}

.posts-scroll {
  width: 100%;
  overflow-x: scroll;
  .posts-scroller {
    display: flex;
    justify-content: space-between;
    width: 900px;
    overflow-x: scroll;
  }
}

.ant-modal-body {
  background-color: #f2f2f2;
}

// notas do influenciador

.posts-container {
  .title {
    font-size: 18px;
    font-weight: bold;
  }
}