@import "/src/common/styles/colors.scss";

.pl-create-edit-modal {
    top: 0;
    opacity: 1;
    z-index: 950;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    overflow: hidden;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);

    .title {
        font-size: 24px;
        font-weight: 700;
    }

    .pl-create-edit-content {
        width: 80%;
        padding: 16px;
        overflow: auto;
        overflow: hidden;
        max-width: 432px;
        min-height: 340px;
        border-radius: 8px;
        background-color: #FFF;
        animation: openScale .4s;
        transition: .2s all ease-in-out;
        transition: all 0.4s ease-in;

        @keyframes openScale {
            0% {
                transform: scale(0);
            }

            100% {
                transform: scale(1);
            }
        }

        .buttons {
            display: flex;
            margin-top: 111px;
            justify-content: space-between;
            .ant-btn {
                width: 49%;
            }
        }

        .ant-form {
            .pl-avatar {
                width: 192px;
                height: 192px;
                position: relative;
                border-radius: 6px;
                background-size: 100% 100%;
                background-repeat: no-repeat;

                .icon {
                    right: 10px;
                    bottom: 10px;
                    padding: 4px;
                    font-size: 30px;
                    border-radius: 6px;
                    position: absolute;
                    background-color: #FFF;
                    color: getColor(primary);
                }
            }

            #avatar {
                display: none;
            }

            .pl-not-avatar {
                width: 192px;
                height: 192px;
                display: flex;
                padding: 0 21px;
                border-radius: 6px;
                text-align: center;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                color: getColor(primary);
                border: 2px dashed getColor(primary, .4);
                .icon {
                    font-size: 36px;
                    margin-bottom: 18px;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .text-error {
                margin: 4px 0 0;
                font-size: 14px;
                color: #ff4d4f;
            }
        }
    }
}