@import "/src/common/styles/colors.scss";

.multi-select-categories {
    .options {
        height: 185px;
        overflow-y: auto;
        margin-top: 16px;
        padding-right: 8px;
        overflow-x: hidden;

        .checkbox-container {
            padding: 8px;
            
            &.category {
                border-radius: 4px;
                background-color: #E6E6E6;
            }   

            .ant-checkbox-wrapper-checked {
                color: getColor(primary);
            } 

            .checkbox-text {    
                font-size: 14px;
            }
        }
    }
}


