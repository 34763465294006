@import "/src/common/styles/colors.scss";

.ant-radio-wrapper-checked {

    .radio-label,
    span.radio-description {
        font-weight: 700;
        color: #000 !important;
    }
}

.ant-radio .ant-radio-inner {
    background-color: transparent !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: getColor(primary) !important;
}

.ant-radio-checked .ant-radio-inner:after {
    background-color: getColor(primary) !important;
}

.ant-radio:hover .ant-radio-inner {
    border-color: getColor(primary) !important;
}

.ant-radio-checked::after {
    border: 1px solid getColor(primary) !important;
}

.ant-radio-checked .ant-radio-inner:focus {
    border-color: getColor(primary) !important;
}


.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: getColor(primary) !important;
    // border-color: #1890ff;
}

.ant-radio-group {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .ant-radio-wrapper {
        height: 40px;
        display: flex;
        margin-right: 0;
        position: relative;
        align-items: center;

        .ant-radio {
            margin-top: -7px;
            margin-right: 4px;
        }


        &.ant-radio-wrapper>span {
            padding: 0;
            display: flex;
            align-items: center;
            transition: all .2s ease-in-out;

            .icon {
                font-size: 18px;
                margin: 0 6px 0 3px;
            }

            .radio-description {
                right: 0;
                color: #979797;
                position: absolute;
                line-height: 14px;
                font-size: 12px !important;
            }
        }


        &:last-child {
            margin-right: 0;
        }
    }
}