@import '/src/common/styles/colors.scss';

.influencer_list_container .container-loading {
  width: 100%;
  padding: 80px 0;
  border-radius: 8px;
  background-color: #fff;
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.influencer_list_container {
  .empty-list {
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 680px;
    align-items: center;
    flex-direction: column;

    img {
      width: 80%;
      margin: 24px 0;
      max-width: 238px;
    }

    .empty-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .empty-description {
      font-size: 16px;
      text-align: center;
      margin-bottom: 12px;
    }

    .empty-warning {
      color: #666;
      font-size: 14px;
      font-weight: 700;
    }

    .buttons {
      display: flex;
      margin-top: 40px;

      .ant-btn {
        margin: 0 12px;
        min-width: 193px;
      }
    }
  }

  .text-error {
    display: flex;
    padding: 24px;
    margin: 0 auto;
    font-size: 16px;
    min-height: 200px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .grid_display {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(274px, min-content);
    gap: 16px;

    @media (width < 1350px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (width < 1024px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.junction-influencer-list {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .title-influencer-list {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
  }

  .junction-select {
    display: flex;
    align-items: center;
    margin-left: auto;

    .text-select {
      font-size: 13px;
      font-weight: 700;
    }

    .ant-select {
      width: 160px;

      .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;

        .ant-select-selection-item {
          font-weight: 700;
        }
      }

      .ant-select-arrow {
        color: #000;
      }
    }
  }
}

.influencer-preview-modal {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  transition: right 0.2s ease-in-out 0.1s;
  background-color: rgba(0, 0, 0, 0.4);

  &.closed {
    .influencer-preview-modal-content {
      animation: moveRight 0.3s;

      @keyframes moveRight {
        from {
          right: 0;
          opacity: 100;
        }

        to {
          opacity: 0;
          right: -50%;
        }
      }
    }
  }

  .influencer-preview-modal-content {
    right: 0;
    top: 50%;
    width: 60%;
    height: 100vh;
    max-width: 460px;
    padding: 16px 24px;
    position: absolute;
    animation: moveLeft 0.3s;
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    transform: translateY(-50%);
    transition: 0.2s all ease-in-out;
    transition: all 0.2s ease-in-out;

    @keyframes moveLeft {
      from {
        opacity: 0;
        right: -50%;
      }

      to {
        right: 0;
        opacity: 100;
      }
    }
  }
}
