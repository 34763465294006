@import '/src/common/styles/colors.scss';

.ant-modal-wrap {
  display: flex;
  align-items: center;
}

.modal-influencer-summary {
  height: 95vh;
  max-height: 900px;
  top: 0 !important;
  overflow: hidden;
  width: 80% !important;
  padding: 0 !important;
  max-width: 1109px !important;

  ::-webkit-scrollbar {
    width: 11px;
    border-radius: 24px;
    background: #d9d9d9;
    border: 4px solid #d9d9d9;
  }

  ::-webkit-scrollbar-track {
    width: 7px;
    border-radius: 24px;
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 24px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    height: 100%;
    overflow: hidden;
    background-color: transparent !important;

    .ant-modal-body {
      height: 100%;
      overflow: hidden;
      padding: 0 !important;

      .influencer {
        display: flex;
        padding: 13px 24px;
        align-items: center;
        border-radius: 8px 8px 0 0;
        background-color: #3c3c3c;

        .avatar-network {
          .ant-avatar {
            margin-right: 16px;
            width: 48px !important;
            height: 48px !important;
          }

          .network-logo {
            right: 12px !important;
            bottom: -2px !important;
            width: 20px !important;
            height: 20px !important;
          }
        }

        .name-username {
          display: flex;
          color: #fff;
          flex-direction: column;

          .name {
            font-size: 16px;
            font-weight: 500;
          }

          .username {
            font-size: 13px;
            text-decoration: underline;
          }
        }
      }

      .text-empty {
        font-size: 14px;
        padding-top: 10px;
        text-align: center;
      }

      .box-summary {
        height: 100%;
        overflow: hidden;
        padding: 16px 24px;
        background-color: #f2f2f2;

        &.extend .posts {
          height: calc(100% - 225px);
        }

        .title {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0;
        }

        .subtitle {
          font-size: 14px;
          margin-bottom: 16px;
        }

        .interaction-values {
          gap: 8px;
          display: flex;

          .interaction {
            width: 100%;
            padding: 18px;
            display: flex;
            border-radius: 8px;
            margin-bottom: 24px;
            flex-direction: column;
            align-items: flex-start;
            background-color: getColor(background, lightest);

            &:last-child {
              border-right: none;
            }

            &.with-icons {
              .icon {
                font-size: 40px;
                color: getColor(primary);
              }

              .interaction-value {
                font-size: 40px;
                cursor: default;
                margin: 20px 0 8px;
              }
            }

            .interaction-value {
              font-size: 20px;
              margin: 0 0 8px;
              font-weight: 700;
            }

            .interaction-name {
              display: flex;
              font-size: 16px;
              max-width: 175px;

              .tooltip {
                margin-left: 8px;
              }
            }

            .interaction-name > div {
              display: flex;
              white-space: nowrap;
            }
          }
        }

        .posts {
          gap: 8px;
          display: flex;
          flex-wrap: wrap;
          overflow-y: auto;
          padding-right: 16px;
          padding-bottom: 16px;
          height: calc(100% - 300px);
          justify-content: flex-start;

          .twitter-post {
            min-width: 240px;
            width: calc(25% - 8px);
            background-color: #e6e6e6;

            .junction-post {
              height: 211px;
            }

            .post-interactions {
              justify-content: space-between;

              .interaction-data {
                margin: 0;

                .icon {
                  font-size: 20px;
                }

                .interaction-value {
                  font-size: 14px;
                }
              }
            }
          }

          .post {
            overflow: hidden;
            padding: 8px;
            height: 240px;
            display: flex;
            min-width: 200px;
            position: relative;
            width: calc(25% - 8px);
            align-items: flex-end;
            background-color: #fff;
            background-size: 100% auto;
            background-position: center;
            background-repeat: no-repeat;

            video {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
              left: 0;
              top: 0;
            }

            .gradient {
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), #000 100%);
            }

            .icon-clip {
              top: 10px;
              z-index: 2;
              right: 10px;
              padding: 2px;
              font-size: 20px;
              position: absolute;
              border-radius: 50%;
              background-color: #fff;
            }

            .post-interactions {
              width: 100%;
              color: #fff;
              display: flex;
              margin-top: 12px;
              position: relative;
              justify-content: space-around;

              .interaction-data {
                margin: 0;
                display: flex;
                align-items: center;
                flex-direction: column;

                .icon {
                  font-size: 20px;
                  margin-bottom: 6px;
                }

                .interaction-value {
                  font-size: 14px;
                }
              }
            }
          }

          .container-loading {
            width: 100%;
          }
        }
      }
    }
  }
}
