@import '/src/common/styles/colors.scss';

.aira-page {
  width: 100%;
  height: 100%;
  background: red;

  .aira-content {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 84px 12px;

    .aira-forms {
      margin: 0 auto;
      max-width: 1280px;

      .ant-form {
        .aira-presentation {
          display: flex;
          color: #4a4a4a;
          border-radius: 8px;
          padding: 32px 24px;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: #fff;

          .aira {
            width: 221px;
          }

          .aira-title {
            width: 100%;
            display: flex;
            font-size: 24px;
            max-width: 656px;
            text-align: left;
            font-weight: 600;
            margin: 40px 0 8px;
          }

          .aira-text {
            margin: 0;
            font-size: 16px;
            max-width: 656px;
            text-align: left;
            font-weight: 400;
          }

          .ant-btn {
            max-width: 656px;
            margin-top: 40px;
          }
        }

        // .curation-form {
        //   gap: 16px;
        //   display: flex;
        //   flex-wrap: wrap;

        //   .box {
        //     width: 65%;
        //     padding: 16px;

        //     &.box-aira-observation {
        //       display: flex;
        //       align-items: flex-start;
        //       transition: height 1.3s;
        //       width: 65%;

        //       .aira {
        //         width: 50px;
        //         height: 50px;
        //         margin-right: 16px;
        //         border: 2px solid white;
        //         border-radius: 50%;
        //       }

        //       .chat-triangle {
        //         width: 16px;
        //         height: 16px;
        //         position: relative;
        //         right: -5px;
        //         top: 0px;
        //       }

        //       .observations {
        //         width: 100%;
        //       }

        //       .observation {
        //         margin: 0;
        //         color: #4a4a4a;
        //         border: 1px solid #e6e6e6;
        //         border-radius: 8px;
        //         background: #fff;
        //         border: 1px solid #ff7095;
        //         padding: 15px;

        //         &:first-child {
        //           border-radius: 0 8px 8px 8px;
        //         }

        //         &:not(:first-child) {
        //           margin-top: 10px;

        //           .chat-name {
        //             display: none;
        //           }
        //         }

        //         &.animate-new-msg {
        //           animation: animate-new-msg 1.8s linear;
        //         }

        //         p {
        //           color: #333;
        //           font-size: 20px;
        //           font-weight: 600;
        //           margin-bottom: 4px;
        //         }

        //         span {
        //           color: #4a4a4a;
        //           font-size: 16px;
        //           font-weight: 400;
        //         }
        //       }

        //       @keyframes animate-new-msg {
        //         0% {
        //           opacity: 0;
        //         }

        //         100% {
        //           opacity: 1;
        //         }
        //       }
        //     }

        //     // -----------------

        //     .title-form {
        //       font-size: 20px;
        //       font-weight: 600;
        //       margin-bottom: 4px;
        //     }

        //     .subtitle-form {
        //       font-size: 14px;
        //       margin-bottom: 17px;
        //     }

        //     .ant-form-item {
        //       margin-bottom: 0;

        //       textarea {
        //         outline: none;
        //         color: #4a4a4a;
        //         font-weight: 400;
        //         min-height: 276px;
        //         padding: 15px 17px;
        //         border-radius: 8px;
        //         box-sizing: border-box;
        //         font-size: 13px !important;
        //         box-shadow: none !important;
        //         border: 1px solid #d9d9d9 !important;

        //         &::placeholder {
        //           color: #767676 !important;
        //         }
        //       }
        //     }

        //     .briefing-count {
        //       font-size: 14px;
        //       color: #ff4d4f;
        //       transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        //     }

        //     .ant-btn {
        //       margin-top: 12px;
        //     }
        //   }

        //   .curation-text-examples {
        //     gap: 16px;
        //     display: flex;
        //     flex-direction: column;
        //     width: calc(25% - 16px);

        //     p {
        //       margin: 0;
        //       font-size: 16px;
        //       color: #ed276a;
        //       font-weight: 600;
        //     }

        //     .examples {
        //       gap: 16px;
        //       display: flex;
        //       flex-direction: column;

        //       .example {
        //         display: flex;
        //         flex-direction: column;
        //         align-items: flex-start;

        //         .icon {
        //           font-size: 24px;
        //           margin-bottom: 4px;
        //           color: getColor(primary);
        //         }
        //       }
        //     }
        //   }
        // }

        .curation-loading {
          top: 0;
          left: 0;
          z-index: 9;
          width: 100%;
          height: 100vh;
          display: flex;
          position: fixed;
          text-align: center;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: #f2f2f2;

          img {
            width: 234px;
          }

          .aira-title {
            font-size: 32px;
            font-weight: 700;
            margin: 44px 0 18px;
          }

          .aira-text {
            font-size: 16px;
            max-width: 464px;
            color: #4a4a4a;
            text-align: center;
          }

          .ant-progress {
            display: flex;
            max-width: 464px;
            align-items: center;
            flex-direction: column;

            .ant-progress-outer .ant-progress-inner {
              height: 22px;
              border: 1px solid #000;
              background-color: #fff9fb !important;

              .ant-progress-bg {
                height: 22px !important;
                background-color: getColor(primary) !important;
              }
            }

            .ant-progress-text {
              margin: 8px 0 0;
              font-size: 16px;
              font-weight: 700;

              .anticon {
                display: none;
              }
            }
          }

          .full-progress {
            font-size: 16px;
            font-weight: 700;
          }
        }

        .curation-concluded {
          display: flex;
          position: relative;

          .aira {
            width: 120px;
            height: 120px;
            margin-right: 24px;
          }

          .box-concluded > span {
            width: 100%;
            display: flex;
            font-size: 20px;
            font-weight: 600;
            text-align: left;
          }

          .box-concluded {
            display: flex;
            padding: 18px 16px;
            border-radius: 8px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 144px);
            border: 1px solid #1c8c3b;
            background: rgba(28, 140, 59, 0.05);

            .aira-title {
              font-size: 20px;
              margin: 24px 0 0;
              font-weight: 700;
              color: #1c8c3b;
              text-align: center;
            }

            .finished-illustration {
              width: 208px;
              margin: 16px 0;
            }

            .aira-subtitle {
              margin: 0;
              color: #1a1517;
              font-size: 16px;
              font-weight: 400;
              text-align: center;
            }

            .ant-btn {
              height: 40px;
              margin-top: 40px;
            }
          }
        }

        .ant-btn {
          width: 100%;
          height: auto;
          margin: 0 auto;
          padding: 10px;
          font-size: 18px;
          box-shadow: 0 2px 1px 0 rgb(201 201 201 / 50%) !important;
        }
      }
    }

    .ant-input {
      background-color: #fff !important;
    }
  }
}

.aira-loading {
  position: fixed;
  inset: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 11rem;
      height: 11rem;
    }

    svg {
      position: absolute;
      min-width: 15.125rem;
      min-height: 15.125rem;
      animation: rotate 2s linear infinite;
      transform-origin: center center;
    }

    .loader-path {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
  }

  h1 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #1a1517;
  }

  p {
    margin-top: 2rem;
    width: 28rem;
    font-size: 16px;
    text-align: center;
    color: getColor(text, dark);
  }
}
