@import '/src/common/styles/colors.scss';

.add-influencer-page {
    .header {
        padding-bottom: 16px;
    }

    .add-influencer-content {
        margin: 0 auto;
        max-width: 1280px;
        padding: 0 84px 48px;
        box-sizing: content-box;

        .add-influencer-container {
            max-width: 647px;
            border-radius: 8px;
            padding: 24px 0 41px;
            background-color: #fff;

            h2 {
                font-size: 20px;
                color: #4a4a4a;
                font-weight: 700;
                margin-bottom: 4px;
            }

            .filter-types {
                display: flex;
                padding: 0 23px;
                position: relative;
                margin-bottom: 32px;
                border-bottom: 2px solid #e6e6e6;

                .filter-type-option {
                    width: 50%;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                    padding-bottom: 12px;
                    transition: all 0.3s ease-in-out 0s;

                    &.unselected {
                        cursor: pointer;
                        font-weight: normal;
                        color: #939393 !important;

                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }

                .underline {
                    left: 0;
                    height: 2px;
                    bottom: -2px;
                    margin: 0 0 0 23px;
                    position: absolute;
                    width: calc(50% - 23px);
                    background-color: #000;
                    transition: all 0.3s ease-in-out 0s;

                    &.multiple {
                        left: 50%;
                        margin: 0 23px 0 0;
                    }
                }
            }

            .form-add-influencer {
                padding: 0 24px;

                .ant-form {
                    margin-top: 24px;

                    &.multiple-influencers {
                        .pattern-purple {
                            width: 150px;
                        }

                        .placeholder-file-name {
                            width: calc(100% - 190px);
                        }
                    }

                    .ant-form-item {
                        margin-bottom: 16px;

                        .ant-form-item-label label {
                            font-size: 15px;
                        }

                        .ant-select-selector,
                        .input-junction input {
                            border-radius: 6px !important;
                            background-color: #fff !important;
                        }

                        .ant-form-item-explain-error {
                            margin-bottom: 16px;
                        }
                    }

                    .input-file {
                        width: 100%;
                        display: none;
                    }

                    .box-file-name {
                        width: 100%;
                        height: 154px;
                        display: flex;
                        font-size: 14px;
                        padding: 0 11px;
                        border-radius: 13px;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        border: 1px dashed #545454;
                        transition: all 0.3s ease-in-out;

                        &.hover-file {
                            opacity: 0.4;
                            border: 1px dashed #cecece;
                        }

                        .icon-file {
                            font-size: 56px;
                            color: #989898;
                            margin-bottom: 8px;
                        }

                        .input-file-title {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 4px;
                        }

                        .input-file-text {
                            font-size: 13px;

                            span {
                                padding-left: 6px;
                                color: getColor(primary);
                                text-decoration: underline;
                            }
                        }
                    }

                    .text-download-model {
                        font-size: 13px;
                        margin-top: -10px;
                        margin-bottom: 16px;

                        span {
                            text-decoration: underline;
                        }
                    }

                    .form-items .ant-form-item-control-input-content {
                        position: relative;

                        .icon-close {
                            top: 18px;
                            right: 12px;
                            display: flex;
                            font-size: 16px;
                            margin-right: 5px;
                            position: absolute;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .ant-btn {
                        width: 100%;
                        height: auto;
                        margin: 0 auto;
                        padding: 10px;
                        font-size: 20px;
                        box-shadow: 0 2px 1px 0 rgb(201 201 201 / 50%) !important;
                    }
                }

                .description {
                    width: 100%;
                    color: #4a4a4a;
                    padding: 8px 0 24px;

                    p {
                        font-size: 13px;
                        margin-bottom: 4px;
                    }

                    p b {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
