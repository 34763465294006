@import "/src/common/styles/colors.scss";

.top-influencers-cards {
    gap: 16px;
    padding: 24px;
    display: flex;
    margin: 0 auto;
    cursor: default;
    flex-wrap: wrap;
    max-width: 1280px;
    border-radius: 8px;
    justify-content: center;
    background-color: #FFF;
    justify-content: flex-start;

    .top-influencer-card {
        border-radius: 6px;
        width: calc(25% - 12px);

        .top-influencer-card-header {
            color: #fff;
            display: flex;
            padding: 8px;
            font-size: 16px;
            font-weight: 600;
            align-items: center;
            background-color: #333;
            border-radius: 6px 6px 0 0;

            .icon {
                padding: 4px;
                font-size: 15px;
                margin-right: 8px;
                border-radius: 50%;
                background-color: #FFF;
                color: var(--influencers-category-color);

                &::before {
                    bottom: -1px;
                    position: relative;
                }
            }
        }

        .top-influencer-card-body {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .row-values {
                width: 100%;
                display: flex;
                padding: 16px;
                align-items: center;
                justify-content: space-between;

                &.even {
                    background-color: #E6E6E6;
                }

                &:first-child {
                    .user {
                        font-weight: 600;
                    }
                    
                    .influencer-score .score {
                        font-weight: 600;
                    }
                }

                .user {
                    width: 80%;
                    display: flex;
                    overflow: hidden;
                    white-space: nowrap;
                    align-items: center;
                    text-overflow: ellipsis;
                    justify-content: flex-start;

                    .position {
                        font-size: 14px;
                        margin-right: 8px;
                    }

                    .network-logo {
                        width: 18px;
                        bottom: 0px;
                        margin: 0 4px;
                    }

                    .name {
                        width: 60%;
                        font-size: 14px;
                        overflow: hidden;
                        margin-left: 8px;
                        text-overflow: ellipsis;
                    }

                    .ant-avatar {
                        margin-right: 4px;
                        width: 40px !important;
                        height: 40px !important;
                    }

                    img.twitter {
                        width: 18px;
                        height: 18px;
                        margin: 0 6px !important;
                    }
                }

                .influencer-score {
                    display: flex;
                    align-items: center;

                    .icon-airscore {
                        margin-right: 4px;
                        color: getColor(primary);
                    }
                }

                &:last-child {
                    border-radius: 0 0 6px 6px;
                }
            }
        }
    }
}