@import "/src/common/styles/colors.scss";

.pl-page {
    width: 100%;
    height: 100%;

    header {
        padding: 50px 84px;
    }

    .pl-content {
        margin: 0 auto;

        .pl-list {
            margin: 0 auto;
            max-width: 1280px;
            position: relative;
            padding: 0 84px 194px;
            box-sizing: content-box;

            .junction-select {
                display: flex;
                align-items: center;

                .text-select {
                    font-size: 13px;
                    line-height: 41px;
                    font-weight: 700;
                }

                .ant-select {
                    width: 248px;

                    .ant-select-selector {
                        border: none !important;
                        box-shadow: none !important;
                        background-color: transparent !important;

                        .ant-select-selection-item {
                            font-weight: 700;
                        }
                    }

                    .ant-select-arrow {
                        color: #000;
                    }
                }
            }

            .pl-actions {
                display: flex;
                flex-wrap: wrap;
                max-width: 1280px;
                margin: 0 auto 32px;
                padding-bottom: 24px;
                align-items: flex-start;
                justify-content: space-between;
                border-bottom: 1px solid #D8D8D8;

                .input-junction {
                    width: 100%;
                    margin-bottom: 12px;

                    .input {
                        height: 54px;
                        padding: 0 27px;
                        background-color: #FFF;

                        .ant-input-prefix {
                            font-size: 22px;
                            margin-right: 17px;
                        }
                    }
                }

                .aira-recommendation {
                    margin-bottom: 12px;
                }

                .button {
                    min-width: 128px !important;

                    &.btn-search {
                        width: 193px;
                        flex-direction: row-reverse;
                        
                        .icon {
                            margin: 0 0 0 6px;
                        }
                    }

                    .icon {
                        margin: 0 6px 0 0;
                        font-size: 16px !important;
                    }
                }

            }

            .card {
                margin-bottom: 24px;
            }

            .text-empty {
                font-size: 36px;
                margin-top: 150px;
                text-align: center;
            }
            
            .table-container {
                padding-bottom: 0 !important;
            }
        }
    }
}

.no-planner-lists {
    display: flex;
    flex-flow: wrap;
    padding-top: 52px;
    justify-content: center;
}

.no-planner-lists span {
    margin-top: 16px;
    color: getColor(text, medium);
}

.icon-unshare {
    font-size: 12px !important;
}