@import "/src/common/styles/colors.scss";

.pl-interactions {
    margin-bottom: 82px;

    .interaction-values {
        display: flex;
        gap: 24px 40px;

        .interaction {
            width: 100%;
            padding: 18px;
            display: flex;
            border-radius: 8px;
            margin-bottom: 24px;
            flex-direction: column;
            align-items: flex-start;
            background-color: getColor(background, lightest);

            &:last-child {
                border-right: none;
            }

            &.with-icons {
                .icon {
                    font-size: 40px;
                    color: getColor(primary);
                }

                .interaction-value {
                    font-size: 40px;
                    cursor: default;
                    margin: 20px 0 8px;
                }
            }

            .interaction-value {
                font-size: 20px;
                margin: 0 0 8px;
                font-weight: 700;
            }

            .interaction-name {
                display: flex;
                font-size: 16px;

                .tooltip {
                    margin-left: 8px;
                }
            }
        }
    }

    .estimated-costs-interaction {
        width: 100%;
        margin-top: 22px;

        .simulation {
            display: flex;
            padding: 24px;
            border-radius: 6px;
            justify-content: space-between;
            background-color: getColor(background, lightest);

            .input-btn {
                width: 50%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;

                .input-junction {
                    max-width: 275px;
                    margin-right: 16px;
                    flex-direction: column;
                    width: calc(100% - 40px);
                    
                    .ant-input-number {
                        margin: 0;
                        width: 100%;
                        
                        input {
                            box-shadow: none;
                            border: 1px solid #D9D9D9 !important;
                            background-color: #FAFAFA !important;
                        }
                    }
                }

                .ant-btn {
                    min-width: 60px;
                    margin-left: -8px;
                    background-color: #F70B53 !important;
                }

                .icon {
                    padding: 0;
                    font-size: 28px;
                    margin-bottom: -2px;
                }
            }

            .input-junction {
                width: 226px;
                margin-right: 24px;
                flex-direction: column;

                input {
                    box-shadow: none;
                    border-radius: 8px;
                    border: 1px solid #D9D9D9 !important;
                    background-color: #E6E6E6 !important;
                }
            }
        }
    }
}

.ant-popover-inner-content {
    padding: 0;

    .modal-simulator {
        width: 250px;
        max-height: 0;
        overflow: hidden;
        border-radius: 6px;
        transition: .2s ease-in all;
        transition: max-height 0.3s ease-out;
        background-color: getColor(background, lightest);
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.13);

        &.open-modal-simulator {
            height: auto;
            max-height: 500px;
            transition: max-height 0.3s ease-out;
        }

        .modal-simulator-header {
            padding: 10px;
            text-align: center;
            position: relative;
            border-radius: 6px 4px 0 0;
            color: getColor(text, white);
            background-color: getColor(text, dark);

            .modal-simulator-header-title {
                font-size: 18px;
                margin-right: 10px;
                text-transform: uppercase;
                display: flex;
            }

            .icon {
                top: 50%;
                right: 10px;
                position: absolute;
                transform: translateY(-62%);
            }
        }

        .modal-simulator-content {
            padding: 10px 10px 16px;

            .input-junction {
                .ant-input::placeholder {
                    font-size: 15px;
                }
            }

            .modal-simulator-junction {
                margin-top: 24px;
                overflow-y: auto;
                max-height: 200px;

                .ant-btn {
                    width: 100%;
                }
            }
        }
    }
}