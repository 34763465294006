@import "/src/common/styles/colors.scss";

.influencer-public-card {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 16px !important;
    color: getColor(text, black);

    header {
        padding: 50px 16px;
    }

    .influencer-card-content {
        height: 100%;
        margin: 0 auto;
        max-width: 1440px;
        position: relative;
        padding: 20px 0 84px;
        box-sizing: content-box;

        .influencer-profile {
            padding: 0;
            margin-bottom: 26px;

            .avatar-network {
                .ant-avatar {
                    width: 67px !important;
                    height: 67px !important;
                }

                .network-logo {
                    width: 30px !important;
                }
            }

            .influencer-data {
                margin-left: 16px;

                .name {
                    font-size: 16px;
                    line-height: normal;
                }

                .bio {
                    margin: 8px 0;
                    max-height: none;
                }
            }

            .icon-certificate {
                cursor: auto;
                font-size: 22px;
                margin-right: 12px;
                color: getColor(status, info);
            }

            .classification {
                height: 26px;
                color: #FFF;
                font-size: 12px;
                font-weight: 700;
                max-width: 100px;
                padding: 4px 16px;
                text-align: center;
                margin-right: 12px;
                border-radius: 24px;
                text-transform: capitalize;
                background-color: getColor(primary) !important;

                .name {
                    line-height: 18px;
                }
            }
        }

        .title-part {
            display: flex;
            font-size: 32px;
            font-weight: 700;
            align-items: center;
            margin-bottom: 24px;
            justify-content: space-between;

            .observation-text {
                font-size: 12px;
                font-weight: 600;
            }
        }

        .subtitle-part {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;

            .icon {
                margin-left: 8px;
                font-weight: normal;
            }
        }

        // mentions
        .influencer-interactions {
            margin-bottom: 16px;

            .interaction-values {
                display: flex;
                flex-wrap: wrap;
                grid-gap: 12px 8px;
                margin-bottom: 12px;
                justify-content: space-between;

                .interaction {
                    width: 48%;
                    margin-bottom: 0;
                    padding: 14px 12px;

                    .tooltip {
                        top: 12px;
                        right: 12px;
                        position: absolute;
                    }

                    .icon {
                        font-size: 30px;
                    }

                    .interaction-value {
                        font-size: 14px;

                    }

                    .interaction-name {
                        font-size: 12px;
                    }

                    .interaction-engagement {
                        display: flex;
                        flex-direction: column;

                        .engagement-rating {
                            padding: 8px 0 0;

                            .classification-text {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .influencer-public-card {
        padding: 0 32px !important;

        header {
            padding: 50px 32px;
        }

        .influencer-card-content {
            padding: 40px 0 84px;

            .influencer-profile {
                margin-bottom: 32px;

                .avatar-network {
                    .ant-avatar {
                        width: 117px !important;
                        height: 117px !important;
                    }

                    .network-logo {
                        width: 40px !important;
                    }
                }

                .influencer-data {
                    margin-left: 24px;

                    .name {
                        font-size: 20px;
                    }

                    .bio {
                        margin: 8px 0;
                        max-height: none;
                    }
                }
            }

            .title-part {
                font-size: 20px;
                margin-bottom: 12px;

                .observation-text {
                    font-size: 12px;
                    font-weight: 600;
                }
            }

            .subtitle-part {
                font-size: 18px;
                margin-bottom: 0;
            }

            // mentions
            .influencer-interactions {
                margin-bottom: 16px;

                .interaction-values {
                    grid-gap: 12px 8px;
                    margin-bottom: 12px;

                    .interaction {
                        margin-bottom: 0;
                        padding: 14px 12px;

                        .tooltip {
                            top: 12px;
                            right: 12px;
                            position: absolute;
                        }

                        .icon {
                            font-size: 30px;
                        }

                        .interaction-value {
                            font-size: 14px;

                        }

                        .interaction-name {
                            font-size: 12px;
                        }

                        .interaction-engagement {
                            display: flex;
                            flex-direction: column;

                            .engagement-rating {
                                padding: 8px 0 0;

                                .classification-text {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    &.interaction-values-3 {
                        .interaction {
                            width: calc(33% - 8px);
                        }
                    }

                    &.interaction-values-4 {
                        .interaction {
                            width: calc(25% - 8px);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .influencer-public-card {
        box-sizing: content-box;
        padding: 0 38px !important;

        header {
            padding: 50px 38px;
        }

        .influencer-card-content {
            padding: 40px 0 84px;

            .influencer-profile {
                margin-bottom: 38px;

                .avatar-network {
                    .ant-avatar {
                        width: 227px !important;
                        height: 227px !important;
                    }

                    .network-logo {
                        width: 80px !important;
                    }
                }

                .influencer-data {
                    margin-left: 24px;

                    .name {
                        font-size: 39px;
                    }

                    .bio {
                        margin: 12px 0;
                        max-height: none;
                    }
                }
            }

            .title-part {
                font-size: 30px;
                margin-bottom: 12px;

                .observation-text {
                    font-size: 12px;
                }
            }

            .subtitle-part {
                font-size: 20px;
                margin-bottom: 0;
            }

            // mentions
            .influencer-interactions {
                margin-bottom: 16px;

                .interaction-values {
                    grid-gap: 12px 8px;
                    margin-bottom: 12px;

                    .interaction {
                        margin-bottom: 0;
                        padding: 14px 12px;

                        .tooltip {
                            top: 12px;
                            right: 12px;
                            position: absolute;
                        }

                        .icon {
                            font-size: 40px;
                        }

                        .interaction-value {
                            font-size: 30px;

                        }

                        .interaction-name {
                            font-size: 14px;
                        }

                        .interaction-engagement {
                            flex-direction: row;

                            .engagement-rating {
                                padding: 0 0 0 8px;

                                .classification-text {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}