@import '/src/common/styles/colors.scss';

.pl-table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container-loading {
    width: 100%;
    padding: 80px 0;
    border-radius: 8px;
    background-color: #fff;
    margin: 0 auto !important;
  }

  .junction-pl-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected-items {
      display: flex;
      align-items: center;

      span {
        color: #666;
        font-size: 14px;
      }

      .icon {
        font-size: 20px;
        margin-left: 18px;
        padding: 4px 0 4px 16px;
        border-left: 1px solid #ccc;
      }
    }

    .ant-select {
      width: 240px;

      .ant-select-selector {
        box-shadow: none !important;
      }

      .ant-select-arrow {
        color: #000;
      }
    }
  }

  .table-container {
    overflow-x: auto;
    margin-bottom: 16px;

    table {
      table-layout: fixed;
      width: 100%;
      border-spacing: 0 16px;
      border-collapse: separate;

      thead {
        tr {
          border-radius: 8px;
          padding-bottom: 16px;

          th {
            margin: 1px;
            padding: 16px;
            font-size: 14px;
            font-weight: 700;
            text-align: left;
            white-space: nowrap;
            position: relative;
            background-color: #e6e6e6;
            color: getColor(text, dark);

            &:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }

            .icon-down {
              margin-top: -2px;
              margin-left: 8px;
              transition: all 0.2s ease-in-out;

              &.smaller {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 16px;
            font-size: 14px;
            text-align: left;
            line-height: 16px;
            font-weight: normal;
            color: getColor(text, dark);
            background-color: getColor(background, lightest);

            &:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }

          .td-network {
            .twitter img {
              width: 14px;
            }

            .tiktok img {
              width: 14px;
            }

            div {
              background-color: #fff;
            }

            .div-flex {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: flex-start;
            }

            .box-nw {
              width: 40px;
              height: 40px;
              border-radius: 6px;
              background: #fff;
            }
          }

          .td-name {
            .div-flex {
              justify-content: flex-start;

              &.name-space {
                .pl-avatar {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  margin-right: 19px;
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                }

                .empty-avatar {
                  width: 40px;
                  height: 40px;
                  display: flex;
                  border-radius: 50%;
                  margin-right: 19px;
                  align-items: center;
                  justify-content: center;
                  background-color: #b3b3b3;

                  img {
                    width: 27px;
                    margin-top: -2px;
                    margin-left: -4px;
                  }
                }

                .influencer-name {
                  font-size: 14px;
                  font-weight: 700;
                  color: #484848;
                  max-width: 300px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: calc(100% - 100px);
                }
              }
            }
          }

          .td-aira img {
            width: 37px;
          }

          .td-options {
            position: relative;
            .icon-more {
              width: 34px;
              height: 34px;
              padding: 8px;
              margin: 0 auto;
              cursor: pointer;
              border-radius: 50%;
              transform: rotate(90deg);
              transition: 0.3s ease all;
              background-color: #e6e6e6;

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }

  .div-flex {
    display: flex;
    align-items: center;
  }
}

.ant-dropdown-menu {
  min-width: 244px !important;
}
