@import '/src/common/styles/colors.scss';

.aira-curation-page {
    .logo {
        height: 20px;
    }

    h1 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: getColor(text, dark);
    }

    .box {
        padding: 12px;
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        background-color: #fff;
        border: 1px solid #cbc4c7;

        &.box-aira-observation {
            margin: 4px 0 22px;
            flex-direction: row;

            .aira {
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }

            .observations {
                .observation {
                    margin-bottom: 0;

                    p {
                        color: #333;
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 0;
                    }

                    span {
                        color: #4a4a4a;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                @keyframes animate-new-msg {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }
        }

        .title-form {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;
        }

        .subtitle-form {
            font-size: 12px;
            margin-bottom: 17px;
        }

        .ant-form-item {
            margin-bottom: 0;

            textarea {
                outline: none;
                color: #4a4a4a;
                font-weight: 400;
                min-height: 226px;
                padding: 15px 17px;
                border-radius: 8px;
                box-sizing: border-box;
                font-size: 13px !important;
                box-shadow: none !important;
                border: 1px solid #d9d9d9 !important;

                &::placeholder {
                    color: #767676 !important;
                }
            }
        }

        .briefing-count {
            font-size: 14px;
            color: #ff4d4f;
            transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .ant-btn {
            margin-top: 12px;
        }
    }

    .part-form {
        display: flex;
        flex-direction: column-reverse;

        .curation-text-examples {
            margin-bottom: 22px;

            p {
                margin: 0 0 4px;
                font-size: 14px;
                font-weight: 600;
            }

            .examples {
                gap: 8px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .example {
                    width: 100%;
                    padding: 8px;
                    display: flex;
                    font-size: 12px;
                    border-radius: 8px;
                    align-items: center;
                    background-color: #fff;
                    border: 1px solid #cbc4c7;

                    .icon {
                        font-size: 18px;
                        margin-right: 8px;
                        color: getColor(primary);
                    }
                }
            }
        }
    }

    .ant-btn {
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 10px;
        font-size: 18px;
        box-shadow: 0 2px 1px 0 rgb(201 201 201 / 50%) !important;
    }

    .ant-input {
        background-color: #fff !important;
    }
}

@media only screen and (min-width: 768px) {
    .aira-curation-page {
        .logo {
            height: 32px;
        }

        h1 {
            font-size: 22px;
            margin: 0;
        }

        .box {
            padding: 14px;

            &.box-aira-observation {
                margin: 4px 0 28px;

                .aira {
                    width: 60px;
                    height: 60px;
                }

                .observations {
                    display: flex;
                    align-items: center;

                    .observation {
                        p {
                            font-size: 18px;
                        }

                        span {
                            font-size: 13px;
                        }
                    }
                }
            }

            // -----------------

            .title-form {
                font-size: 22px;
                margin-bottom: 4px;
            }

            .subtitle-form {
                font-size: 14px;
                margin-bottom: 17px;
            }

            .briefing-count {
                font-size: 14px;
            }

            .ant-form-item {
                textarea {
                    min-height: 256px;
                }
            }

            .ant-btn {
                margin-top: 16px;
            }
        }

        .part-form {
            .curation-text-examples {
                margin-bottom: 28px;

                p {
                    margin: 0 0 4px;
                    font-size: 15px;
                    font-weight: 600;
                }

                .examples {
                    gap: 6px;

                    .example {
                        padding: 12px;
                        font-size: 14px;
                        border-radius: 8px;

                        .icon {
                            font-size: 20px;
                            margin-right: 14px;
                            color: getColor(primary);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .aira-curation-page {
        align-items: flex-start;

        .logo {
            height: 30px;
        }

        h1 {
            font-size: 24px;
            margin: 0;
        }

        .box {
            width: 100%;
            padding: 16px;

            &.box-aira-observation {
                margin: 24px 0;

                .aira {
                    width: 50px;
                    height: 50px;
                    margin-right: 16px;
                }

                .observations {
                    display: flex;
                    align-items: center;

                    .observation {
                        p {
                            font-size: 20px;
                            margin-bottom: 0;
                        }

                        span {
                            font-size: 16px;
                        }
                    }
                }
            }

            // -----------------

            .title-form {
                font-size: 20px;
                margin-bottom: 4px;
            }

            .subtitle-form {
                font-size: 14px;
                margin-bottom: 17px;
            }

            .briefing-count {
                font-size: 14px;
            }

            .ant-form-item {
                textarea {
                    min-height: 297px;
                }
            }

            .form-actions {
                gap: 16px;
                display: flex;
                flex-direction: row-reverse;

                .ant-btn {
                    margin-top: 16px;
                }
            }
        }

        .part-form {
            flex-direction: row;
            justify-content: space-between;

            .box {
                padding: 20px;
                width: calc(70% - 8px);
            }

            .curation-text-examples {
                padding: 20px;
                display: flex;
                margin-bottom: 0;
                border-radius: 8px;
                width: calc(30% - 8px);
                flex-direction: column;
                background-color: #fff;
                border: 1px solid #cbc4c7;

                p {
                    margin: 0 0 16px;
                    font-size: 16px;
                    color: getColor(primary);
                }

                .examples {
                    gap: 16px;

                    .example {
                        padding: 0;
                        padding: 0;
                        border: none;
                        font-size: 13px;
                        border-radius: 0;
                        flex-direction: column;
                        align-items: flex-start;
                        background: transparent;

                        .icon {
                            font-size: 24px;
                            margin-right: 0;
                            margin-bottom: 6px;
                        }
                    }
                }
            }
        }
    }
}
