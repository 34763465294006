@import "/src/common/styles/colors.scss";
@import "~antd/dist/antd.css";

.button {
    height: 40px;
    border: none;
    display: flex;
    font-size: 13px;
    font-weight: bold;
    min-width: 110px;
    line-height: 24px;
    text-align: center;
    border-radius: 12px;
    align-items: center;
    justify-content:center;
    letter-spacing: 0.15px;
    color: #fff !important;
    box-shadow: none !important;
    transition: 0.2s ease-in all;
    background-color: getColor(primary) !important;
    
    .tiktok-img {
        width: 17px;
        margin: 0 9px 0 0;
    }

    .icon, .anticon-loading {
        color: getColor(text, white) !important;
    }

    .icon {
        font-size: 14px;
        margin: 0 6px 0 0;
    }

    &:hover {
        opacity: 0.6;
        
        &:disabled {
            opacity: 1;
        }
    }
    &:before {
        background-color: transparent !important;
    }

    &:disabled {
        background-color: #eaeaea !important;
        
        &:hover {
            background-color: #eaeaea !important;
        }
    }
}

.pattern-purple, .pattern-purple:focus, .pattern-purple:hover {
    color: getColor(text, white) !important;
    background-color: getColor(secondary) !important;
}

.pattern-dark, .pattern-dark:focus, .pattern-dark:hover {
    color: getColor(text, white) !important;
    background-color: #333 !important;
}

.pattern-light, .pattern-light:focus, .pattern-light:hover {
    color: getColor(text, dark) !important;
    background-color: getColor(background, lightest) !important;
}

.pattern-error, .pattern-error:focus, .pattern-error:hover {
    background-color: getColor(status, error) !important;
}

.pattern-success, .pattern-success:focus, .pattern-success:hover {
    background-color: getColor(status, success) !important;
}

.pattern-warning, .pattern-warning:focus, .pattern-warning:hover{
    background-color: getColor(status, warning) !important;
}

.pattern-info, .pattern-info:focus, .pattern-info:hover{
    background-color: getColor(status, info) !important;
}

.pattern-grey, .pattern-grey:focus, .pattern-grey:hover{
    background-color: #FAFAFA !important;
    color: getColor(text, black) !important;
}

.pattern-newlist-enabled, .pattern-newlist-enabled:focus, .pattern-newlist-enabled:hover {
    background-color: getColor(buttonNewList, enabled) !important;
}

.pattern-newlist-enabled:disabled, .pattern-newlist-enabled[disabled]:hover {
    background-color:  getColor(buttonNewList, disabled) !important;
    color: white;
}

.pattern-see-bio, .pattern-see-bio:focus, .pattern-see-bio:hover {
    background-color: getColor(text, dark) !important;
    height: 27px;
    font-size: 14px;
}

// normal nw
.pattern-facebook, .pattern-facebook:focus, .pattern-facebook:hover{
    background-color: getColor(socials, facebook) !important;
}
.pattern-instagram, .pattern-instagram:focus, .pattern-instagram:hover{
    background-color: getColor(socials, instagram) !important;
}
.pattern-twitter, .pattern-twitter:focus, .pattern-twitter:hover{
    background-color: getColor(socials, twitter) !important;
}
.pattern-youtube, .pattern-youtube:focus, .pattern-youtube:hover{
    background-color: getColor(socials, youtube) !important;
}
.pattern-tiktok, .pattern-tiktok:focus, .pattern-tiktok:hover{
    background-color: getColor(socials, tiktok) !important;
}

// outline
.outline-dark, .outline-dark:focus, .outline-dark:hover {
    color: getColor(text, dark) !important;
    background-color: transparent !important;
    border: 1px solid getColor(text, dark) !important;

    .icon, .anticon-loading {
        color: getColor(text, dark) !important;
    }
}
.outline-light, .outline-light:focus, .outline-light:hover {
    box-shadow: none !important;
    color: getColor(text, dark) !important;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    
    .icon, .anticon-loading {
        color: getColor(text, dark) !important;
    }
}

.outline-primary, .outline-primary:focus, .outline-primary:hover {
    color: getColor(primary) !important;
    background-color: transparent !important;
    border: 1px solid getColor(primary) !important;

    .icon, .anticon-loading {
        color: getColor(primary) !important;
    }
}

.outline-error, .outline-error:focus, .outline-error:hover{
    color: getColor(status, error) !important;
    background-color: transparent !important;
    border: 1px solid getColor(status, error) !important;

    .icon, .anticon-loading {
        color: getColor(status, error);
    }
}

.outline-success, .outline-success:focus, .outline-success:hover{
    color: getColor(status, success) !important;
    background-color: transparent !important;
    border: 1px solid getColor(status, success) !important;

    .icon, .anticon-loading {
        color: getColor(status, success);
    }
}

.outline-warning, .outline-warning:focus, .outline-warning:hover{
    color: getColor(status, warning) !important;
    background-color: transparent !important;
    border: 1px solid getColor(status, warning) !important;

    .icon, .anticon-loading {
        color: getColor(status, warning);
    }
}

.outline-info, .outline-info:focus, .outline-info:hover{
    color: getColor(status, info) !important;
    background-color: transparent !important;
    border: 1px solid getColor(status, info) !important;

    .icon, .anticon-loading {
        color: getColor(status, info) !important;
    }
}

.outline-grey, .outline-grey:focus, .outline-grey:hover{
    background-color: #FFF !important;
    color: getColor(text, dark) !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;

    .icon, .anticon-loading {
        color: rgba(0, 0, 0, 0.15) !important;
    }
}

// outline nw
.outline-facebook, .outline-facebook:focus, .outline-facebook:hover{
    color: getColor(socials, facebook) !important;
    background-color: transparent !important;
    border: 1px solid getColor(socials, facebook) !important;

    .icon, .anticon-loading {
        color: getColor(socials, facebook) !important;
    }
}
.outline-instagram, .outline-instagram:focus, .outline-instagram:hover{
    color: getColor(socials, instagram) !important;
    background-color: transparent !important;
    border: 1px solid getColor(socials, instagram) !important;

    .icon, .anticon-loading {
        color: getColor(socials, instagram) !important;
    }
}
.outline-twitter, .outline-twitter:focus, .outline-twitter:hover{
    color: getColor(socials, twitter) !important;
    background-color: transparent !important;
    border: 1px solid getColor(socials, twitter) !important;

    .icon, .anticon-loading {
        color: getColor(socials, twitter) !important;
    }
}
.outline-youtube, .outline-youtube:focus, .outline-youtube:hover{
    color: getColor(socials, youtube) !important;
    background-color: transparent !important;
    border: 1px solid getColor(socials, youtube) !important;

    .icon, .anticon-loading {
        color: getColor(socials, youtube) !important;
    }
}
.outline-tiktok, .outline-tiktok:focus, .outline-tiktok:hover{
    color: getColor(socials, tiktok) !important;
    background-color: transparent !important;
    border: 1px solid getColor(socials, tiktok) !important;

    .icon, .anticon-loading {
        color: getColor(socials, tiktok) !important;
    }
}
