@import "/src/common/styles/colors.scss";

.card-solicitation {
    height: auto;
    display: flex;
    cursor: pointer;
    margin: 0 0 16px;
    padding: 24px 16px;
    border-radius: 8px;
    align-items: flex-end;
    background-color: getColor(background, lightest);

    .solicitation-content {
        width: 100%;
        display: flex;
        color: #4A4A4A;
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;

        .checkbox-container {
            margin-top: 5px;
            margin-right: 21px;
        }

        .circle-unread {
            left: 3px;
            bottom: 6px;
            width: 10px;
            height: 10px;
            position: absolute;
            border-radius: 50%;
            background-color: getColor(primary);
        }

        .solicitation-title {
            font-size: 20px;
            font-weight: 700;
        }

        .solicitation-description {
            font-size: 14px;
        }

        .solicitation-tag {
            display: flex;
            padding: 0 10px;
            border-radius: 18px;

            &.created {
                border: 1px solid #000;
                background-color: #FFF;
            }

            &.read {
                color: getColor(status, success);
                border: 1px solid getColor(status, success);
                background-color: getColor(status, success, .2);
            }

            &.archived {
                color: getColor(status, warning);
                border: 1px solid getColor(status, warning);
                background-color: getColor(status, warning, .2);
            }

            &.deleted {
                color: getColor(status, error);
                border: 1px solid getColor(status, error);
                background-color: getColor(status, error, .2);
            }
        }

        .date-button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            
            .solicitation-date {
                color: #333;
                font-size: 14px
                ;
                margin: 16px 0 0;
            }
        }
    }
}