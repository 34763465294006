.delete_column_modal {
  width: 30rem !important;
  padding: 0;
  top: 0;

  .content__delete_column_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      height: 72px;
      width: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffb320;
      border-radius: 999px;
    }

    > b {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 700;
    }

    > p {
      margin-top: 8px;
      font-size: 14px;
      text-align: center;
    }

    > div {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      gap: 32px;

      button:first-of-type {
        background: #ffffff !important;
        border: 1px solid #ed276a;
        color: #ed276a !important;

        &:hover {
          border-color: #ed276a;
        }
      }
    }
  }
}
