@mixin card {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #cbc4c7;
  border-radius: 8px;
}

$HEADER: 99px;

.curation-form {
  padding-bottom: 32px;
  min-height: calc(100vh - $HEADER);
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-template-rows: repeat(2, max-content);
  gap: 16px;
}

.observations__curation-form {
  padding: 0;
  margin: 0;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;

  .observation__curation-form {
    @include card;
    display: flex;
    gap: 1rem;

    img {
      height: 3.5rem;
      width: 3.5rem;
    }

    b {
      font-size: 1.25rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}

.form__curation-form {
  @include card;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  label {
    font-size: 16px;
    font-weight: 700;
  }

  p {
    margin: 4px 0 16px;
  }

  > div,
  > div *:first-of-type {
    margin: 0;
    flex: 1;
    height: 100%;
    display: flex;
  }

  textarea {
    padding: 16px;
    text-wrap: wrap;
    height: 100%;
    border: 1px solid #cccccc;
    border-radius: 8px;
    resize: vertical;
  }

  > footer {
    display: flex;
    margin-top: 16px;
    gap: 16px;

    a,
    button {
      height: 44px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ed276a;
      text-align: center;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: #fb3984;
      }
    }

    a:first-of-type {
      border: 1px solid #ed276a;
      background: #ffffff;
      color: #ed276a;

      &:focus-visible {
        outline: 2px solid black;
        outline-offset: 0;
      }

      &:hover {
        background: #fef1f7;
      }
    }
  }
}

.examples-container__curation-form {
  @include card;

  > b {
    font-size: 1rem;
    color: #ed276a;
  }

  .examples__curation-form {
    padding: 0;
    margin: 16px 0 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .example__examples {
    > div {
      display: flex;
      align-items: center;

      i {
        margin-right: 4px;
        font-size: 24px;
        color: #ed276a;
      }
    }

    p {
      margin: 0;
    }
  }
}
