@import "/src/common/styles/colors.scss";

.tabs {
    display: flex;
    position: relative;
    margin-bottom: 38px;

    .tab-option {
        width: 50%;
        display: flex;
        font-size: 16px;
        font-weight: 700;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid #CCC;
        transition: all .2s ease-in-out;

        &.unselected {
            cursor: pointer;
            color: #CCC !important;

            &:hover {
                opacity: .6;
            }
        }

        .count {
            top: 0;
            right: 0;
            width: 27px;
            height: 21px;
            color: #FFF;
            display: flex;
            font-size: 14px;
            margin-left: 4px;
            line-height: 23px;
            border-radius: 24px;
            justify-content: center;
            background-color: getColor(primary);
        }
    }

    .underline {
        left: 0;
        width: 50%;
        height: 3px;
        bottom: -1px;
        position: absolute;
        background-color: #333;
        transition: all .2s ease-in-out;
    }
}