@import "/src/common/styles/colors.scss";

.add-brand-page {
    width: 100%;
    height: 100%;

    .header {
        padding-bottom: 16px;
    }

    .add-brand-content {
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 84px 12px;

        .add-brand-forms {
            margin: 0 auto;
            max-width: 1280px;

            .ant-form {
                max-width: 647px;
                border-radius: 8px;
                padding: 24px 24px 41px;
                background-color: #FFF;

                .ant-form-item-required::before {
                    display: none;
                }

                .ant-steps {
                    margin-bottom: 48px;

                    .ant-steps-item {
                        // &:hover {
                        //     .ant-steps-item-title {
                        //         color: rgba(99, 99, 99);
                        //     }
                        // }

                        .ant-steps-item-icon {
                            width: 32px;
                            height: 32px;
                            border: none;
                            border-radius: 50%;
                            background-color: #B3B3B3;

                            .ant-steps-icon {
                                .icon {
                                    font-size: 16px;
                                    color: #FFF !important;
                                }
                            }

                        }

                        .ant-steps-item-title {
                            font-size: 16px;
                            font-weight: 600;
                        }

                        &.ant-steps-item-finish {
                            .ant-steps-item-tail::after {
                                background-color: getColor(status, darkSuccess);
                            }

                            .ant-steps-item-icon {
                                background-color: getColor(status, darkSuccess);

                                .ant-steps-icon .anticon {
                                    color: #FFF;
                                }
                            }

                            .ant-steps-item-title {
                                color: #808080;
                            }
                        }

                        &.ant-steps-item-active {
                            .ant-steps-item-icon {
                                background-color: getColor(primary);
                            }
                        }
                    }
                }

                .title-form {
                    font-size: 20px;
                    color: #4A4A4A;
                    font-weight: 700;
                    margin-bottom: 24px;
                    
                    &.first {
                        margin-bottom: 48px;
                    }
                }

                .junction-input-icon {
                    display: flex;
                    align-items: center;

                    .ant-form-item {
                        width: 100%;
                    }

                    .icon-close {
                        width: 60px;
                    }

                    .add-btn {
                        max-width: 160px;
                        margin: 0 auto 0 8px;
                    }
                }

                .junction-input-btn {
                    display: flex;

                    .ant-btn {
                        width: 163px;
                        margin-left: 8px;
                    }
                }

                .buttons {
                    gap: 16px;
                    display: flex;
                    margin-top: 40px;

                    .ant-btn {
                        width: 100%;
                        height: auto;
                        margin: 0 auto;
                        padding: 10px;
                        font-size: 20px;
                        box-shadow: 0 2px 1px 0 rgb(201 201 201 / 50%) !important;
                    }
                }
            }
        }

        .ant-input {
            background-color: #FFF !important;
        }
    }
}