@import "/src/common/styles/colors.scss";

.simple-pagination {
    display: flex;
    justify-content: flex-end;

    .ant-pagination-prev {
        margin-right: 16px;
    }

    .ant-pagination-next {
        margin-left: 16px;
    }

    .ant-pagination-item-link-icon svg {
        color: getColor(primary);
    }

    .ant-pagination-prev button,
    .ant-pagination-next button {
        .icon {
            font-size: 18px;
        }
    }

    .ant-pagination-prev {
        margin-right: 10px;
    }

    .ant-pagination-next {
        margin-left: 10px;
        
    }

    .ant-pagination-next,
    .ant-pagination-prev {
        &.ant-pagination-disabled:hover {
            button {
                color: rgba(0, 0, 0, .25) !important;
            }
        }

        button {
            border: none;
            border-radius: 50%;
            background-color: transparent;

            &:hover {
                color: getColor(text, dark) !important;
            }
        }
    }

    .ant-pagination-item {
        margin: 0;
        border: none;
        min-width: auto;
        border-radius: 50%;
        background-color: transparent !important;

        a {
            // padding: 0;
            color: getColor(text, dark, .4) !important;
        }

        &:hover {
            a {
                color: getColor(text, black) !important;
            }
        }

        &.ant-pagination-item-active {
            a {
                font-weight: 600;
                color: getColor(text, black) !important;
            }
        }
    }

    .ant-pagination-options {
        display: none;
    }
}