@import '/src/common/styles/colors.scss';

.observation {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 24px;
}

.blocked-charts {
  height: 330px;
  display: flex;
  margin-top: 32px;
  position: relative;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 82px;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;

  .message-insufficient-data {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .reasons-for-block {
    font-size: 18px;
    // color: getColor(primary);
    text-decoration: underline;
  }

  .ant-btn {
    margin-top: 40px;
  }

  .reasons-for-block {
    .icon {
      font-size: 16px;
      margin-left: 2px;
    }
  }

  .unavailable-img {
    width: 175px;
    margin-top: 30px;
  }
}

.demographics {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-bottom: 82px;
  flex-direction: column;
  justify-content: space-between;

  .observation {
    width: 100%;
  }

  .text-part {
    margin: 0 0 15px 8px;
  }

  .ant-tabs {
    overflow: auto;

    .ant-tabs-nav-list {
      border-bottom: 1px solid #000;
    }

    .ant-tabs-tab {
      font-size: 16px;
      padding: 8px 16px;
      margin-left: 0 !important;

      .ant-tabs-tab-btn {
        color: #000000 !important;
      }

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-weight: 700;
        color: #000000 !important;
      }
    }

    .ant-tabs-ink-bar {
      height: 3px;
      margin-bottom: -2px;
      background-color: #000 !important;
    }
  }

  .current-charts {
    display: flex;
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      transition: all 0.2s ease-in-out;

      &.chart-gender,
      &.chart-has_child {
        max-width: 330px;
      }

      .bars {
        padding-top: 24px;

        .highcharts-container {
          width: 100% !important;
        }
      }

      .donuts,
      .bars {
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        position: relative;
        background-color: #fff;

        .no-data {
          width: 100%;
          display: flex;
          height: 331px;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }

        .icon {
          top: 10px;
          z-index: 2;
          right: 12px;
          position: absolute;
        }
      }
    }

    .box-donuts,
    .box-chart {
      // animation: scaleCard .2s;

      @keyframes scaleCard {
        from {
          opacity: 0;
          transform: scale(0);
        }

        to {
          opacity: 100;
          transform: scale(1);
        }
      }
    }

    .box-donuts {
      width: 100%;
      display: flex;
      gap: 8px 19px;
      flex-wrap: wrap;
      padding: 0 !important;
      justify-content: flex-end;

      img {
        width: 295px;
      }

      .chart-items {
        gap: 24px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        padding: 16px 24px;
        background-color: #fff;
        justify-content: space-between;

        .chart-item {
          width: calc(50% - 24px);

          .value-name {
            font-size: 14px;
            margin-bottom: 4px;
          }

          .junction-value {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            align-items: center;

            div {
              display: flex;
              align-items: center;

              .icon {
                font-size: 24px;
                margin-right: 8px;
                color: getColor(primary);
              }

              b {
                font-size: 16px;
              }
            }

            .value {
              font-size: 16px;
            }

            .release_data__junction_value {
              all: unset;
              cursor: pointer;
              text-decoration: underline;
              font-size: 16px;
              color: #1a1517;
              text-underline-offset: 0.25rem;
              transition: 0.2s;

              &:hover {
                color: getColor(primary, base);
              }
            }
          }

          .no-data {
            color: #000000;
          }
        }
      }

      .ant-btn {
        padding: 0;
        text-decoration: underline;
      }
    }

    &.current-charts-sex-age-place {
      justify-content: space-between;

      .box-chart-gender {
        width: 411px;
      }

      .box-chart-census {
        width: calc(100% - 435px);
      }

      .box-chart-location {
        width: 100%;
        margin-top: 24px;
      }
    }

    &.current-charts-maritial-status-has-child {
      gap: 24px;

      .box-chart {
        width: 50%;
        max-width: 411px;

        .donuts .no-data {
          height: auto;
          margin: 130px 0 -20px;
        }
      }
    }

    &.current-charts-interests-brands,
    &.current-charts-media-consumed-and-profiles-most-followed {
      gap: 24px;

      .box-chart {
        width: 100%;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.spinner__junction_value {
  border-radius: 999px;
  height: 20px;
  width: 20px;
  border: 2px solid getColor(primary, base);
  border-right-color: transparent;
  animation: spin linear 0.6s infinite;
}

.spinner_card__junction_value {
  border-radius: 999px;
  height: 32px;
  width: 32px;
  border: 2px solid getColor(primary, base);
  border-right-color: transparent;
  animation: spin linear 0.6s infinite;
}

.card_release_data {
  width: 100%;
  height: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #ffffff;
  color: #333333;

  strong {
    font-size: 1.5rem;
    font-weight: 700;
  }

  p {
    margin: 1rem 0 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  button {
    padding: 0;
    height: 2.5rem;
    width: 14rem;
    background: getColor(primary, base);
    border: none;
    border-radius: 0.5rem;
    font-size: 16px;
    font-weight: 700;
    color: getColor(text, white);
    cursor: pointer;
    outline: none;
    transition: background 0.2s ease-in-out;

    &:not(:disabled):hover {
      background: getColor(primary, base, 0.75);
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px getColor(text, black, 1);
    }
  }

  & + & {
    display: none !important;
  }
}

.highcharts-container {
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.container-logo {
  width: 1050px;
  display: flex;
  margin: 0 auto 40px;
  justify-content: flex-end;

  .logo-demographics-pro {
    width: 112px;
    margin-top: 20px;
  }
}
