@import '/src/common/styles/colors.scss';

.layout-pattern {
  width: 100%;
  min-height: 100vh;

  .background-page {
    height: 100%;
    padding-left: 54px;
    box-sizing: content-box;
    width: calc(100% - 54px);
    background-color: #f2f2f2;
    transition: all 0.2s ease-in-out;
  }

  .container-floating-actions {
    margin-left: 54px;

    .box-floating-actions {
      width: calc(100% - 54px);
    }
  }

  .scroll-content {
    height: 100%;
    transition: all 0.2s ease-in-out;
  }

  &.menu-open {
    .container-floating-actions {
      margin-left: 204px;

      .box-floating-actions {
        width: calc(100% - 204px);
      }
    }

    .background-page {
      padding-left: 204px;
      width: calc(100% - 204px);
      transition: all 0.2s ease-in-out;
    }
  }

  &.in-loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .container-loading {
      margin: 0 !important;

      img {
        width: 75px !important;
      }
    }
  }

  .feedback-error {
    display: flex;
    font-size: 16px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: 80%;
      max-width: 290px;
      margin-bottom: 40px;
    }
  }
}

$menu-collapsed: 56px;
$menu-expanded: 207px;

.dev-env {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - $menu-expanded);
  background: #ffe350;
  transition: width 0.2s ease-in-out;
  z-index: 10;

  &[data-collapsed-menu='true'] {
    width: calc(100% - $menu-collapsed);
  }

  span {
    display: block;
    text-align: center;
    color: black;
  }
}
