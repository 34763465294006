.layout-pattern-public {
    margin-top: 45px;

    .container-header {
        top: 0;
        left: 0;
        width: 100%;
        height: 45px;
        z-index: 9999;
        display: flex;
        position: fixed;
        padding: 0 16px;
        align-items: center;
        background-color: #FFF;
        border-bottom: 1px solid #CBC4C7;

        .content-header {
            img {
                height: 26px;
            }
        }
    }

    .pattern-page {
        display: flex;
        padding: 22px 16px;
        flex-direction: column;
        max-width: 1440px;
    }
}

@media only screen and (min-width: 768px) {
    .layout-pattern-public {
        margin-top: 68px;

        .container-header {
            height: 68px;
            padding: 0 32px;

            .content-header {
                img {
                    height: 46px;
                }
            }
        }

        .pattern-page {
            padding: 32px 32px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .layout-pattern-public {
        margin-top: 0;

        .container-header {
            height: 62px;
            padding: 0 38px;
            min-height: 62px;
            position: relative;
            justify-content: center;

            .content-header {
                width: 100%;
                max-width: 1440px;

                img {
                    height: 30px;
                }
            }
        }

        .pattern-page {
            margin: 0 auto;
            max-width: 1440px;
            padding: 32px 38px;
            box-sizing: content-box;
        }
    }
}