.modal-lists-content-add-to-workspace {
    .ant-select-selection-search {
        display: flex;
        align-items: center;
    }
    padding: 10px 16px 16px;
    .ant-select {
        width: 100%;
    }
    .input-junction {
        flex-direction: row;

        .ant-input::placeholder {
            font-size: 14px;
        }

        .input {
            background-color: #FFF;
            box-shadow: none !important;
            // border-radius: 12px 0 0 12px;
            border: 1px solid #d9d9d9 !important;
        }

        .suffix {
            width: 40px;
            height: 40px;
            z-index: 99;
            position: relative;
            background-color: getColor(primary);
            
            .icon {
                padding: 10px;
            }
            
            &.disabled {
                cursor: not-allowed;
                background-color: #fb90b4 !important;                        
            }

            &:hover {
                opacity: 1 !important;
                background-color: #fb90b4 !important;                        
            } 
        }
    }

    .modal-lists-junction {
        margin-top: 10px;
        overflow-y: auto;
        max-height: 200px;

        .option-list {
            width: 100%;
            height: 32px;
            display: flex;
            text-align: left;
            overflow: hidden;
            text-align: left;
            align-items: center;

            .ant-checkbox-wrapper {
                width: 100%;
                
                .ant-checkbox+span {
                    text-wrap: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 30px);
                }
            }

            .ant-checkbox-inner::after {
                top: 50%;
                left: 50%;
                transform: translate(-35%, -60%) rotate(45deg);
            }

            .ant-checkbox-checked::after {
                border: 1px solid transparent !important;
            }

            .ant-checkbox-wrapper:hover .ant-checkbox-inner {
                border-color: getColor(primary) !important;
            }

            .ant-checkbox-wrapper {

                .ant-checkbox-wrapper:hover .ant-checkbox-inner,
                .ant-checkbox:hover .ant-checkbox-inner,
                .ant-checkbox-input:focus+.ant-checkbox-inner {
                    border-color: getColor(primary) !important;
                }

                .ant-checkbox-inner {
                    &:hover {
                        border-color: getColor(primary) !important;
                    }
                }

                .ant-checkbox-checked .ant-checkbox-inner {
                    border-color: getColor(primary) !important;
                    background-color: getColor(primary);
                }

            }
        }

        .pl-loading {
            display: flex;
            align-items: center;

            .ant-spin {
                display: flex;
                align-items: center;

                .ant-spin-dot {
                    width: 16px;
                    height: 16px;

                    .ant-spin-dot-item {
                        width: 8px;
                        height: 8px;
                        background-color: getColor(primary);
                    }
                }
            }

            span {
                padding: 0 8px;
                font-size: 14px;
            }
        }
    }
}


.ant-popover-inner-content .modal-lists.open-modal-lists-add-to-workspace {
    width: 400px;
    height: 300px;
    transition: max-height 0.3s ease-out;
}
