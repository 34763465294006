@import "/src/common/styles/colors.scss";

.chart-description {
    display: flex;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;

    p {
        margin: 0;
    }

    .text {
        position: relative;
        padding-right: 16px;

        span {
            font-size: 14px;
            margin-left: 4px;
            text-transform: lowercase;
        }

        &::after {
            top: 6px;
            content: "";
            left: -18px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: absolute;
            background-color: getColor(primary);
        }
    }
}