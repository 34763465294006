@import "/src/common/styles/colors.scss";

.container-modal-simulation>span {
    display: flex;
    align-items: center;
}

.container-modal-simulation {
    display: flex;
    align-items: center;
    justify-content: center;


    .icon {
        cursor: pointer;
        margin-left: 4px;
        transition: .2s ease-in all;

        &:hover {
            opacity: 0.6;
            transition: .2s ease-in all;
        }

        &.open {
            transform: rotate(180deg);
        }
    }

    .icon-favorite {
        padding: 8px;
        font-size: 22px;
        margin-left: -8px;

        &.active {
            color: #FFF;
            border-radius: 50%;
            background-color: getColor(primary) !important;
        }
    }

    .icon-my-list-circle {
        color: getColor(text, dark);
    }

    .ant-btn {
        background-color: #333 !important;
    }
}

.modal-simulation {
    left: 50%;
    z-index: 2;
    bottom: 40px;
    height: 88px;
    display: flex;
    overflow: hidden;
    position: fixed;
    border-radius: 8px;
    align-items: center;
    white-space: break-spaces;
    transition: all .2s ease-in;
    border: 1px solid #D8D8D8;
    transition: all 0.2s ease-in-out;
    animation: moveSimulationModalTop .3s;
    transform: translate(calc(-50% + 104px));
    background-color: getColor(background, lightest);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.13);

    &.collapsed {
        transform: translate(calc(-50% + 28px));
    }

    @keyframes moveSimulationModalTop {
        from {
            opacity: 0;
            bottom: -100%;
        }
        
        to {
            bottom: 40px;
            opacity: 100;
        }

    }

    &.closed {
        animation: moveSimulationModalBottom .3s;

        @keyframes moveSimulationModalBottom {
            from {
                bottom: 40px;
                opacity: 1;
            }

            to {
                opacity: 0;
                bottom: -100%;
            }
        }
    }

    .tag-influencers-count {
        height: 100%;
        width: 122px;
        color: #FFF;
        display: flex;
        padding: 9px 0;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: getColor(primary);

        .count {
            font-size: 40px;
            font-weight: 700;
            line-height: 45px;
        }
    }

    .simulation-input {
        height: 100%;
        display: flex;
        padding: 15px 24px;
        flex-direction: column;
        justify-content: space-between;
        
        .modal-simulation-header-title {
            font-size: 12px;
            line-height: 10px;
        }

        input {
            width: 112px;
            height: 32px;
        }
    }


    .buttons {
        gap: 40px;
        display: flex;
        height: 100%;
        padding: 15px 24px 15px 16px;
        justify-content: space-between;

        .button {
            width: 83px;
            height: 100%;
            min-width: auto;
            font-size: 12px;
            line-height: 12px;
            flex-direction: column;
            border: none !important;
            justify-content: space-between;

            .icon {
                margin: 0;
                font-size: 18px;
            }

            &:first-child {
                .icon {
                    font-size: 22px;
                    color: #4A4A4A !important;
                }
            }
        }
    }


    .icon-close {
        height: 100%;
        display: flex;
        padding: 0 24px;
        align-items: center;
        border-left: 1px solid #D8D8D8;
    }
}