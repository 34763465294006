@import "/src/common/styles/colors.scss";

.demographics-pdf {
    display: flex;
    flex-wrap: wrap;
    max-width: 1465px;
    margin: 0 auto 16px;
    justify-content: space-between;

    &.demographics-pdf-1 {
        border-radius: 8px;
        margin-bottom: 24px;
        align-items: center;
        background-color: #FFF;

        img {
            width: 40px;
            height: 40px;
        }

        .donuts {
            width: 30%;
            padding: 60px 24px 70px;
            
            .subtitle-part {
                margin-bottom: 18px;
            }
        }

        .bar {
            width: 65%;
            margin-bottom: 0;
        }
    }

    .bar {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 24px;
        padding: 24px 24px 0;
        background-color: #FFF;
    }
}

.blocked-charts-pdf {
    max-width: 1465px;
    border-radius: 6px;
    margin: 0 auto 16px;
    background-color: #FFF;

    p {
        height: 483px;
        display: flex;
        font-size: 22px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .title-warning {
            font-weight: 700;
            margin-bottom: 40px;
        }

        .text-warning {
            max-width: 850px;
            text-align: center;
        }
    }
}

.label-circle-chart-pdf {
    width: 74px;
    height: 74px;
    display: flex;
    font-size: 16px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    color: #000 !important;
    box-shadow: 2px 7px 4px rgba(0, 0, 0, 0.02), 1px 3px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03);


    &.label-circle-chart-pdf-1 {
        top: -40px;
        left: 4px;
    }

    &.label-circle-chart-pdf-0 {
        top: -20px;
        left: -20px;
    }
}
