@import "/src/common/styles/colors.scss";

.select {
    .ant-select-selector {
        min-width: 150px !important;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        height: 40px !important;
        color: #4a4a4a !important;
        box-shadow: none !important;
        border-radius: 12px !important;
        background-color: #FAFAFA !important;
        border: 1px solid #D9D9D9 !important;
        
        .ant-select-selection-item {
            color: getColor(text, black)
        }

        .ant-select-selection-placeholder {
            display: flex;
            font-size: 13px;
            color: #767676;
            line-height: 40px;
            align-items: center;
        }
    }

    .ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
        background-color: getColor(primary, light) !important;
    }

    .ant-select-item-option.ant-select-item-option-selected {
        background-color: getColor(primary, light) !important;
    }

    .ant-select-item-option,
    .ant-select-item-option.ant-select-item-option-active {
        padding: 10px;
        font-size: 14px;
        color: getColor(text, medium);
        background-color: getColor(background, lightest) !important;

        &:hover {
            background-color: getColor(primary, light) !important;
        }
    }

    .ant-select-selector {
        .ant-select-selection-placeholder {
            color: getColor(text, dark);
        }
    }

    .ant-select-arrow {
        .anticon svg {
            fill: getColor(text, dark);
        }
    }

    .ant-select-item,
    .ant-select-selection-item {
        text-transform: capitalize;
    }
}

.ant-select-dropdown {
    z-index: 99999;
}

.ant-select-selection-item, .ant-select-item-option-content {
    .icon {
        font-size: 18px;
        margin-right: 8px;
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: getColor(primary) !important;
    background-color: getColor(primary, .2) !important;
}