@import "/src/common/styles/colors.scss";

.avatar {
    border-radius: 50%;
    display: inline-flex;
    transition: 0.2s ease-in all;

    &.avatar-twitter {border: 2px solid getColor(socials, twitter);}
    &.avatar-youtube {border: 2px solid getColor(socials, youtube);}
    &.avatar-facebook {border: 2px solid getColor(socials, facebook);}
    &.avatar-instagram {border: 2px solid getColor(socials, instagram);}
    &.avatar-pinterest {border: 2px solid getColor(socials, pinterest);}
}