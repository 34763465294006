@import "/src/common/styles/colors.scss";

.influencer-profile-pdf {
    display: flex;
    padding: 48px;
    margin: 0 auto;
    max-width: 1465px;
    margin-bottom: 48px;
    align-items: center;
    border-radius: 16px;
    background-color: #FFF;
    justify-content: flex-start;

    .network-logo {
        width: 90px !important;
    }
    
    .influencer-data {
        margin-left: 48px;

        .name-verified-classification {
            margin: 0;
            display: flex;
            letter-spacing: 0;
            align-items: center;
            margin-bottom: 24px;
            color: getColor(text, dark);

            .name {
                font-size: 26px;
                font-weight: 700;
                overflow: hidden;
                margin-right: 16px;
                white-space: nowrap;
                margin-bottom: -2px;
            }

            .classification {
                font-size: 14px;
                line-height: 16px;
                font-weight: normal;
            }

            .accelerated-growth {
                height: 26px;
                color: #FFF;
                margin: 8px 0;
                display: flex;
                padding: 0 8px;
                max-width: 214px;
                align-items: center;
                border-radius: 24px;
                background-color: getColor(primary);

                .icon {
                    font-size: 14px;
                    margin-right: 4px;
                }

                span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        .bio-title {
            font-size: 19px;
        }
        
        .bio {
            width: 100%;
            height: 100px;
            margin-top: 8px;
            font-size: 19px;
            max-width: 850px;
            line-height: 26px;
        }
    }
}