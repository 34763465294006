.conect_card_rm {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .lucide-circle-check {
        color: #038d00;
        margin-right: 5px;
    }

    .lucide-external-link {
        color: #484848;
        margin-left: 5px;
    }
    
  }