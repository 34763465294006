@import "/src/common/styles/colors.scss";

.influencer-categories-pdf {
    margin: 0 auto;
    max-width: 1465px;
    margin-bottom: 32px;

    .categories-card {
        padding: 24px 24px 32px;
        background-color: #FFF;

        .text-empty {
            font-size: 16px;
            text-align: center;
            max-width: none !important;
            margin: 59px auto !important;
        }

        .categories {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .category-card {
                margin: 0;
                padding: 0;
                max-width: 185px;
                border-radius: 8px;
                text-align: center;
                width: calc(18% - 4px);

                .category-card-header {
                    flex-direction: column-reverse !important;
                }

                .icon {
                    font-size: 42px;
                }

                .category-name {
                    font-size: 18px;
                    margin: 40px 0 0;
                    font-weight: 400;
                }

                .category-airscore {
                    margin: 0;
                    bottom: -95px;
                    position: relative;

                    .icon {
                        font-size: 16px;
                    }

                    .score {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}