@import "/src/common/styles/colors.scss";

.part-persona {
    width: 100%;

    .persona.chart-items {
        gap: 24px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        padding: 16px 24px;
        background-color: #FFF;
        justify-content: space-between;

        .chart-item {
            width: calc(50% - 24px);

            .value-name {
                font-size: 16px;
                margin-bottom: 4px;
            }

            .junction-value {
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                    display: flex;
                    align-items: center;

                    .icon {
                        font-size: 24px;
                        margin-right: 8px;
                        color: getColor(primary);
                    }

                    b {
                        font-size: 19px;
                    }
                }

                .value {
                    font-size: 18px;
                }
            }

            .no-data {
                color: #000000;
            }
        }
    }
}