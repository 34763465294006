@import "/src/common/styles/colors.scss";

.influencer-posts {
    margin-bottom: 82px;

    .posts-card {
        border-radius: 8px;
        padding: 24px 24px 16px;
        background-color: #fff;

        .empty {
            display: flex;
            padding: 20px 0;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            img {
                margin-top: 10px;
            }
        }

        &.posts-card-simple {
            ::-webkit-scrollbar {
                width: 11px;
                border: none;
            }

            ::-webkit-scrollbar-track {
                background: #F2F2F2;
            }

            ::-webkit-scrollbar-thumb {
                background: #F2F2F2;
            }

            .selects {
                justify-content: flex-start !important;
            }
        }

        .post-filters {
            display: flex;
            margin-bottom: 30px;
            align-items: flex-start;
            justify-content: space-between;
            
            .box-search-keywords {
                width: 50%;
                display: flex;
                align-items: center;

                .icon-close {
                    padding: 10px;
                }

                .input-junction {
                    width: 368px;
                    
                    .input {
                        width: 100%;
                        padding-left: 24px;
                        padding-right: 40px;
                        background-color: #FFF;
    
                        .ant-input-prefix {
                            margin-right: 12px;
    
                            .icon-search {
                                font-size: 24px;
                                color: #767676;
                            }
                        }
                    }
                }

                .submit-post-keyword {
                    width: 224px;
                    margin-left: 14px;
                }
            
                .icon-close {
                    z-index: 9;
                    cursor: pointer;
                    margin-left: -40px;
                    position: relative;
                }
            }

            .selects {
                gap: 8px;
                width: 50%;
                display: flex;
                justify-content: flex-end;
            }

            .junction-checkbox {
                gap: 8px;
                height: 40px;
                display: flex;
                font-size: 13px;
                align-items: center;
            }
        }


        .container-loading {
            margin: 70px auto !important;
        }

        ::-webkit-scrollbar-thumb:horizontal {
            height: 8px !important;
            background-color: #888;
        }

        ::-webkit-scrollbar-track:horizontal {
            height: 7px !important;
            background: #D9D9D9;
        }
    
        .posts {
            gap: 24px;
            width: 100%;
            display: grid;
            flex-wrap: wrap;
            overflow-x: auto;
            min-height: 390px;
            padding-bottom: 8px;
            justify-content: flex-start;


            @supports(display: grid) {
                .posts {
                    position: relative;
                    width: 100%;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    white-space: nowrap;
                    transition: all 0.2s;
                    transform: scale(0.98);
                    will-change: transform;
                    user-select: none;
                    cursor: pointer;
                }

                .posts.active {
                    background: rgba(255, 255, 255, 0.3);
                    cursor: grabbing;
                    cursor: -webkit-grabbing;
                    transform: scale(1);
                }
            }

            &.youtube-posts {
                // 3
                min-height: 330px;
                grid-template-columns: repeat(3, 1fr);

                .youtube-post {
                    width: 100%;
                }
            }

            &.twitch-posts {
                // 3
                min-height: 330px;
                grid-template-columns: repeat(3, 1fr);

                .twitch-post {
                    width: 100%;
                }
            }

            &.tiktok-posts {
                // 5
                gap: 16px;
                grid-template-columns: repeat(5, 1fr);

                &.posts-3 {
                    grid-template-columns: repeat(4, 1fr);
                }

                .twitter-post {
                    width: 100%;
                }
            }

            &.twitter-posts {
                // 4
                min-height: auto;
                min-height: 200px !important;
                grid-template-columns: repeat(4, 1fr);

                .twitter-post {
                    width: 100%;
                }
            }

            &.facebook-posts {
                grid-template-columns: repeat(4, 1fr);

                .facebook-post {
                    width: 100%;
                }
            }

            &.instagram-posts {
                grid-template-columns: repeat(4, 1fr);

                .instagram-post {
                    width: 100%;
                }
            }
        }
    }
}