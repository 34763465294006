@import "/src/common/styles/colors.scss";

*,
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  // remove focus
  --antd-wave-shadow-color: transparent !important;

}

html {
  height: 100%;
}

body {
  margin: 0;
  z-index: 1;
  padding: 0;
  height: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  background: #f0f2f5;
  flex-direction: column;
  width: 100% !important;
  
  .btn-back {
    padding: 0;
  }
}

.hover {
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    opacity: .6;
  }
}

.deselect-text {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.app-loading {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: white;
}

::-webkit-scrollbar {
  width: 4px;
  /* width of vertical scrollbar */
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  border: 1px solid #888;
}

::-webkit-scrollbar {
  width: 5px;
  /* width of vertical scrollbar */
  height: 5px;
  /* height of horizontal scrollbar ← You're missing this */
  border: 1px solid #888;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(97, 97, 97);
}


// per networks
.color-instagram,
.color-ig {
  color: getColor(socials, instagram) !important;
}

.border-color-instagram,
.border-color-ig {
  border-color: getColor(socials, instagram) !important;
}

.background-color-instagram,
.background-color-ig {
  background-color: getColor(socials, instagram) !important;
}

//
.color-facebook,
.color-fb {
  color: getColor(socials, facebook) !important;
}

.border-color-facebook,
.border-color-fb {
  border-color: getColor(socials, facebook) !important;
}

.background-color-facebook,
.background-color-fb {
  background-color: getColor(socials, facebook) !important;
}

//
.color-twitter,
.color-tw {
  color: getColor(socials, twitter) !important;
}

.border-color-twitter,
.border-color-tw {
  border-color: getColor(socials, twitter) !important;
}

.background-color-twitter,
.background-color-tw {
  background-color: getColor(socials, twitter) !important;
}

//
.color-tiktok,
.color-tt {
  color: getColor(socials, tiktok) !important;
}

.border-color-tiktok,
.border-color-tt {
  border-color: getColor(socials, tiktok) !important;
}

.background-color-tiktok,
.background-color-tt {
  background-color: getColor(socials, tiktok) !important;
}

// 
.color-youtube,
.color-yt {
  color: getColor(socials, youtube) !important;
}

.border-color-youtube,
.border-color-yt {
  border-color: getColor(socials, youtube) !important;
}

.background-color-youtube,
.background-color-yt {
  background-color: getColor(socials, youtube) !important;
}

.triangle {
  width: 0px;
  height: 0px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4a4a4a;

}

.intercom-app>div,
.intercom-lightweight-app {
  z-index: 9998 !important;
}

.ant-tooltip {
  z-index: 99999;

  .ant-tooltip-inner {
    font-size: 12px;
    line-height: none;
  }
}

.page-not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}