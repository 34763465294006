@import '/src/common/styles/colors.scss';

.influencer-profile {
  display: flex;
  padding-top: 32px;
  margin-bottom: 48px;
  align-items: flex-start;
  justify-content: flex-start;

  .ant-btn {
    background-color: getColor(background, grey);
    color: getColor(text, dark);
  }

  .network-logo {
    width: 50px !important;
    height: 50px;
  }

  .influencer-data {
    margin-left: 41px;

    .name-verified-classification {
      margin: 0;
      display: flex;
      letter-spacing: 0;
      align-items: center;
      color: getColor(text, dark);

      .name {
        display: flex;
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
        margin-right: 8px;
        align-items: center;

        .icon {
          font-size: 20px;
          color: #3897f0;
          margin-left: 10px;
        }
      }
    }

    .username {
      font-size: 16px;
      margin: 0;
      text-decoration: underline;
      display: inline-flex;
    }

    .href {
      margin: 0 16px 1px 8px;

      .icon {
        color: getColor(text, medium);
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    b {
      font-size: 16px;
      margin-bottom: 8px;
    }

    .bio {
      width: 100%;
      max-width: 650px;
      overflow-x: auto;
      max-height: 125px;
      margin-bottom: 24px;
    }

    .about {
      margin-bottom: 24px;

      .about-data-junction {
        display: flex;

        .about-data {
          height: auto;
          display: inline-flex;
          font-size: 14px;
          margin-left: 16px;
          padding-left: 16px;
          align-items: center;
          border-left: 1px solid #ccc;
          column-gap: 4px;

          &:first-child {
            margin-left: 0;
            padding-left: 0;
            border-left: none;
          }

          .icon {
            font-size: 18px;
            margin-right: 8px;
          }
        }

        .email_header {
          display: inline-flex;
          align-items: center;
          gap: 6px;
          font-size: 14px;
          border-radius: 99px;
          margin-right: 12px;

          .whats_app {
            text-decoration: underline;
            text-underline-offset: 2px;
            cursor: pointer;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
            color: #333333;
          }
        }
      }
    }

    .part-stamps {
      p {
        margin-bottom: 8px;
      }

      .stamps {
        gap: 8px;
        display: flex;
        justify-content: flex-start;

        .profile-hide-like,
        .accelerated-growth {
          height: 32px;
          color: #fff;
          margin: 8px 0;
          display: flex;
          color: #1a1517;
          align-items: center;
          border-radius: 24px;
          padding: 0 16px 0 8px;
          border: 1px solid #1a1517;

          .icon {
            font-size: 16px;
            margin-right: 12px;
          }

          span {
            font-size: 14px;
            font-weight: 400;
          }
        }

        .profile-hide-like {
          img {
            color: #000;
            margin-right: 12px;
          }
        }
      }
    }

    .influencer-actions {
      display: flex;
      margin-top: 16px;
      align-items: center;

      .button {
        min-width: 182px;
        margin-right: 16px;
      }

      .test {
        display: flex;
        margin-left: 20px;
        flex-direction: column;
      }

      .container-icon {
        border-radius: 6px;
        background-color: #fff;
      }

      .icon-pink-star,
      .icon-favorite {
        cursor: pointer;
        font-size: 34px;

        &:hover {
          opacity: 0.4;
        }
      }

      .icon-pink-star {
        padding: 3px;
        color: getColor(primary);
        animation: openStartScale 0.2s;
        transition: all 0.2s ease-in-out;

        @keyframes openStartScale {
          0% {
            transform: scale(2.5);
          }

          100% {
            transform: scale(1);
          }
        }
      }

      .icon-favorite {
        padding: 9px;
        font-size: 22px;
      }
    }
  }
}

.classification_tooltip {
  padding: 12px;
}

.ant-tooltip .ant-tooltip-inner {
  max-height: none;
}
