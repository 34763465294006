@import "/src/common/styles/colors.scss";

.add-category-page {
    width: 100%;
    height: 100%;

    .header {
        padding-bottom: 16px;
    }

    .add-category-content {
        margin: 0 auto;
        max-width: 1280px;
        padding: 0 84px 12px;
        box-sizing: content-box;

        .add-category-form {
            max-width: 647px;
            border-radius: 8px;
            padding: 24px 24px 41px;
            background-color: #FFF;

            .title-form {
                font-size: 20px;
                color: #4A4A4A;
                font-weight: 700;
                margin-bottom: 24px;
            }

            .ant-form {
                .input-junction input {
                    border-radius: 6px !important;
                    background-color: #FFF !important;
                }

                textarea {
                    outline: none;
                    color: #4a4a4a;
                    font-weight: 400;
                    min-height: 276px;
                    padding: 6px 12px;
                    border-radius: 6px;
                    box-sizing: border-box;
                    font-size: 13px !important;
                    box-shadow: none !important;
                    border: 1px solid #D9D9D9 !important;

                    &::placeholder {
                        color: #767676 !important;
                    }
                }

                .description {
                    width: 100%;
                    color: #4A4A4A;
                    padding: 8px 0 24px;

                    p {
                        font-size: 13px;
                        margin-bottom: 4px;
                    }
                }

                .ant-btn {
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    padding: 10px;
                    font-size: 20px;
                    box-shadow: 0 2px 1px 0 rgb(201 201 201 / 50%) !important;
              
                }
            }
        }
    }
}