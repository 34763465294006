@import "/src/common/styles/colors.scss";

.blank-item {
    width: 100%;
    /* height: auto; */
    display: flex;
    margin-top: -10px;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    /* background-color: aquamarine; */
    /* position: relative; */
}

.blank-item span {
    font-size: 10px;
    text-align: center;
    color: getColor(text, blankItem);
    
}