@import "/src/common/styles/colors.scss";

.popover-item {
    width: 290px;
    display: flex;
    position: relative;
    flex-direction: column;

    label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .box-to-close {
        z-index: 9;
        width: 100%;
        height: 40px;
        position: absolute;
    }

    .popover-button {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px 0 8px;
        border: 1px solid transparent;
        border-right-color: #C3C3C3;
        justify-content: space-between;
        transition: all .3s ease-in-out;

        &.opened {
            border-color: #C3C3C3;
            border-radius: 8px 8px 0 0;
        }

        .ant-btn {
            padding: 0;
            font-size: 13px;
            margin-right: 30px;
            justify-content: flex-start;
            box-shadow: none !important;

            .icon {
                font-size: 16px !important;
            }

            .btn-title {
                color: #767676;
                font-weight: normal;
            }

            .item-value {
                color: #000;
            }
        }

        .icon-down {
            margin-top: -2px;
            transition: all .3s ease-in-out;

            &.up {
                transform: rotate(-180deg);
            }
        }
    }

    .popover-content {
        top: 40px;
        opacity: 0;
        width: 290px;
        z-index: 999;
        max-height: 0;
        padding: 0 12px;
        overflow: hidden;
        position: absolute;
        background-color: #FFF;
        border-radius: 0 0 8px 8px;
        transition: all 0.2s ease-in-out;
        background-color: getColor(background, lightest);

        &.with-label {
            top: 67px !important;
        }

        &.popover-opened {
            opacity: 1;
            max-height: 500px;
            padding: 18px 12px 12px;
            border: 1px solid #C3C3C3;
            
            .ant-input-affix-wrapper .ant-input {
                background-color: #FAFAFA;
            }
        }

        .title {
            padding: 0;
            border: none;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            align-items: center;
            margin-bottom: 24px;
            justify-content: space-between;
        }

        .btn-clear {
            width: 100%;
            margin-top: 16px;
            padding-top: 16px;
            border-radius: 0;
            border-top: 1px solid #d8d8d8 !important;
        }
    }

    .item-count {
        width: 27px;
        height: 27px;
        color: #FFF;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: getColor(primary);
    }
}