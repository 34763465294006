@import "/src/common/styles/colors.scss";

.pl-mentions {
    .ant-btn {
        .arrow {
            margin-left: 20px;
            margin-top: -20px;
            position: relative;

            &::before,
            &::after {
                content: "";
                top: 10px;
                width: 8px;
                height: 1px;
                position: absolute;
                display: inline-block;
                background-color: #333;
                transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                -ms-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
            }

            &::before {
                left: 0;
                transform: rotate(-45deg);
            }

            &::after {
                right: -4px;
                transform: rotate(45deg);
            }

            &.open {
                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .junction-mentions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &.to-pdf {
            margin-bottom: 120px;
            .list-mentions .mentions.expanded {
                height: 560px !important;
            }
        }

        .list-mentions {
            padding: 14px 15px;
            border-radius: 6px;
            margin-bottom: 24px;
            width: calc(50% - 8px);
            transition: all .2s ease-in-out;
            background-color: getColor(background, lightest);

            .list-title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 14px;
            }

            .mentions {
                display: flex;
                flex-wrap: wrap;
                font-size: 16px;
                overflow: hidden;
                min-height: 200px;
                max-height: 200px;
                align-content: baseline;
                align-items: flex-start;
                align-content: flex-start;
                transition: .2s all ease-in-out;

                &.expanded {
                    max-height: 2200px;
                }

                .mention {
                    display: flex;
                    margin-bottom: 4px;
                    align-items: center;

                    &.mention-brand {
                        min-height: 22px;
                        cursor: pointer;
                        transition: .2s all ease-in-out;

                        &:hover {
                            opacity: 0.6;
                        }
                    }

                    .comma {
                        padding-right: 2px;
                    }

                    .value {
                        height: 18px;
                        display: flex;
                        padding: 0 4px;
                        min-width: 32px;
                        font-size: 13px;
                        margin-left: 4px;
                        font-weight: bold;
                        margin-right: 16px;
                        border-radius: 12px;
                        align-items: center;
                        justify-content: center;
                        background-color: #333;
                        color: getColor(text, white);
                    }
                }

                .text-empty {
                    width: 100%;
                    margin: 20px 0;
                    font-size: 16px;
                }
            }
        }
    }
}