@import '/src/common/styles/colors.scss';

.influencer-interactions {
  margin-bottom: 82px;

  .interaction-values {
    display: grid;
    grid-gap: 24px 40px;
    grid-template-columns: repeat(4, 1fr);

    &.interaction-values-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.interaction-values-4 {
      grid-template-columns: repeat(4, 1fr);
    }

    .interaction {
      width: 100%;
      padding: 18px;
      display: flex;
      border-radius: 8px;
      position: relative;
      margin-bottom: 24px;
      flex-direction: column;
      align-items: flex-start;
      background-color: getColor(background, lightest);

      &:last-child {
        border-right: none;
      }

      &.with-icons {
        .icon {
          font-size: 40px;
          color: getColor(primary);
        }

        .interaction-value {
          font-size: 40px;
          cursor: default;
          margin: 20px 0 8px;
        }
      }

      .interaction-value {
        font-size: 20px;
        margin: 0 0 8px;
        font-weight: 700;
      }

      .interaction-name {
        display: flex;
        font-size: 16px;

        .tooltip {
          margin-left: 8px;
        }
      }

      .interaction-name > div {
        display: flex;
        flex: 1;
        white-space: nowrap;

        > button {
          cursor: pointer;

          &:hover {
            opacity: 0.75;
          }
        }

        .level_gauge__wrapper {
          margin-left: 8px;
          margin-bottom: 4px;
          padding: 0;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          transition: opacity 0.2s;
        }
      }

      .part-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .profile-hide-like {
          display: flex;
          color: #000;
          font-size: 14px;
          // color: #CC1F1F;
          padding: 1px 8px;
          align-items: center;
          border-radius: 100px;
          justify-content: center;
          border: 1px solid #000;
          // border: 1px solid #CC1F1F;

          img {
            color: #000;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .estimated-costs-interaction {
    width: 100%;
    margin-top: 22px;

    .simulation {
      gap: 24px;
      display: flex;
      padding: 24px;
      border-radius: 6px;
      justify-content: space-between;
      background-color: getColor(background, lightest);

      .input-btn {
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        .input-junction {
          width: 275px;
        }

        .ant-input-number {
          margin: 0;
          width: 100%;
          max-width: 350px;
          margin-right: 16px;
          flex-direction: column;

          input {
            width: 275px;
            max-width: none;
            box-shadow: none;
            border: 1px solid #d9d9d9 !important;
            background-color: #fafafa !important;
          }
        }

        .ant-btn {
          min-width: 60px;
          margin-left: -8px;
          background-color: #f70b53 !important;
        }

        .icon {
          padding: 0;
          font-size: 28px;
          margin-bottom: -2px;
        }
      }

      .input-junction {
        width: 226px;
        margin-right: 24px;
        flex-direction: column;

        input {
          width: 100%;
          box-shadow: none;
          border-radius: 8px;
          border: 1px solid #d9d9d9 !important;
          background-color: #e6e6e6 !important;
        }
      }
    }
  }
}

.ant-popover-inner-content {
  padding: 0;

  .modal-simulator {
    width: 250px;
    max-height: 0;
    overflow: hidden;
    border-radius: 6px;
    transition: 0.2s ease-in all;
    transition: max-height 0.3s ease-out;
    background-color: getColor(background, lightest);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.13);

    &.open-modal-simulator {
      height: auto;
      max-height: 500px;
      transition: max-height 0.3s ease-out;
    }

    .modal-simulator-header {
      padding: 10px;
      text-align: center;
      position: relative;
      border-radius: 6px 4px 0 0;
      color: getColor(text, white);
      background-color: getColor(text, dark);

      .modal-simulator-header-title {
        font-size: 18px;
        margin-right: 10px;
        text-transform: uppercase;
        display: flex;
      }

      .icon {
        top: 50%;
        right: 10px;
        position: absolute;
        transform: translateY(-62%);
      }
    }

    .modal-simulator-content {
      padding: 10px 10px 16px;

      .input-junction {
        .ant-input::placeholder {
          font-size: 15px;
        }
      }

      .modal-simulator-junction {
        margin-top: 24px;
        overflow-y: auto;
        max-height: 200px;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}
