@import "/src/common/styles/colors.scss";

.input-junction {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .input {
        height: 40px;
        outline: none;
        color: #4a4a4a;
        font-weight: 400;
        padding: 6px 12px;
        border-radius: 12px;
        box-sizing: border-box;
        font-size: 13px !important;
        box-shadow: none !important;
        background-color: #FAFAFA;
        border: 1px solid #D9D9D9 !important;
        
        .ant-input-prefix {
            margin-right: 8px;
        
        }
        .ant-input:focus, .ant-input-focused {
            box-shadow: none;
        }
    }

    .input::placeholder {
        font-size: 13px !important;
        color: #767676 !important;
    }

    .suffix {
        height: 42px;
        width: 25px;
        display: flex;
        cursor: pointer;
        margin-left: -4px;
        position: relative;
        align-items: center;
        justify-content: center;
        border-radius: 0 8px 8px 0;
        transition: 0.2s ease-in all;
        color: getColor(text, white);
        background-color: getColor(status, success);

        &:hover {
            opacity: 0.6;
            transition: 0.2s ease-in all;
        }
    }
}