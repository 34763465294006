@import "/src/common/styles/colors.scss";

.influencer-categories {
    margin-bottom: 82px;

    .categories-card {
        padding: 24px;
        background-color: #fff;
        
        .filters {
            display: flex;
            margin-bottom: 27px;
            justify-content: space-between;

            .btns {
                display: flex;

                .button {
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 20px;
                }
            }

            .select {
                width: 30%;
                max-width: 200px;
            }
        }

        .empty {
            display: flex;
            font-size: 24px;
            padding: 40px 0;
            font-weight: 700;
            text-align: center;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            img {
                margin-top: 10px;
            }
        }

        .categories {
            gap: 16px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .category-card {
                border-radius: 8px;
                text-align: center;
                width: calc(20% - 14px);
                background-color: #FAFAFA;

                .category-airscore {
                    margin: 0;
                }
            }
        }
    }
}