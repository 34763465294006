@import "/src/common/styles/colors.scss";

.ant-modal-wrap {
    display: flex;
    align-items: center;
    z-index: 99998 !important;
}


.modal-comments {
    top: 0 !important;
    width: 80% !important;
    padding: 0 !important;
    max-width: 726px !important;

    ::-webkit-scrollbar {
        width: 11px;
        border-radius: 24px;
        background: #D9D9D9;
        border: 4px solid #D9D9D9;
    }

    ::-webkit-scrollbar-track {
        width: 7px;
        border-radius: 24px;
        background: #D9D9D9;
    }

    ::-webkit-scrollbar-thumb {
        background: #333;
        border-radius: 24px;
    }

    .ant-modal-close {
        display: none;
    }

    .ant-modal-content {
        background-color: transparent !important;

        .ant-modal-body {
            padding: 0 !important;

            .influencer {
                display: flex;
                padding: 13px 24px;
                align-items: center;
                border-radius: 8px 8px 0 0;
                background-color: #3C3C3C;

                .avatar-network {
                    .ant-avatar {
                        margin-right: 16px;
                        width: 48px !important;
                        height: 48px !important;
                    }

                    .network-logo {
                        right: 12px !important;
                        bottom: -2px !important;
                        width: 20px !important;
                        height: 20px !important;
                    }
                }

                .name-username {
                    display: flex;
                    color: #FFF;
                    flex-direction: column;

                    .name {
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .username {
                        font-size: 13px;
                        text-decoration: underline;
                    }
                }
            }

            .empty {
                display: flex;
                font-size: 18px;
                padding: 40px 0;
                text-align: center;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                img {
                    margin-top: 10px;
                }
            }

            .box-comments {
                background-color: #FFF;
                padding: 16px 6px 16px 24px;

                .title {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 8px;
                }

                .subtitle {
                    font-size: 14px;
                    margin-bottom: 16px;

                    .icon {
                        font-size: 16px;
                        margin-top: -3px;
                        margin-right: 4px;
                    }
                }

                .comment-list {
                    gap: 24px;
                    display: flex;
                    overflow-y: auto;
                    color: #4A4A4A;
                    min-height: 142px;
                    max-height: 322px;
                    padding-right: 8px;
                    flex-direction: column;
                    transition: all .3s ease-in-out;

                    .comment {
                        position: relative;
                        padding-bottom: 16px;
                        white-space: pre-line;
                        border-bottom: 1px solid #E6E6E6;

                        &:last-child {
                            border: none;
                        }

                        &.fixed {
                            border: none;
                            color: #1A1517;
                            border-radius: 8px;
                            background-color: #E6E6E6;
                            padding: 30px 36px 14px 16px;

                            .text-fixed {
                                top: 8px;
                                right: 35px;
                                font-size: 12px;
                                position: absolute;
                            }

                            .ant-dropdown-trigger.icon-more {
                                top: 10px;
                                margin: 0;
                                right: 8px;
                            }

                            .about {
                                padding-right: 0;
                            }
                        }

                        .text {
                            font-size: 14px;
                            line-height: 18px;
                            padding-right: 27px;
                        }

                        .about {
                            gap: 12px;
                            display: flex;
                            font-size: 12px;
                            margin-top: 16px;
                            padding-right: 27px;
                            align-items: center;
                            justify-content: flex-end;

                            .is-edited,
                            .author {
                                position: relative;

                                &:after {
                                    top: 7px;
                                    right: -8px;
                                    content: "";
                                    width: 3px;
                                    height: 3px;
                                    position: absolute;
                                    background: #999;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .ant-dropdown {
                            left: auto !important;
                            right: 8px !important;
                        }

                        .ant-dropdown-trigger {
                            top: 0;
                            right: 0;
                            margin: 0;
                            position: absolute;

                            &.icon-more {
                                width: 16px;
                                height: 16px;
                                margin: 0 auto;
                                cursor: pointer;
                                border-radius: 50%;
                                transform: rotate(90deg);
                                transition: .3s ease all;

                                &.icon-more-open {
                                    transform: rotate(180deg);
                                    transition: .3s ease all;
                                }

                                &:hover {
                                    opacity: .6;
                                }
                            }
                        }
                    }

                    .edit-comment {
                        border-radius: 8px;
                        padding-bottom: 12px;
                        background-color: #FFF;
                        box-shadow: none !important;
                        border: 1px solid #CCC !important;

                        .input {
                            height: auto;
                            resize: none;
                            border: none;
                            outline: none;
                            min-height: 40px;
                            max-height: 170px;
                            overflow: hidden;
                            color: #4a4a4a;
                            font-weight: 400;
                            padding: 6px 12px;
                            box-sizing: border-box;
                            font-size: 13px !important;
                            box-shadow: none !important;
                            background-color: transparent;
                        }

                        .buttons {
                            gap: 8px;
                            display: flex;
                            margin-top: 12px;
                            margin-right: 12px;
                            justify-content: flex-end;

                            .ant-btn {
                                min-width: auto;
                            }
                        }
                    }

                    .show-more {
                        color: #333;
                        display: flex;
                        margin-top: 32px;
                        text-align: center;
                        align-items: center;
                        justify-content: center;

                        .icon {
                            margin-left: 4px;
                        }
                    }
                }
            }

            .input-box {
                gap: 16px;
                display: flex;
                position: relative;
                padding: 0 16px 24px;
                align-items: flex-end;
                background-color: #FFF;
                border-radius: 0 0 8px 8px;

                .input {
                    min-height: 40px;
                    max-height: 160px;
                    outline: none;
                    color: #4a4a4a;
                    font-weight: 400;
                    padding: 6px 12px;
                    border-radius: 8px;
                    box-sizing: border-box;
                    font-size: 13px !important;
                    box-shadow: none !important;
                    background-color: #FFF;
                    border: 1px solid #CCC !important;
                    
                    height: 40px;
                    resize: none;
                    overflow: hidden;
                    min-height: 40px;
                    max-height: 100px;
                }

                .ant-btn {
                    height: 40px;
                    padding: 0 5px;
                    min-width: 40px;
                    border-radius: 8px;

                    .icon {
                        margin: 0;
                    }
                }
            }
        }
    }
}



.comments-outside {
    .influencer {
        display: flex;
        padding: 13px 24px;
        align-items: center;
        border-radius: 8px 8px 0 0;
        background-color: #3C3C3C;

        .avatar-network {
            .ant-avatar {
                margin-right: 16px;
                width: 48px !important;
                height: 48px !important;
            }

            .network-logo {
                right: 12px !important;
                bottom: -2px !important;
                width: 20px !important;
                height: 20px !important;
            }
        }

        .name-username {
            display: flex;
            color: #FFF;
            flex-direction: column;

            .name {
                font-size: 16px;
                font-weight: 500;
            }

            .username {
                font-size: 13px;
                text-decoration: underline;
            }
        }
    }

    .empty {
        display: flex;
        font-size: 18px;
        padding: 40px 0;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
            margin-top: 10px;
        }
    }

    .box-comments {
        background-color: #FFF;
        padding: 16px 6px 16px 24px;

        .title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 8px;
        }

        .subtitle {
            font-size: 14px;
            margin-bottom: 16px;

            .icon {
                font-size: 16px;
                margin-top: -3px;
                margin-right: 4px;
            }
        }

        .comment-list {
            gap: 24px;
            display: flex;
            overflow-y: auto;
            color: #4A4A4A;
            min-height: 142px;
            max-height: 322px;
            padding-right: 8px;
            flex-direction: column;
            transition: all .3s ease-in-out;

            .comment {
                position: relative;
                padding-bottom: 16px;
                white-space: pre-line;
                border-bottom: 1px solid #E6E6E6;

                &:last-child {
                    border: none;
                }

                &.fixed {
                    border: none;
                    color: #1A1517;
                    border-radius: 8px;
                    background-color: #E6E6E6;
                    padding: 30px 36px 14px 16px;

                    .text-fixed {
                        top: 8px;
                        right: 35px;
                        font-size: 12px;
                        position: absolute;
                    }

                    .ant-dropdown-trigger.icon-more {
                        top: 10px;
                        margin: 0;
                        right: 8px;
                    }

                    .about {
                        padding-right: 0;
                    }
                }

                .text {
                    font-size: 14px;
                    line-height: 18px;
                    padding-right: 27px;
                }

                .about {
                    gap: 12px;
                    display: flex;
                    font-size: 12px;
                    margin-top: 16px;
                    padding-right: 27px;
                    align-items: center;
                    justify-content: flex-end;

                    .is-edited,
                    .author {
                        position: relative;

                        &:after {
                            top: 7px;
                            right: -8px;
                            content: "";
                            width: 3px;
                            height: 3px;
                            position: absolute;
                            background: #999;
                            border-radius: 50%;
                        }
                    }
                }

                .ant-dropdown {
                    left: auto !important;
                    right: 8px !important;
                }

                .ant-dropdown-trigger {
                    top: 0;
                    right: 0;
                    margin: 0;
                    position: absolute;

                    &.icon-more {
                        width: 16px;
                        height: 16px;
                        margin: 0 auto;
                        cursor: pointer;
                        border-radius: 50%;
                        transform: rotate(90deg);
                        transition: .3s ease all;

                        &.icon-more-open {
                            transform: rotate(180deg);
                            transition: .3s ease all;
                        }

                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }

            .edit-comment {
                border-radius: 8px;
                padding-bottom: 12px;
                background-color: #FFF;
                box-shadow: none !important;
                border: 1px solid #CCC !important;

                .input {
                    height: auto;
                    resize: none;
                    border: none;
                    outline: none;
                    min-height: 40px;
                    max-height: 170px;
                    overflow: hidden;
                    color: #4a4a4a;
                    font-weight: 400;
                    padding: 6px 12px;
                    box-sizing: border-box;
                    font-size: 13px !important;
                    box-shadow: none !important;
                    background-color: transparent;
                }

                .buttons {
                    gap: 8px;
                    display: flex;
                    margin-top: 12px;
                    margin-right: 12px;
                    justify-content: flex-end;

                    .ant-btn {
                        min-width: auto;
                    }
                }
            }

            .show-more {
                color: #333;
                display: flex;
                margin-top: 32px;
                text-align: center;
                align-items: center;
                justify-content: center;

                .icon {
                    margin-left: 4px;
                }
            }
        }
    }

    .input-box {
        gap: 16px;
        display: flex;
        position: relative;
        padding: 0 16px 24px;
        align-items: flex-end;
        background-color: #FFF;
        border-radius: 0 0 8px 8px;

        .input {
            min-height: 40px;
            max-height: 160px;
            outline: none;
            color: #4a4a4a;
            font-weight: 400;
            padding: 6px 12px;
            border-radius: 8px;
            box-sizing: border-box;
            font-size: 13px !important;
            box-shadow: none !important;
            background-color: #FFF;
            border: 1px solid #CCC !important;
            
            height: 40px;
            resize: none;
            overflow: hidden;
            min-height: 40px;
            max-height: 100px;
        }

        .ant-btn {
            height: 40px;
            padding: 0 5px;
            min-width: 40px;
            border-radius: 8px;

            .icon {
                margin: 0;
            }
        }
    }
}