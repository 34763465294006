@import '/src/common/styles/colors.scss';

.influencer-preview-modal {
    top: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    transition: right 0.2s ease-in-out 0.1s;
    background-color: rgba(0, 0, 0, 0.4);

    &.closed {
        .influencer-preview-modal-content {
            animation: moveRight 0.3s;

            @keyframes moveRight {
                from {
                    right: 0;
                    opacity: 100;
                }

                to {
                    opacity: 0;
                    right: -50%;
                }
            }
        }
    }

    .influencer-preview-modal-content {
        right: 0;
        top: 50%;
        width: 60%;
        height: 100vh;
        max-width: 460px;
        padding: 16px 24px;
        position: absolute;
        animation: moveLeft 0.3s;
        background-color: #fff;
        border-radius: 4px 0 0 4px;
        transform: translateY(-50%);
        transition: 0.2s all ease-in-out;
        transition: all 0.2s ease-in-out;

        @keyframes moveLeft {
            from {
                opacity: 0;
                right: -50%;
            }

            to {
                right: 0;
                opacity: 100;
            }
        }
    }
}

.ct-page {
    .ct-content-container {
        margin: 0 auto;
        padding: 0 84px 12px;
        box-sizing: border-box;

        .btn-back {
            margin: 50px 0 20px;
        }

        .header {
            padding-top: 0;
            padding-bottom: 24px;
        }

        .briefing-collapse {
            margin: 0 0 16px;
        }

        .ct-content {
            margin: 0 auto;
            max-width: 1280px;

            .empty-list {
                display: flex;
                // margin-top: -82px;
                margin-bottom: 32px;
                padding-left: 260px;
                flex-direction: column;
                align-items: flex-start;

                .text-empty {
                    font-size: 24px;
                    font-weight: 500;
                    margin-bottom: 15px;
                    text-align: left;
                }

                .question-empty {
                    font-size: 16px;
                    min-width: 224px;
                    margin-bottom: 27px;
                }
            }

            .ct-actions {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 32px;
                align-items: flex-end;
                justify-content: space-between;

                .buttons {
                    gap: 16px;
                    display: flex;

                    .ant-btn {
                        min-width: auto;
                    }
                }

                .filter-networks {
                    width: calc(100% - 400px);

                    .ant-radio-group {
                        width: 100%;
                        gap: 4px 20px;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        .ant-radio-wrapper {
                            height: 40px;
                            padding: 0 8px;
                            border-radius: 8px;
                            background-color: #fff;
                            border: 1px solid #6d6d6d;
                            transition: all 0.3s ease-in-out;

                            .icon {
                                color: #6d6d6d;
                            }

                            .radio-label {
                                color: #6d6d6d;
                                font-weight: 600;
                                text-transform: capitalize;
                            }

                            &:hover {
                                background-color: #ccc;
                            }

                            &.ant-radio-wrapper-checked {
                                border-color: getColor(primary);

                                .icon {
                                    color: getColor(primary);
                                }

                                .radio-label {
                                    color: getColor(primary) !important;
                                }

                                &:hover {
                                    cursor: default;
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .detailing {
                border-radius: 8px;
                margin-bottom: 18px;
                padding: 17px 21px 9px;
                background-color: #fff;

                .detailing-title {
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                    }

                    .ant-btn {
                        height: 20px;

                        .arrow {
                            margin-left: 20px;
                            margin-top: -20px;
                            position: relative;

                            &::before,
                            &::after {
                                content: '';
                                top: 10px;
                                width: 8px;
                                height: 1px;
                                position: absolute;
                                display: inline-block;
                                background-color: #333;
                                transition: all 0.3s ease-in-out;
                                -o-transition: all 0.3s ease-in-out;
                                -ms-transition: all 0.3s ease-in-out;
                                -moz-transition: all 0.3s ease-in-out;
                                -webkit-transition: all 0.3s ease-in-out;
                            }

                            &::before {
                                left: 0;
                                transform: rotate(-45deg);
                            }

                            &::after {
                                right: -4px;
                                transform: rotate(45deg);
                            }

                            &.open {
                                &::before {
                                    transform: rotate(45deg);
                                }

                                &::after {
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }

                .detailing-text {
                    display: flex;
                    min-height: 0;
                    max-height: 0;
                    flex-wrap: wrap;
                    font-size: 16px;
                    margin-top: 8px;
                    overflow: hidden;
                    align-items: flex-start;
                    align-content: baseline;
                    align-content: flex-start;
                    transition: all 0.3s ease-in-out;

                    &.expanded {
                        margin-bottom: 8px;
                        max-height: 2200px;
                    }
                }
            }

            .ct-cards {
                display: flex;
                gap: 6px 16px;
                margin-bottom: 30px;

                &.ct-cards-4 {
                    .ct-card {
                        width: 25% !important;
                    }
                }

                .ct-card {
                    width: 20%;
                    display: flex;
                    border-radius: 8px;
                    padding: 21px 26px;
                    text-align: center;
                    position: relative;
                    align-items: center;
                    flex-direction: column;
                    color: getColor(text, dark);
                    justify-content: space-between;
                    background-color: getColor(background, lightest);

                    .ct-card-title {
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 8px;
                    }

                    .ct-card-value {
                        cursor: default;
                        font-size: 32px;
                        font-weight: 700;
                    }

                    .tooltip {
                        top: 4px;
                        right: 10px;
                        position: absolute;
                    }
                }
            }

            .ct-table {
                .filter-types {
                    display: flex;
                    margin-top: 48px;
                    position: relative;
                    margin-bottom: 38px;
                    border-bottom: 2px solid #e6e6e6;

                    .filter-type-option {
                        width: 210px;
                        font-size: 16px;
                        font-weight: 700;
                        text-align: center;
                        padding-bottom: 12px;
                        transition: all 0.3s ease-in-out 0s;

                        &.unselected {
                            cursor: pointer;
                            font-weight: normal;
                            color: #939393 !important;

                            &:hover {
                                opacity: 0.6;
                            }
                        }
                    }

                    .underline {
                        left: 0;
                        height: 2px;
                        bottom: -2px;
                        width: 210px;
                        position: absolute;
                        background-color: #000;
                        transition: all 0.3s ease-in-out 0s;

                        &.simulation {
                            left: 210px;
                        }
                    }
                }

                .select-label {
                    font-size: 16px;
                    margin-bottom: 8px;
                }

                .ant-select {
                    width: 50%;
                    max-width: 476px;
                    margin-bottom: 12px;
                }

                .table-container {
                    animation: fadeTables 0.6s;
                    transition: all 0.6s ease-in-out;

                    @keyframes fadeTables {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 100;
                        }
                    }
                }
            }
        }
    }
}
