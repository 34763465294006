@import '/src/common/styles/colors.scss';

.ant-tooltip {
  z-index: 99999;

  .ant-tooltip-inner {
    line-height: none;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 150px;
    padding-inline: 150px;

    .tooltip {
      padding-bottom: 20px;
    }
  }
}

.reclassification-modal {
  width: 70% !important;
  max-width: 950px !important;

  .ant-modal-content {
    border-radius: 6px;

    .ant-modal-header {
      display: none;
    }

    .ant-modal-footer {
      display: none;
    }

    .modal-title {
      font-size: 20px;
    }

    .ant-modal-body {
      display: flex;
      padding: 24px;
      min-height: 100px;
      flex-direction: column;

      .modal-content {
        gap: 18px;
        display: flex;
        justify-content: space-between;

        // post
        .twitch-post {
          margin-bottom: 0;
          max-width: 330px;
        }

        .part-right {
          gap: 32px;
          width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          b {
            font-size: 15px;
            margin-bottom: 4px;
          }

          .post-about {
            gap: 32px;
            display: flex;
            flex-direction: column;
          }

          .ant-form {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .ant-form-item {
              width: 56%;
              margin: 4px 0 0;
            }

            .ant-btn {
              width: 42%;
            }
          }
        }

        .btn-reclassification {
          display: none !important;
        }
      }
    }
  }
}

.type-post,
.btn-reclassification {
  top: 8px;
  left: 8px;
  height: 18px;
  color: #333;
  border: none;
  outline: none;
  display: flex;
  padding: 0 8px;
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.type-post {
  box-shadow: none;
  text-transform: capitalize;
}

.tiktok-post {
  width: 231px;
  height: 390px;
  position: relative;
  border-radius: 13px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: getColor(text, white);

  .opacity-image {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    background-image: linear-gradient(0deg, rgba(0, 0, 0), 42%, #0000004e);
  }

  .icon-clip {
    top: 28px;
    right: 14px;
    padding: 4px;
    color: #333;
    font-size: 24px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
  }

  .post-interactions {
    right: 10px;
    width: 41px;
    bottom: 16px;
    display: flex;
    position: absolute;
    flex-direction: column;

    .interaction-data {
      display: flex;
      margin: 19px 0 0;
      align-items: center;
      flex-direction: column;

      .icon {
        font-size: 20px;
      }

      .interaction-value {
        font-size: 14px;
      }
    }
  }

  .post-description {
    bottom: 0;
    z-index: 1;
    font-size: 10px;
    overflow: hidden;
    max-height: 64px;
    position: absolute;
    padding: 0 60px 16px 12px;
    transition: max-height 0.3s ease-out;

    &.expanded {
      max-height: 300px;
      transition: max-height 0.3s ease-in;
    }

    span {
      width: 100%;
    }
  }
}

.instagram-post {
  width: 286px;
  padding: 8px;
  border-radius: 6px;
  position: relative;
  background-color: #f2f2f2;

  .btn-reclassification {
    top: 64px;
    left: 24px;
  }

  .description {
    height: 36px;
    overflow: hidden;
    margin-bottom: 6px;
    line-height: initial;
  }

  .thumb {
    height: 247px;
    border-radius: 6px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.527);
    background-position: center;
    overflow: hidden;

    .btn-reclassification {
      top: auto;
      left: auto;
      right: 20px;
      bottom: 20px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .icon-clip {
    top: 59px;
    right: 20px;
    padding: 4px;
    font-size: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
  }

  .post-interactions {
    display: flex;
    margin-top: 12px;
    justify-content: space-around;

    .interaction-data {
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .icon {
        font-size: 20px;
        margin-bottom: 6px;
      }

      .interaction-value {
        font-size: 14px;
      }
    }
  }
}

.facebook-post {
  width: 286px;
  padding: 8px;
  border-radius: 6px;
  position: relative;
  background-color: #f2f2f2;

  .description {
    height: 36px;
    overflow: hidden;
    margin-bottom: 6px;
    line-height: initial;
  }

  .thumb {
    height: 247px;
    border-radius: 6px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.527);
  }

  .icon-clip {
    top: 72px;
    right: 24px;
    padding: 4px;
    font-size: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
  }

  .post-interactions {
    display: flex;
    margin-top: 12px;
    justify-content: space-around;

    .interaction-data {
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .icon {
        font-size: 20px;
        margin-bottom: 6px;
      }

      .interaction-value {
        font-size: 14px;
      }
    }
  }
}

.twitter-post {
  width: 286px;
  padding: 8px;
  border-radius: 6px;
  position: relative;
  background-color: #f2f2f2;

  .post-type {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .junction-post {
    height: 126px;
    padding: 6px 8px;
    overflow: hidden;
    border-radius: 6px;
    background-color: #fff;

    .post-header {
      margin-bottom: 12px;
      position: relative;

      .ant-avatar {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .name {
        font-size: 12px;
      }

      .icon-clip {
        top: 4px;
        right: 0;
        font-size: 12px;
        border-radius: 50%;
        position: absolute;
      }
    }

    .description {
      font-size: 12px;
    }
  }

  .post-interactions {
    display: flex;
    margin-top: 12px;
    margin-left: 8px;

    .interaction-data {
      display: flex;
      margin: 0 19px 0 0;
      align-items: center;
      flex-direction: column;

      .icon {
        font-size: 20px;
        margin-bottom: 6px;
      }

      .interaction-value {
        font-size: 14px;
      }
    }
  }
}

.twitch-post {
  width: 436px;
  padding: 8px;
  height: 350px;
  border-radius: 6px;
  position: relative;
  border: 1px solid #f2f2f2;
  margin-bottom: 30px;

  .btn-reclassification {
    top: 58px;
    left: 24px;
  }

  .title {
    max-width: 320px;
    height: 17px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .thumb {
    width: 100%;
    height: 242px;
    border-radius: 6px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.527);
  }

  .icon-clip {
    top: 55px;
    right: 24px;
    padding: 4px;
    font-size: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
  }

  .post-interactions {
    display: flex;
    margin-left: 8px;
    justify-content: space-between;

    .interaction-data {
      display: flex;
      align-items: center;
      margin: 8px 19px 0 0;
      flex-direction: column;

      .icon {
        font-size: 20px;
        margin-bottom: 5px;
        color: getColor(text, dark);
      }

      .interaction-value {
        font-size: 14px;
      }
    }
  }
}

.youtube-post {
  width: 436px;
  padding: 8px;
  height: 315px;
  border-radius: 6px;
  position: relative;
  border: 1px solid #f2f2f2;

  .thumb {
    width: 100%;
    height: 242px;
    border-radius: 6px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.527);
  }

  .icon-clip {
    top: 28px;
    right: 24px;
    padding: 4px;
    font-size: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
  }

  .post-interactions {
    display: flex;
    margin-left: 8px;

    .interaction-data {
      display: flex;
      align-items: center;
      margin: 8px 19px 0 0;
      flex-direction: column;

      .icon {
        font-size: 20px;
        margin-bottom: 5px;
        color: getColor(text, dark);
      }

      .interaction-value {
        font-size: 14px;
      }
    }
  }
}
