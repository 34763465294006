@import "/src/common/styles/colors.scss";

.notifications-page {
    padding: 28px !important;
    background-color: #F2F2F2;

    .icon-close {
        top: 9px;
        right: -45px;
        padding: 8px;
        color: #333;
        font-size: 15px;
        border-radius: 8px;
        position: absolute;
        background-color: #FFF;
    }

    .notifications-content {
        max-width: 1280px;
        background-color: #F2F2F2;

        .notification-title {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 32px;
        }

        .notification-types {
            display: flex;
            margin-top: 41px;
            position: relative;
            border-bottom: 2px solid #E6E6E6;

            .type-option {
                width: 50%;
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                padding-bottom: 12px;
                transition: all .3s ease-in-out .0s;

                &.unselected {
                    cursor: pointer;
                    font-weight: normal;
                    color: #939393 !important;

                    &:hover {
                        opacity: .6;
                    }
                }
            }

            .underline {
                left: 0;
                height: 2px;
                bottom: -2px;
                width: 50%;
                position: absolute;
                background-color: #000;
                transition: all .3s ease-in-out .0s;

                &.solicitations {
                    left: 50%;
                }
            }
        }

        .btn-filters {
            gap: 20px;
            display: flex;
            margin-bottom: 32px;
            align-items: center;
            justify-content: flex-start;

            .ant-btn {
                height: 24px;
                font-size: 13px;
                width: auto !important;
                min-width: auto !important;
                max-width: 100px !important;

                &:nth-child(2) {
                    min-width: 118px !important;
                    max-width: 100px !important;
                }

                &.active {
                    opacity: 1 !important;
                    background-color: #000 !important;
                    color: getColor(text, white) !important;
                }

                &:hover {
                    opacity: .7;
                }

                .counter {
                    right: 0;
                    top: -4px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    color: #fff;
                    font-size: 8px;
                    position: absolute;
                    line-height: normal;
                    border-radius: 13px;
                    align-items: center;
                    justify-content: center;
                    background-color: getColor(primary);
                }
            }
        }

        .list-notifications-content {
            background-color: #F2F2F2;

            .btn-clear {
                color: #333333e5 !important;

                .icon {
                    font-size: 18px;
                }
            }

            .list-notifications {
                margin-top: 8px;

                .card-notification {
                    margin-bottom: 14px;
                }
            }
        }

        .text-empty {
            padding: 40px;
            margin-top: 20px;
        }
    }
}