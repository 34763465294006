@import "/src/common/styles/colors.scss";

.ant-modal-wrap {
    z-index: 1000 !important;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal {
    width: 60% !important;
    max-width: 430px !important;

    .ant-modal-content {
        border-radius: 8px;

        .ant-modal-header {
            display: none;
        }

        .ant-modal-footer {
            display: none;
        }

        .ant-modal-body {
            display: flex;
            padding: 22px;
            min-height: 100px;
            flex-direction: column;

            .container-icon {
                &.container-info .icon {
                    color: getColor(status, info);
                }

                &.container-error .icon {
                    color: getColor(status, error);
                }

                &.container-success .icon {
                    color: getColor(status, success);
                }

                &.container-warning .icon {
                    color: getColor(status, warning);
                }

                .icon {
                    font-size: 45px;
                    margin-bottom: 14px;
                }
            }

            .container-content {
                width: 100%;

                .title {
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 24px;
                    text-transform: capitalize;

                    &.title-info {
                        text-transform: none;
                        color: getColor(status, info);
                    }

                    // &.title-error {
                    //     color: getColor(status, error);
                    // }

                    // &.title-success {
                    //     color: getColor(status, success);
                    // }

                    // &.title-warning {
                    //     color: getColor(status, warning);
                    // }
                }

                .subtitle {
                    margin-bottom: 32px;
                }

                .subtitle,
                .text,
                .question {
                    font-size: 14px;
                    line-height: 28px;
                    letter-spacing: 0.26px;
                }

                .text {
                    ul {
                        padding: 20px;
                    }
                }
                
                .question {
                    margin: 40px 0 32px;
                    font-weight: bold;
                }

                .buttons {
                    gap: 20px;
                    display: flex;
                    margin-top: 48px;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}