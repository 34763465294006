.container-slider-benefits {
    width: 100%;
    overflow: hidden;

    .slider-benefits {
        display: flex;
        transition: all .3s ease-in-out;

        .benefit-item {
            display: flex;
            flex: 0 0 100%;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;

            h2 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 26px;
            }

            img {
                width: auto;
                height: 181px;
            }

            p {
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
        }
    }

    .dots {
        gap: 6px;
        display: flex;
        margin: 24px 0 32px;
        justify-content: center;

        .dot {
            width: 8px;
            height: 8px;
            cursor: pointer;
            border-radius: 50%;
            background-color: #CCC;
            transition: all .3s ease-in-out;
            
            &.active {
                background-color: #333;
            }

            &:hover {
                opacity: .6;
            }
        }
    }

    .slider-actions {
        gap: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        justify-content: space-between;

        .ant-btn {
            margin: 0;
            width: 49%;
        }
    }
}