@import "/src/common/styles/colors.scss";

.influencer-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .container-loading {
        width: 100%;
        padding: 80px 0;
        border-radius: 8px;
        background-color: #FFF;
        margin: 0 auto !important;
    }

    .junction-influencer-list {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        justify-content: space-between;

        .title-influencer-list {
            margin: 0;
            font-size: 20px;
            font-weight: 700;
        }

        .ant-select {
            width: 240px;

            .ant-select-selector {
                box-shadow: none !important;
            }

            .ant-select-arrow {
                color: #000;
            }
        }
    }

    .container-table {
        overflow-x: auto;

        table {
            width: 100%;
            overflow-x: auto;
            border-spacing: 0 8px;
            border-collapse: separate;

            thead {
                tr {
                    border-radius: 8px;
                    padding-bottom: 16px;

                    th {
                        margin: 1px;
                        padding: 16px;
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        position: relative;
                        background-color: #E6E6E6;
                        color: getColor(text, dark);
                        border-right: 1px solid #D9D9D9;

                        &.network {
                            white-space: nowrap;
                        }

                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:last-child {
                            border: none;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }

                        .icon-down {
                            margin-top: -2px;
                            margin-left: 8px;
                            transition: all .2s ease-in-out;

                            &.smaller {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            // tbody {
            // }
        }
    }

    .div-flex {
        display: flex;
        align-items: center;
    }

    .empty-list {
        width: 100%;
        display: flex;
        margin: 0 auto;
        max-width: 680px;
        align-items: center;
        flex-direction: column;

        img {
            width: 80%;
            margin: 24px 0;
            max-width: 238px;
        }

        .empty-title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 24px;
        }

        .empty-description {
            font-size: 16px;
            text-align: center;
            margin-bottom: 12px;
        }

        .empty-warning {
            color: #666;
            font-size: 14px;
            font-weight: 700;
        }

        .buttons {
            display: flex;
            margin-top: 40px;

            .ant-btn {
                margin: 0 12px;
                min-width: 193px;
            }
        }
    }
}

.tooltip-themes {
    .theme-name {
        font-size: 10px;
        margin-bottom: 8px;
        text-align: center;
        padding: 0 20px;
    }
    
    .theme-score {
        display: flex;
        padding-top: 4px;
        margin-bottom: 4px;
        justify-content: space-between;
        border-top: 1px solid #ffffff73;
    }

    .theme-percent {
        display: flex;
        margin-bottom: 0;
        justify-content: space-between;

        b {
            margin-left: 12px;
        }
    }
}